
import couriemin from '../assest/restaurantImg/couriemin.jpg'
import whatisd2c from '../assest/restaurantImg/whatisd2c.jpg'


const ServiceD2CNewCommerceData = [
    {
        id: 1,
        heading:'How D2C Brands are Growing Their Innovative Strategies in a Competitive World ',
        textInfo:'In the last couple of years, several aspects of the business have been changing rapidly, like research and development, products and customers,…',
        byName:'By - Mitesh Bhandari',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: whatisd2c,
    },
    {
        id: 2,
        heading:'How D2C Brands are Growing Their Innovative Strategies in a Competitive World ',
        textInfo:'In the last couple of years, several aspects of the business have been changing rapidly, like research and development, products and customers,…',
        byName:'By - Mitesh Bhandari',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: couriemin,
    },
    {
        id: 3,
        heading:'How D2C Brands are Growing Their Innovative Strategies in a Competitive World ',
        textInfo:'In the last couple of years, several aspects of the business have been changing rapidly, like research and development, products and customers,…',
        byName:'By - Mitesh Bhandari',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: whatisd2c,
    }, {
        id: 4,
        heading:'How D2C Brands are Growing Their Innovative Strategies in a Competitive World ',
        textInfo:'In the last couple of years, several aspects of the business have been changing rapidly, like research and development, products and customers,…',
        byName:'By - Mitesh Bhandari',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: couriemin,
    }, {
        id: 5,
        heading:'How D2C Brands are Growing Their Innovative Strategies in a Competitive World ',
        textInfo:'In the last couple of years, several aspects of the business have been changing rapidly, like research and development, products and customers,…',
        byName:'By - Mitesh Bhandari',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: whatisd2c,
    }, {
        id: 6,
        heading:'How D2C Brands are Growing Their Innovative Strategies in a Competitive World ',
        textInfo:'In the last couple of years, several aspects of the business have been changing rapidly, like research and development, products and customers,…',
        byName:'By - Mitesh Bhandari',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: couriemin,
    }
];
export default ServiceD2CNewCommerceData
import greenfield1 from '../assest/greenfield1.webp'
import greenfield2 from '../assest/greenfield2.webp'
import greenfield3 from '../assest/greenfield3.webp'
import greenfield4 from '../assest/greenfield4.webp'
import greenfield5 from '../assest/greenfield5.webp'
import greenfield6 from '../assest/greenfield6.webp'
import bgtreee from '../assest/bgtreee.webp'
import bgtrees from '../assest/bgtrees.webp'
import img044 from '../assest/044.webp'
import seed from '../assest/seed.webp'


const MiyawakiForestGalleryData = [
    {
        id: 1,
        src: greenfield1,
    },
    {
        id: 2,
        src: greenfield2,
    },
    {
        id: 3,
        src: greenfield3,
    }, {
        id: 4,
        src: greenfield4,
    }, {
        id: 5,
        src: greenfield5,
    }, {
        id: 6,
        src: greenfield6,
    }, {
        id: 7,
        src: bgtrees,
    }, {
        id: 8,
        src: bgtreee,
    }, {
        id: 9,
        src: seed,
    }, {
        id: 10,
        src: img044,
    }
];
export default MiyawakiForestGalleryData
import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import topbgImg from '../../assest/seed.webp'
import '../../styles/DonateNowStyle.css'
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { FaArrowAltCircleRight } from "react-icons/fa";
import { MdCurrencyRupee } from "react-icons/md";
import { useState } from "react"

const DonateNow = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const amounts = [500, 1000, 2500, 5000, 10000, 'other'];

    const handleClick = (index) => {
        setActiveIndex(index);
    };


    const defalutValue = {
        FirstName: '',
        LastName: '',
        Email: '',
        PanCardNo: '',
        Mobile: '',
        StreetAddressLine1: '',
        StreetAddressLine2: '',
        City: '',
        States: '',
        PostalCode: '',
        Country: '',
        EnterMessage: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        FirstName: yup.string().required('First Name is Requird!'),
        LastName: yup.string().required('Last Name is Requird!'),
        Email: yup.string().required('Email is Requird!'),
        PanCardNo: yup.string().required('Pan Card No is Requird!'),
        Mobile: yup.string().required('Mobile is Requird!'),
        StreetAddressLine1: yup.string().required('Street Address Line 1 is Requird!'),
        StreetAddressLine2: yup.string().required('Street Address Line 2 is Requird!'),
        City: yup.string().required('City is Requird!'),
        States: yup.string().required('State is Requird!'),
        PostalCode: yup.string().required('Postal Code is Requird!'),
        Country: yup.string().required('Country is Requird!'),
        EnterMessage: yup.string().required('Message is Requird!'),


    })
    const handleSubmit = (value) => {
        console.log(value);

    }

    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={topbgImg} alt="img" />
                <h1 className="h1donate">Donate Now</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Donate <span>Now</span> </h1>
                </div>
                <div className="row">
                    <div className="leftdiv col-lg-6 col-12 px-5 py-2">
                        <div>
                            <p><FaArrowAltCircleRight className="icon" /></p>
                            <p> We believe and practice widely used phrase "HONESTY IS THE BEST POLICY" on ground level along with Honest ACTION.</p>
                        </div>
                        <div>
                            <p><FaArrowAltCircleRight className="icon" /></p>
                            <p> Green Yatra Trust’s offers workshops, tree plantation, presentations, awareness programs and educational activities ABSOLUTELY FREE to government schools, colleges, housing societies and underprivileged farmers. WE DEPEND ENTIRELY ON DONATIONS.</p>
                        </div>
                        <div>
                            <p><FaArrowAltCircleRight className="icon" /></p>
                            <p> We believe in clean water to drink, fresh air to breath, wild places to explore and a healthy environment is a right for all. These all things are worth saving for a GREEN present and future.</p>
                        </div>
                        <div>
                            <p><FaArrowAltCircleRight className="icon" /></p>

                            <p> Your small contribution can be of great help for us to continue serving to Mother Nature and humanity. Your contribution will help us to achieve #10CroreTREESby2025 support underprivileged farmers and manage the running cost of project Zaroorat.</p>
                        </div>
                    </div>
                    <div className="rightdiv col-lg-6 col-12">
                        <h4 className="pb-3 mt-1 h4tag">Select your Amount</h4>
                        <ul className="ultag">
                            {amounts.map((amount, index) => (
                                <li
                                    key={index}
                                    className={`amount_class ${index === activeIndex ? 'active' : ''}`}
                                    onClick={() => handleClick(index)}
                                >
                                    {typeof amount === 'number' ? (
                                        <>
                                            <MdCurrencyRupee className="fs-5" /> {amount}
                                        </>
                                    ) : (
                                        <>
                                            <MdCurrencyRupee className="fs-5" />
                                            <input type="text" placeholder="other" />
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>

                        <div className="card mb-4">

                            <div className="">
                                <h4 className="p-3 mb-0">Your Information</h4>
                            </div>

                            <div className="card-body row">
                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">

                                            <div className="col-12 col-lg-6">

                                                <Field type='text' name='FirstName' placeholder='First Name'
                                                    className={
                                                        `form-control
                                                     ${errors.FirstName && touched.FirstName ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FirstName" /></p>
                                            </div>
                                            <div className="col-12 col-lg-6">

                                                <Field type='text' name='LastName' placeholder='Last Name'
                                                    className={
                                                        `form-control
                                                     ${errors.LastName && touched.LastName ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="LastName" /></p>
                                            </div>

                                            <div className="col-12 col-lg-6">

                                                <Field type='text' name='Email' placeholder='Email'
                                                    className={
                                                        `form-control
                                                     ${errors.Email && touched.Email ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Email" /></p>
                                            </div>
                                            <div className="col-12 col-lg-6">


                                                <Field type='text' name='PanCardNo' placeholder='Pan Card No'
                                                    className={
                                                        `form-control
                                                     ${errors.PanCardNo && touched.PanCardNo ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="PanCardNo" /></p>
                                            </div>
                                            <div className="">

                                                <Field type='text' name='Mobile' placeholder='Mobile'
                                                    className={
                                                        `form-control
                                                     ${errors.Mobile && touched.Mobile ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Mobile" /></p>
                                            </div>

                                            <div className="">

                                                <Field type='text' name='StreetAddressLine1' placeholder='Street Address Line 1'
                                                    className={
                                                        `form-control
                                                        ${errors.StreetAddressLine1 && touched.StreetAddressLine1 ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="StreetAddressLine1" /></p>
                                            </div>

                                            <div className="">

                                                <Field type='text' name='StreetAddressLine2' placeholder='Street Address Line 2'
                                                    className={
                                                        `form-control
                                                        ${errors.StreetAddressLine2 && touched.StreetAddressLine2 ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="StreetAddressLine2" /></p>
                                            </div>

                                            <div className="col-12 col-lg-6">
                                                <Field type='text' name='City' placeholder='City'
                                                    className={
                                                        `form-control
                                                        ${errors.City && touched.City ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="City" /></p>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <Field component='select' name='States' className={
                                                    `form-control
                                                          ${errors.States && touched.States ? ' is-invalid' : ''}`
                                                }>
                                                    <option >States</option>
                                                    <option value={71}>Yes</option>
                                                    <option value={82}>No</option>
                                                </Field>

                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="States" /></p>
                                            </div>

                                            <div className="col-12 col-lg-6">
                                                <Field type='text' name='PostalCode' placeholder='Postal Code'
                                                    className={
                                                        `form-control
                                                        ${errors.PostalCode && touched.PostalCode ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="PostalCode" /></p>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <Field component='select' name='Country' className={
                                                    `form-control
                                                          ${errors.Country && touched.Country ? ' is-invalid' : ''}`
                                                }>
                                                    <option >Country</option>
                                                    <option value={71}>Yes</option>
                                                    <option value={82}>No</option>
                                                </Field>

                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Country" /></p>
                                            </div>

                                            <div className="">

                                                <Field as={'textarea'} rows={4} type='text' name='EnterMessage' placeholder='Message'
                                                    className={
                                                        `form-control
                                                     ${errors.EnterMessage && touched.EnterMessage ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="EnterMessage" /></p>
                                            </div>





                                            <div className="d-flex justify-content-center">
                                                <button type="submit" className="btn btn-success fs-4" style={{ padding: "10px 20px" }}>
                                                    Submit
                                                </button>
                                            </div>

                                        </Form>
                                    }}
                                </Formik>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="bank_deatials">
                <p className="tophead">For transferring directly in the bank (choose the bank details as applicable in your case):</p>
                <div className="row">
                    <div className="col-lg-6 col-12" style={{ borderRight: "1px solid lightgrey" }}>
                        <h6 className="h6tag">For Indian passport holders, staying abroad or Indian-tax payers.</h6>
                        <div className="bankinfo">
                            <p><strong> Bank:</strong> ICICI BANK</p>
                            <p><strong> Address:</strong> ICICI Bank Ltd., Bhayandar (W) Branch, Thane-401101, Maharashtra, India.</p>
                            <p><strong> Beneficiary Name:</strong> Green Yatra Trust</p>
                            <p><strong> Account No.:</strong> 00282581</p>
                            <p><strong> RTGS / NEFT / IFSC:</strong> ICIC0028</p>
                            <p><strong> Account Type:</strong> Saving</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <h6 className="h6tag">ONLY for foreign passport holders, as transaction from anyone else is a violation of the law.</h6>
                        <div className="bankinfo">
                            <p><strong> Bank:</strong> SBI Bank</p>
                            <p><strong> Address:</strong> FCRA Cell, 4th Floor, State Bank of India, New Delhi Main Branch, 11, Sansad Marg, New Delhi-110001</p>
                            <p><strong> Beneficiary Name:</strong> Green Yatra Trust</p>
                            <p><strong> Account No.:</strong> 00280555581</p>
                            <p><strong> Account Type:</strong> Saving</p>
                            <p><strong> Branch Code:</strong> 005591</p>
                            <p><strong> IFSC:</strong> SBIN0065591</p>
                            <p><strong> Swift Code:</strong> SBIN444B104</p>
                        </div>
                    </div>

                </div>

            </div>

            <div className="bottomdiv">
                <h5><FaArrowAltCircleRight className="icon" />Please mail us the following details to trace donations, issue receipt and for proper account maintenance</h5>
                <ul>
                    <li>Date and Mode of remittance</li>
                    <li>Transfer Amount [in currencies]</li>
                    <li>Transfer Reference Number</li>
                    <li>Nationality of the Donor [kindly mail us the scanned copy of the first and last page of your passport]</li>
                    <li>Account to which the remittance is made</li>
                    <li>Donor’s address and phone number</li>
                </ul>
                <p>For any further detail and query feel free to write Mr.Pradeep Tripathi on pradeep@greenyatra.org and Mr.Durgesh Gupta on durgesh@greenyatra.org</p>
                <h6>For Green Yatra regular operations</h6>
                <p>Kindly send your Cheque Demand Draft favouring "Green Yatra Trust" and other kind of contributions in the following address</p>
                <p>Head office: 308, Eco Star, Vishveshwar Nagar Rd, Churi Wadi, Goregaon (East) <br />
                    Mumbai - 400063 Maharashtra, India.<br />
                    Contact: +91 9964538849 |+91 2256456649
                </p>

            </div>


            <NGOTrustFooter />

        </>
    )
}

export default DonateNow
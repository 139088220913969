import RestaurantFooter from "../../common/Restaurant/RestaurantFooter/RestaurantFooter"
import RestaurantHeader from "../../common/Restaurant/RestaurantHeader/RestaurantHeader"
import '../../styles/RestaurantAboutUsStyle.css'
import RestaurantAboutUsData from "../../data/RestaurantAboutUsData"
import imgpresident from '../../assest/restaurantImg/people3.jpg'


const RestaurantAboutUs = () => {
    return (
        <>
            <RestaurantHeader />

            <div className="BusinessSuppliers_head">
                <h4>About Us</h4>
            </div>
            <div className="container">
                <p className="ptagg">Food service business has emerged more appealing now than it used to be a decade back. Driving factors like urbanisation, wide exposure to international cuisines, a rising mentality to experiment with food and all the more young population opting for ‘dine-out’ or prepared food have contributed the growth story of Indian food service business. Recognizing such huge potential, Franchise India has initiated to provide a platform to entrepreneurs who want to taste success in the domestic and international food service business where different measures of food business can be discussed. Restaurant India is one such unique platform where the stakeholders of food business fraternity come together to talk about their success stories. The specially designed case studies of failed ventures aware restaurant owners about a failed strategy offering enormous support. The website boasts of authentic news updates, interviews, web features covering different issues and best practices from the domestic and international food business fraternity as well.</p>

                <div className="Aboutuspage">
                    <div className="About_Restaurant">
                        {RestaurantAboutUsData?.map((item, index) => (
                            <div className="About_detail">
                                <div key={item.id} className="AboutRestaurant_imgdiv">
                                    <img src={item.imgSrc} alt={`img-${item.id}`} />
                                </div>

                                <div className="">
                                    <h4>{item.heading}</h4>
                                    <p>{item.textInfo}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="note">
                    <h4>Note From President</h4>
                    <div className="note_by_chairman">
                        <div>
                            <img src={imgpresident} alt='img' />
                            <h6>Gaurav Marya Chairman, Franchise India Holdings Ltd.</h6>
                        </div>
                        <div>
                            <p>3Ps - Principle, People and Partnership In the last seven years of successful franchising we have laid these 3Ps as our foundation. First is PRINCIPLE, to be 'Investor Centric... Franchise Focussed.' We understand the importance of an investor and are centric to work towards his interest. All our businesses are dedicated to inform and assist the investor. As pioneers in Indian franchising, all our divisions are either franchise supply or profitable franchise opportunities. Along with principle, we believe in our PEOPLE. FIHL is a people's company. HERE I would like to thank all our partners, vendors, team members and our clients for their valuable support. Happy Franchising !</p>
                        </div>
                    </div>
                </div>
            </div>

            <RestaurantFooter />

        </>
    )
}

export default RestaurantAboutUs
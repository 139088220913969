import { Box, Button } from "@mui/material"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx"
import '../../styles/NGOTrustHomeStyle.css'
import { Link } from "react-router-dom"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OurPartnerData from "../../data/OurPartnerData"
import { MdEmail, MdOutlinePunchClock, MdLocalPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NgoGreenTreeImageData from "../../data/NgoGreenTreeImageData"
import greenfield6 from '../../assest/greenfield6.webp'
import greenfield1 from '../../assest/greenfield1.webp'
import greenfield2 from '../../assest/greenfield2.webp'
import greenfield3 from '../../assest/greenfield3.webp'
import greenfield4 from '../../assest/greenfield4.webp'
import Volunteer from '../../assest/Volunteer.webp'
import corporate from '../../assest/corporate.webp'
import community from '../../assest/community.webp'
import calendar from '../../assest/calendar.webp'
import planting from '../../assest/planting.webp'
import teamwork from '../../assest/teamwork.webp'
import volunteer from '../../assest/volunteer_1.webp'
import control from '../../assest/control.webp'
import family from '../../assest/family.webp'



const NGOTrustHome = () => {
    const defalutValue = {
        FullName: '',
        EmailID: '',
        EnterSubject: '',
        ContactNumber: '',
        EnterMessage: '',

        ///
        FirstName:'',
        LastName:'',
        EmailAddress:'',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        FullName: yup.string().required('Full Name is Requird!'),
        EmailID: yup.string().required('Email is Requird!'),
        EnterSubject: yup.string().required('Enter Subject is Requird!'),
        ContactNumber: yup.string().required('Contact Number is Requird!'),
        EnterMessage: yup.string().required('Enter Message is Requird!'),
        FirstName: yup.string().required('First Name is Requird!'),
        LastName: yup.string().required('Last Name is Requird!'),
        EmailAddress: yup.string().required('Email Address is Requird!'),


    })
    const handleSubmit = (value) => {
        console.log(value);

    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 5,
                },
            }
        ],
    };
    return (
        <>
            <NGOTrustHeader />
            <TopSliderAllMax images={NgoGreenTreeImageData} />
            <Box className='welcomeDiv'>
                <h1>WELCOME TO <span>GREEN YATRA</span></h1>
                <h6>A devoted Green Yatra for a Greener and Sustainable World</h6>
                <hr className=" mx-5 fw border-3" />
                <p>Green Yatra is a leading environmental NGO in India working towards achieving the Sustainable Development Goals (SDGs) through tree plantation, urban biodiversity conservation, water bodies restoration, sustainable solutions, and environmental research and awareness. We are a rare blend of resources equipped with traditional wisdom and modern, innovative technologies to help corporate achieving their NET Zero Goal.</p>
                <h6>We are registered with 12 A, 80G, FCRA and UNEP.</h6>
                <Button className=''> Read More </Button>
            </Box>

            <Box className="NGOTrustHome row">
                <div className="ngo_imggdiv col-lg-6 col-12">
                    <img src={greenfield6} alt="img" />
                </div>

                <div className="NGO_textdiv col-lg-6 col-12">
                    <h1>Miyawaki Forest</h1>
                    <h6>Urban Dense Forests In Less Time And Space</h6>
                    <p>We can grow ultra-fast growing dense, native forests in small patches in different places of the city, such as residential housing societies, gardens, industrial areas, corporate and IT parks and MIDC areas. Forests grown under the Miyawaki method grow 10 times faster, 30 times denser and 100 times more biodiverse compared to a monoculture plantation.</p>
                    <Button>Read More</Button>
                </div>

            </Box>

            <Box className='ngo_ourwork'>
                <div className="welcomeDiv">
                    <h1 className="ps-2">OUR FEATURED <span>WORK</span> </h1>
                </div>
                <div className="row ngo_ourwork_detail ps-3">
                    <Link className="col-lg-3 col-12 linktag_ngo" to={'/home_area'}>
                        <div className="ngo_ourwork_area">
                            <div>
                                <img src={greenfield1} alt="img" />
                            </div>
                            <div>
                                <h5>Miyawaki Dense Forest</h5>
                            </div>
                            <div>
                                <Button>Read More</Button>
                            </div>

                        </div>
                    </Link>
                    <Link className="col-lg-3 col-12 linktag_ngo" to={'/'}>
                        <div className="ngo_ourwork_area">
                            <div>
                                <img src={greenfield3} alt="img" />
                            </div>
                            <div>
                                <h5>Water Body Restoration</h5>
                            </div>
                            <div>
                                <Button>Read More</Button>
                            </div>

                        </div>
                    </Link>
                    <Link className="col-lg-3 col-12 linktag_ngo" to={'/'}>

                        <div className="ngo_ourwork_area">
                            <div>
                                <img src={greenfield2} alt="img" />
                            </div>
                            <div>
                                <h5>Livelihood Plantation</h5>
                            </div>
                            <div>
                                <Button>Read More</Button>
                            </div>
                        </div>
                    </Link>
                    <Link className="col-lg-3 col-12 linktag_ngo" to={'/'}>

                        <div className="ngo_ourwork_area">
                            <div>
                                <img src={greenfield4} alt="img" />
                            </div>
                            <div>
                                <h5>Carbon Footprint Auditing</h5>
                            </div>
                            <div>
                                <Button>Read More</Button>
                            </div>
                        </div>

                    </Link>


                </div>
            </Box>

            <div className="GreenYatra_btndiv">
                <Button>Green Yatra And Salesforce Campaign</Button>
            </div>



            <Box className='ngo_ourwork'>
                <div className="welcomeDiv">
                    <h1 className="ps-2">WHAT YOU <span>CAN DO</span> </h1>
                    <h6 className="my-4">All our workshops, tree plantations, presentations, awareness programs and educational activities are ABSOLUTELY FREE for government schools, colleges and housing societies.</h6>
                </div>
                <div className=" row">

                    <Link className="col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>
                        <div className="workshopsdiv">
                            <div className="img_workdiv">
                                <img src={Volunteer} alt="img" />
                            </div>
                            <div>
                                <h5>Volunteer</h5>
                                <p>We believe bringing positive change in a large scale is not a single handed process. It must be supported by the 'Power Of People’ And the Power of people must be led by 'Power of Unity’ with a good deed.</p>
                            </div>
                            <div>
                                <Button>Read More</Button>
                            </div>

                        </div>
                    </Link>
                    <Link className="col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>

                        <div className="workshopsdiv">
                            <div className="img_workdiv">
                                <img src={corporate} alt="img" />
                            </div>
                            <div>
                                <h5>Corporates</h5>
                                <p>We enable CSR activities, which are the responsibility as well as an opportunity for corporates to impact social, environmental and economic dimensions. Thereby create a more sustainable earth and better world for humanity.</p>
                            </div>
                            <div>
                                <Button>Read More</Button>
                            </div>
                        </div>
                    </Link>
                    <Link className=" col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>

                        <div className="workshopsdiv">
                            <div className="img_workdiv">
                                <img src={community} alt="img" />
                            </div>
                            <div>
                                <h5>Communities</h5>
                                <p>We invite you to come together with your community (school, college, housing societies etc.) for the betterment of the environment. It is a step towards creating a big change in society.</p>
                            </div>
                            <div>
                                <Button>Read More</Button>
                            </div>
                        </div>

                    </Link>


                </div>
            </Box>

            <div className="container_impact">
                <div className="row pb-90">
                    <div className="welcomeDiv">
                        <h1 className="ps-2 text-white">IMPACT AND REACH</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="funfact text-center">
                            <img src={planting} alt="img" />
                            <h2 data-animation-duration data-value="10,000,00+" className="animate-number text-white  number-font font-weight-500 my-2 appeared">10,000,00+</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Trees Planted</h6>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="funfact text-center">
                            <img src={teamwork} alt="img" />
                            <h2 data-animation-duration data-value="300+" className="animate-number text-white  number-font font-weight-500 my-2 appeared">300+</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Partners</h6>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="funfact text-center">
                            <img src={calendar} alt="img" />
                            <h2 data-animation-duration data-value="4000+" className="animate-number text-white  number-font font-weight-500 my-2 appeared">4,000+</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Events</h6>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="funfact text-center">
                            <img src={volunteer} alt="img" />
                            <h2 data-animation-duration data-value="20,000+" className="animate-number text-white  number-font font-weight-500 my-2 appeared">20,000+</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Volunteers</h6>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="funfact text-center">
                            <img src={family} alt="img" />
                            <h2 data-animation-duration data-value="200000+" className="animate-number text-white  number-font font-weight-500 my-2 appeared">200,000+</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Farmers and families supported</h6>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="funfact text-center">
                            <img src={planting} alt="img" />
                            <h2 data-animation-duration data-value="60+" className="animate-number text-white  number-font font-weight-500 my-2 appeared">60+</h2>
                            <h6 className="text-white text-uppercase font-weight-600">No of Miyawaki Forest Created</h6>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="funfact text-center">
                            <img src={control} alt="img" />
                            <h2 style={{ fontSize: '35.5px !important' }} data-animation-duration data-value="199.46+ Cr liters" className="animate-number text-white  number-font font-weight-500 my-2 appeared">199.46+ Cr liters</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Water Conserved<br /> 20+ Water Bodies Restored</h6>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="funfact text-center">
                            <img src={teamwork} alt="img" />
                            <h2 data-animation-duration data-value="28,000+ Days" className="animate-number text-white  number-font font-weight-500 my-2 appeared">28,000+ Days</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Total Employment Generated</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our_partner">
                {/* <h1 className='fw-bold ms-3 text-white'>{title}</h1> */}
                <Slider {...settings}>
                    {OurPartnerData?.map((item, index) => (
                        <div key={item.id} className="item-topimg">
                            <img style={{ width: '200px', height: '300px', objectFit: 'contain' }} src={item.imgSrc} alt={`img-${item.id}`} />
                        </div>
                    ))}
                </Slider>
            </div>

            <Box className='row bg-success-subtle overflow-hidden mt-5'>
                <div className=" col-lg-6 col-12 p-5">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="card mb-4">

                                <div className="welcomeDiv">
                                    <h1 className="ps-2">Quick Contact <span>Form</span></h1>
                                </div>

                                <div className="card-body row">
                                    <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                        {({ errors, touched, resetForm }) => {
                                            return <Form className="row">

                                                <div className="col-6">

                                                    <Field type='text' name='FullName' placeholder='Full Name'
                                                        className={
                                                            `form-control
                                                    ${errors.FullName && touched.FullName ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FullName" /></p>
                                                </div>

                                                <div className="col-6">

                                                    <Field type='text' name='EmailID' placeholder='Email ID'
                                                        className={
                                                            `form-control
                                                    ${errors.EmailID && touched.EmailID ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="EmailID" /></p>
                                                </div>
                                                <div className="col-6">

                                                    <Field type='text' name='EnterSubject' placeholder='Enter Subject'
                                                        className={
                                                            `form-control
                                                    ${errors.EnterSubject && touched.EnterSubject ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="EnterSubject" /></p>
                                                </div>
                                                <div className="col-6">

                                                    <Field type='text' name='ContactNumber' placeholder='Contact Number'
                                                        className={
                                                            `form-control
                                                    ${errors.ContactNumber && touched.ContactNumber ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="ContactNumber" /></p>
                                                </div>

                                                <div className="">

                                                    <Field as={'textarea'} type='text' name='EnterMessage' placeholder='Enter Message'
                                                        className={
                                                            `form-control
                                                    ${errors.EnterMessage && touched.EnterMessage ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="EnterMessage" /></p>
                                                </div>





                                                <div className="d-flex">
                                                    <button type="submit" className="btn btn-success" style={{ margin: "20px 0" }}>
                                                        Send Message
                                                    </button>
                                                    <button type="button" className="btn btn-success" onClick={resetForm} style={{ margin: "20px 10px" }}>
                                                        Reset
                                                    </button>
                                                </div>

                                            </Form>
                                        }}
                                    </Formik>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div className=" col-lg-6 col-12 ContactUs p-5">
                    <div className="welcomeDiv smallscrenpadding">
                        <h1 className="ps-2">Contact <span>Us</span></h1>
                    </div>

                    <div className="contactdiv">
                        <div className="ngo_icondiv">
                            <p> <IoLocationSharp className=" fs-1 p-2" /></p>
                        </div>
                        <div className="ngocontact">
                            <p>
                                <strong>Address : </strong> 408, Ecostar, Vikas Nagar,
                                Off Aarey road Churi Wadi, Goregaon East,
                                Mumbai 400063, Maharashtra, India
                            </p>
                        </div>

                    </div>

                    <div className="contactdiv">
                        <div className="ngo_icondiv">
                            <p> <MdLocalPhone className=" fs-1 p-2" /></p>
                        </div>
                        <div className="ngocontact">
                            <p> <strong>Phone : </strong>9897656564</p>
                        </div>

                    </div>

                    <div className="contactdiv">
                        <div className="ngo_icondiv">
                            <p> <MdEmail className=" fs-1 p-2" /></p>
                        </div>
                        <div className="ngocontact">
                            <p> <strong>Email ID : </strong> abarisoftech@gmail.com</p>
                        </div>

                    </div>

                    <div className="contactdiv">
                        <div className="ngo_icondiv">
                            <p> <MdOutlinePunchClock className=" fs-1 p-2" /></p>
                        </div>
                        <div className="ngocontact">
                            <p> <strong>Office Hours : </strong> 10 AM To 6 PM Mon-Sat</p>
                        </div>

                    </div>
                </div>
            </Box>

            <Box className='subscribebox'>
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="mb-4">
                            <div className="subscribediv">
                                <h1 className="ps-2">Subscribe to Our <span>Green Newsletter</span></h1>
                                <h6>Get regular updates on email</h6>
                            </div>

                            <div className="row">
                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">

                                            <div className="col-12 col-lg-2">

                                                <Field type='text' name='FirstName' placeholder='First Name'
                                                    className={
                                                        `form-control
                                                    ${errors.FirstName && touched.FirstName ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FirstName" /></p>
                                            </div>

                                            <div className="col-12 col-lg-2">

                                                <Field type='text' name='LastName' placeholder='Last Name'
                                                    className={
                                                        `form-control
                                                      ${errors.LastName && touched.LastName ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="LastName" /></p>
                                            </div>

                                            <div className="col-12 col-lg-2">

                                                <Field type='text' name='EmailAddress' placeholder='Email Address'
                                                    className={
                                                        `form-control
                                                    ${errors.EmailAddress && touched.EmailAddress ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="EmailAddress" /></p>
                                            </div>

                                            <div className="col-12 col-lg-2">
                                                <button type="submit" className="btn btn-success">
                                                    Sign up for green news
                                                </button>
                                            </div>

                                        </Form>
                                    }}
                                </Formik>
                            </div>

                        </div>


                    </div>

                </div>
            </Box>

            <NGOTrustFooter />

        </>
    )
}

export default NGOTrustHome

import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import wrldbg from '../../assest/wrldbg.webp'
import { FaChevronCircleRight } from "react-icons/fa";
import bannermiyawaki from '../../assest/banner-miyawaki.webp'
import kid1 from '../../assest/kid1.webp'
import kid2 from '../../assest/kid2.webp'
import kid3 from '../../assest/kid3.webp'
import kid4 from '../../assest/kid4.webp'




const CampusGreenProject = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={wrldbg} alt="img" />
                <h1 className="h1donate text-center">Campus Green Project</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv pb-0">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Campus <span>Green Project</span> </h1>
                    <h6 className="m-4" style={{ color: '#3aa348' }}>Building a Sustainable Future: Transforming Campus into a Green Hub</h6>
                </div>
                <div className="pedhlagao_div">
                    <h3>NEED OF THE <span>PROJECT:</span></h3>
                    <p className=" fs-5">The need for environmental awareness and sustainable practices has been more urgent. Campuses serve as crucial hubs for education, innovation, and community engagement. The Green Campus program aims to address the pressing need for sustainability education and on-ground environmental initiatives, creating a model for campuses to become centers for environmental consciousness and action.</p>
                </div>
            </div>

            <div class="row gap-5 Benefits">
                <div class="col-lg-12">
                    <div class="bdr bdr-txt3">
                        <h4><strong>OBJECTIVES:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Conduct online and offline sessions on various environmental topics, including climate change, environmental conservation, sustainable green lifestyle, environmental restoration, and more. These sessions will raise awareness, educate students, faculty, and staff on environmental challenges, and inspire them to adopt sustainable practices.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Execute on-ground projects within and outside the campus, such as establishing a Miyawaki forest, Sacred Groves, Native grass plantation, Medicinal plantation, Rare tree species plantation, Vegetable garden, and other initiatives. These projects will serve as tangible examples of sustainable practices and enhance the campus's biodiversity and ecological value.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Foster student leadership and involvement in sustainability initiatives by establishing student-led environmental clubs, organizing eco-campaigns, and encouraging participation in project planning, implementation, and monitoring.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Collaborate with local communities, government bodies, and environmental organizations to extend the impact beyond the campus borders, creating opportunities for community engagement, knowledge exchange, and shared responsibility for environmental stewardship.</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="bdr bdr-txt">
                        <h4><strong>TARGET BENEFICIARIES:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Students, faculty, and staff of the campus: The program will directly benefit individuals within the campus community by providing them with knowledge, resources, and opportunities to actively engage in sustainability practices and contribute to a greener campus.</li>
                        </ul>

                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="bdr bdr-txt1">
                        <h4><strong>EXPECTED IMPACT:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Increased environmental awareness and knowledge among campus stakeholders, leading to a shift in mindset towards sustainability and a sense of personal responsibility for environmental conservation.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Adoption of sustainable practices within the campus, such as waste reduction, energy efficiency, water conservation, and sustainable transportation, resulting in reduced environmental impact and resource consumption.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Improved campus biodiversity through the establishment of green spaces, native plantations, and habitat creation, promoting ecosystem health and resilience.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Empowered student leaders and environmental champions who can drive sustainability initiatives within the campus and beyond, creating a ripple effect in the community.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Enhanced collaboration between the campus and external stakeholders, fostering partnerships for community-driven environmental projects and strengthening the campus's role as a sustainability hub.</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="bdr bdr-txt1">
                        <h4><strong>BENEFITS TO FUNDER:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" /> Positive brand association and reputation in promoting sustainability and environmental conservation.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Demonstrated commitment to corporate social responsibility and addressing environmental challenges.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Opportunities for community engagement, partnerships, and networking.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Knowledge exchange and learning from the program's initiatives and projects.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Measurable impact and reporting, showcasing tangible outcomes.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Employee engagement and volunteerism.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Enhanced visibility and presence in the sustainability community.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Contribution to building a sustainable legacy and inspiring future environmental leaders.</li>
                        </ul>
                    </div>
                </div>


            </div>

            <div className="row justify-content-center gap-3 my-5">
                <div className=" col-lg-3 col-12">
                    <img className=" w-100 rounded-1" src={kid1} alt="img" />
                </div>
                <div className=" col-lg-3 col-12">
                    <img className=" w-100 rounded-1" src={kid2} alt="img" />
                </div>
                <div className=" col-lg-3 col-12">
                    <img className=" w-100 rounded-1" src={kid3} alt="img" />
                </div>
                <div className=" col-lg-3 col-12">
                    <img className=" w-100 rounded-1" src={kid4} alt="img" />
                </div>
            </div>
            <NGOTrustFooter />

        </>
    )
}

export default CampusGreenProject
import Handicraft from '../assest/restaurantImg/Handicraft (final).jpg'
import BeFunkycollage from '../assest/restaurantImg/BeFunky-collage (36).jpg'


const ServiceFashionAndBeautyData = [
    {
        id: 1,
        heading:'Denim Beyond Definition: Lee X Suneet Varma Collection',
        textInfo:'The collision of tradition and contemporary trends often sparks the most captivating creations, the Lee X Suneet Varma collection is a testament to…',
        byName:'By - Aritra Ghosh',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: BeFunkycollage,
    },
    {
        id: 2,
        heading:'Denim Beyond Definition: Lee X Suneet Varma Collection',
        textInfo:'The collision of tradition and contemporary trends often sparks the most captivating creations, the Lee X Suneet Varma collection is a testament to…',
        byName:'By - Aritra Ghosh',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Handicraft,
    },
    {
        id: 3,
        heading:'Denim Beyond Definition: Lee X Suneet Varma Collection',
        textInfo:'The collision of tradition and contemporary trends often sparks the most captivating creations, the Lee X Suneet Varma collection is a testament to…',
        byName:'By - Aritra Ghosh',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: BeFunkycollage,
    }, {
        id: 4,
        heading:'Denim Beyond Definition: Lee X Suneet Varma Collection',
        textInfo:'The collision of tradition and contemporary trends often sparks the most captivating creations, the Lee X Suneet Varma collection is a testament to…',
        byName:'By - Aritra Ghosh',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Handicraft,
    }, {
        id: 5,
        heading:'Denim Beyond Definition: Lee X Suneet Varma Collection',
        textInfo:'The collision of tradition and contemporary trends often sparks the most captivating creations, the Lee X Suneet Varma collection is a testament to…',
        byName:'By - Aritra Ghosh',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: BeFunkycollage,
    }, {
        id: 6,
        heading:'Denim Beyond Definition: Lee X Suneet Varma Collection',
        textInfo:'The collision of tradition and contemporary trends often sparks the most captivating creations, the Lee X Suneet Varma collection is a testament to…',
        byName:'By - Aritra Ghosh',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Handicraft,
    }
];
export default ServiceFashionAndBeautyData
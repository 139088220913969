
import VIPStore from '../assest/restaurantImg/VIP Store.jpg'
import Untitledd387 from '../assest/restaurantImg/387.jpg'


const ServiceTechnologyECommerceData = [
    {
        id: 1,
        heading:'VIP Clothing Plans to Step Up in the World of Quick Commerce',
        textInfo:'The quick commerce industry has experienced remarkable growth in recent years, driven by the increasing demand for fast and convenient delivery…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: VIPStore,
    },
    {
        id: 2,
        heading:'VIP Clothing Plans to Step Up in the World of Quick Commerce',
        textInfo:'The quick commerce industry has experienced remarkable growth in recent years, driven by the increasing demand for fast and convenient delivery…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Untitledd387,
    },
    {
        id: 3,
        heading:'VIP Clothing Plans to Step Up in the World of Quick Commerce',
        textInfo:'The quick commerce industry has experienced remarkable growth in recent years, driven by the increasing demand for fast and convenient delivery…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: VIPStore,
    }, {
        id: 4,
        heading:'VIP Clothing Plans to Step Up in the World of Quick Commerce',
        textInfo:'The quick commerce industry has experienced remarkable growth in recent years, driven by the increasing demand for fast and convenient delivery…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Untitledd387,
    }, {
        id: 5,
        heading:'VIP Clothing Plans to Step Up in the World of Quick Commerce',
        textInfo:'The quick commerce industry has experienced remarkable growth in recent years, driven by the increasing demand for fast and convenient delivery…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: VIPStore,
    }, {
        id: 6,
        heading:'VIP Clothing Plans to Step Up in the World of Quick Commerce',
        textInfo:'The quick commerce industry has experienced remarkable growth in recent years, driven by the increasing demand for fast and convenient delivery…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Untitledd387,
    }
];
export default ServiceTechnologyECommerceData

import content6044 from '../assest/restaurantImg/content6044.jpg'
import odiyana from '../assest/restaurantImg/odiyana.jpg'


const StartupData = [
    {
        id: 1,
        heading:'How this start-up is working on becoming an international biryani chain',
        textInfo:'In an interaction with Restaurant India, Kaushik Roy, Founder and CEO- Biryani by Kilo talks about the growing trend of biryani in India.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: content6044,
    },
    {
        id: 2,
        heading:'How this start-up is working on becoming an international biryani chain',
        textInfo:'In an interaction with Restaurant India, Kaushik Roy, Founder and CEO- Biryani by Kilo talks about the growing trend of biryani in India.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: odiyana,
    },
    {
        id: 3,
        heading:'How this start-up is working on becoming an international biryani chain',
        textInfo:'In an interaction with Restaurant India, Kaushik Roy, Founder and CEO- Biryani by Kilo talks about the growing trend of biryani in India.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: content6044,
    }, {
        id: 4,
        heading:'How this start-up is working on becoming an international biryani chain',
        textInfo:'In an interaction with Restaurant India, Kaushik Roy, Founder and CEO- Biryani by Kilo talks about the growing trend of biryani in India.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: odiyana,
    }, {
        id: 5,
        heading:'How this start-up is working on becoming an international biryani chain',
        textInfo:'In an interaction with Restaurant India, Kaushik Roy, Founder and CEO- Biryani by Kilo talks about the growing trend of biryani in India.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: content6044,
    }, {
        id: 6,
        heading:'How this start-up is working on becoming an international biryani chain',
        textInfo:'In an interaction with Restaurant India, Kaushik Roy, Founder and CEO- Biryani by Kilo talks about the growing trend of biryani in India.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: odiyana,
    }
];
export default StartupData
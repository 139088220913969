import RestaurantFooter from "../../common/Restaurant/RestaurantFooter/RestaurantFooter"
import RestaurantHeader from "../../common/Restaurant/RestaurantHeader/RestaurantHeader"
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import '../../styles/RestaurantContactUsStyle.css'


const RestaurantFeedback = () => {
    const defalutValue = {
        yourname: '',
        EmailID: '',
        Mobile: '',
        FeedbackTopic: '',
        Feedback: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        yourname: yup.string().required('Name is Requird!'),
        EmailID: yup.string().required('Email is Requird!'),
        Mobile: yup.string().required('Enter Mobile is Requird!'),
        FeedbackTopic: yup.string().required('Feedback Topic is Requird!'),
        Feedback: yup.string().required('Feedback is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }
    return (
        <>
            <RestaurantHeader />

            <div className="BusinessSuppliers_head">
                <h4>Feedback</h4>
            </div>
            <div className="container">
                <div className="card_body row">
                    <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                        {({ errors, touched, resetForm }) => {
                            return <Form className="row formdiv">

                                <div className="col-lg-6 col-12 contactform">
                                    <label className="form-label" htmlFor="">Name</label>
                                    <Field type='text' name='yourname' placeholder='Enter your name'
                                        className={
                                            `form-control
                                                    ${errors.yourname && touched.yourname ? ' is-invalid' : ''}`
                                        }
                                    />
                                    <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="yourname" /></p>
                                </div>

                                <div className="col-lg-6 col-12 contactform">
                                    <label className="form-label" htmlFor="">Email</label>
                                    <Field type='text' name='EmailID' placeholder='Enter your Email'
                                        className={
                                            `form-control
                                                    ${errors.EmailID && touched.EmailID ? ' is-invalid' : ''}`
                                        }
                                    />
                                    <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="EmailID" /></p>
                                </div>
                                <div className="col-lg-6 col-12 contactform">
                                    <label className="form-label" htmlFor="">Mobile</label>
                                    <Field type='text' name='Mobile' placeholder='Enter your Mobile'
                                        className={
                                            `form-control
                                                    ${errors.Mobile && touched.Mobile ? ' is-invalid' : ''}`
                                        }
                                    />
                                    <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Mobile" /></p>
                                </div>
                                <div className="col-lg-6 col-12 contactform">
                                    <label className="form-label" htmlFor="">Feedback Topic</label>
                                    <Field component='select' name='FeedbackTopic' className={
                                        `form-control
                                            ${errors.FeedbackTopic && touched.FeedbackTopic ? ' is-invalid' : ''}`
                                    }>
                                        <option > Select Feedback Topic</option>
                                        <option value={71}>Yes</option>
                                        <option value={82}>No</option>
                                    </Field>

                                    <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FeedbackTopic" /></p>
                                </div>
                               

                               


                                <div className="contactform">
                                    <label className="form-label" htmlFor="">Feedback</label>
                                    <Field as={'textarea'} rows={6} type='text' name='Feedback' placeholder='Enter Feedback'
                                        className={
                                            `form-control
                                                    ${errors.Feedback && touched.Feedback ? ' is-invalid' : ''}`
                                        }
                                    />
                                    <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Feedback" /></p>
                                </div>

                               

                                <div className="d-flex">
                                    <button type="submit" className="btn btn-danger px-4 py-2" style={{ margin: "20px 0" }}>
                                        Submit
                                    </button>
                                    {/* <button type="button" className="btn btn-success" onClick={resetForm} style={{ margin: "20px 10px" }}>
                                        Reset
                                    </button> */}
                                </div>

                            </Form>
                        }}
                    </Formik>
                </div>
            </div>

            <RestaurantFooter />

        </>
    )
}

export default RestaurantFeedback
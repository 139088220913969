
import BeFunky from '../assest/restaurantImg/BeFunky.jpg'
import FoodSafety from '../assest/restaurantImg/FoodSafety.jpg'


const RestaurantNewsData = [
    {
        id: 1,
        heading:'Mumbai Gets a New Culinary Destination ‘Novaara’',
        textInfo:'Mumbai has got an all-new innovative dining destination offering an unparalleled trifecta of experiences. Nestled in the vibrant area of Malad, Novaara with the motto of Bites, Brews & beyond is set to redefine the way we dine, drink, and dance, all under one roof.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: BeFunky,
    },
    {
        id: 2,
        heading:'Mumbai Gets a New Culinary Destination ‘Novaara’',
        textInfo:'Mumbai has got an all-new innovative dining destination offering an unparalleled trifecta of experiences. Nestled in the vibrant area of Malad, Novaara with the motto of Bites, Brews & beyond is set to redefine the way we dine, drink, and dance, all under one roof.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: FoodSafety,
    },
    {
        id: 3,
        heading:'Mumbai Gets a New Culinary Destination ‘Novaara’',
        textInfo:'Mumbai has got an all-new innovative dining destination offering an unparalleled trifecta of experiences. Nestled in the vibrant area of Malad, Novaara with the motto of Bites, Brews & beyond is set to redefine the way we dine, drink, and dance, all under one roof.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: BeFunky,
    }, {
        id: 4,
        heading:'Mumbai Gets a New Culinary Destination ‘Novaara’',
        textInfo:'Mumbai has got an all-new innovative dining destination offering an unparalleled trifecta of experiences. Nestled in the vibrant area of Malad, Novaara with the motto of Bites, Brews & beyond is set to redefine the way we dine, drink, and dance, all under one roof.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: FoodSafety,
    }, {
        id: 5,
        heading:'Mumbai Gets a New Culinary Destination ‘Novaara’',
        textInfo:'Mumbai has got an all-new innovative dining destination offering an unparalleled trifecta of experiences. Nestled in the vibrant area of Malad, Novaara with the motto of Bites, Brews & beyond is set to redefine the way we dine, drink, and dance, all under one roof.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: BeFunky,
    }, {
        id: 6,
        heading:'Mumbai Gets a New Culinary Destination ‘Novaara’',
        textInfo:'Mumbai has got an all-new innovative dining destination offering an unparalleled trifecta of experiences. Nestled in the vibrant area of Malad, Novaara with the motto of Bites, Brews & beyond is set to redefine the way we dine, drink, and dance, all under one roof.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: FoodSafety,
    }
];
export default RestaurantNewsData
import home7 from '../assest/home7.webp'
import plot from '../assest/plot.webp'
import home6 from '../assest/home6.webp'
import home2 from '../assest/home2.webp'
import home1 from '../assest/home1.webp'
import innerhome1 from '../assest/innerhome1.webp'


const MarxGroupGalleryData = [
    {
        id: 1,
        imgSrc: home7,
    },
    {
        id: 2,
        imgSrc: plot,
    },
    {
        id: 3,
        imgSrc: home6,
    }, {
        id: 4,
        imgSrc: home2,
    }, {
        id: 5,
        imgSrc: home1,
    }, {
        id: 6,
        imgSrc: innerhome1,
    }, {
        id: 7,
        imgSrc: home7,
    }, {
        id: 8,
        imgSrc: home1,
    }, {
        id: 9,
        imgSrc: plot,
    }, {
        id: 10,
        imgSrc: home7,
    },{
        id: 11,
        imgSrc: innerhome1,
    },
    {
        id: 12,
        imgSrc: plot,
    },
    {
        id: 13,
        imgSrc: home7,
    }, {
        id: 14,
        imgSrc: innerhome1,
    }, {
        id: 15,
        imgSrc: plot,
    }, {
        id: 16,
        imgSrc: home7,
    }, {
        id: 17,
        imgSrc: innerhome1,
    }, {
        id: 18,
        imgSrc: plot,
    }, {
        id: 19,
        imgSrc: home7,
    }, {
        id: 20,
        imgSrc: innerhome1,
    }
    , {
        id: 21,
        imgSrc: plot,
    }
    , {
        id: 22,
        imgSrc: home7,
    }
    , {
        id: 23,
        imgSrc: innerhome1,
    }
    , {
        id: 24,
        imgSrc: plot,
    }
    , {
        id: 25,
        imgSrc: home7,
    }
];
export default MarxGroupGalleryData
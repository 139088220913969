import React, { useState } from 'react'
import Logo from "../../../assest/marxWeddinglogo.webp";
import { Box, Button, Drawer } from '@mui/material';
import { MdOutlineMenu } from "react-icons/md";
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import SideBar from '../../realEstate/sidebar/SideBar';
import './NGOTrustHeaderStyle.css'


const NGOTrustHeader = () => {
    const [navActive, setNavActive] = useState("#");

    const [openSide, setOpenSide] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpenSide(newOpen);
    };


    return (
        <>
            <Box className="NGO_topHeader">
                <div className='heading-div-mobile'>
                    <Button onClick={toggleDrawer(true)}><MdOutlineMenu className=' fs-1textwhite' /></Button>
                    <Drawer open={openSide} onClose={toggleDrawer(false)}>
                        <SideBar setOpenSide={setOpenSide} />
                    </Drawer>
                </div>
                <a href="/NGO_trust"
                    className={navActive === "/NGO_trust" ? "active" : ""}
                    onClick={() => setNavActive("/NGO_trust")}
                >
                    <img className="logo" src={Logo} alt='Logo' />
                    <span className='ngo_compnyName'>Abaris Green Yatra</span>
                </a>

                <Box className='rightUL'>
                    <ul className="ngo_liauto">
                        <li>
                            <a
                                onClick={() => setNavActive("/NGO_trust")}
                                className={navActive === "/NGO_trust" ? "active" : ""}
                                href="/NGO_trust"
                            >
                                Home
                            </a>
                        </li>
                        <li className="">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    About Us
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/NGO_trust/about_us">Our Journey
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#media_coverage">Media Coverage</Dropdown.Item>
                                    <Dropdown.Item href="#awards">Awards</Dropdown.Item>
                                    <Dropdown.Item href="#team">Team</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/NGO_trust/our_work_gallery">Our Work Gallery</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>

                        <li className="">
                            <Dropdown className="custom-dropdown">
                                <Dropdown.Toggle className="modelbank" variant="" id="dropdown-basic">
                                    Initiatives
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="custom-dropdown-menu">
                                    <Dropdown.Item className="action">
                                        Concept Not On Nature Based Solution
                                        <Dropdown.Menu className="nested-dropdown-menu">
                                            <Dropdown.Item as={Link} to="/NGO_trust/Miyawaki">Miyawaki Forest</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/NGO_trust/water_body_restoration">Water Body Restoration</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/NGO_trust/nature_intraction_centre">Nature Intraction Centre</Dropdown.Item> 
                                            <Dropdown.Item as={Link} to="/NGO_trust/campus_green_project">Campus Green Project</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/NGO_trust/">Lantana Removal</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/NGO_trust/">Carbon Footprint Auditing</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/NGO_trust/">Climate Education</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/NGO_trust/">CRM</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/NGO_trust/">Mangroves Restoration</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/NGO_trust/">Conservation Of Rare Flora</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/NGO_trust/">Solar Project</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/NGO_trust/">Agroforestry</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/NGO_trust/pedh_lagao">Pedh Lagao</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/NGO_trust/miyawaki_forest">Miyawaki Forest</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/NGO_trust/sustainable_solutions">Sustainable Solutions</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/NGO_trust/sustainable_village_and_farmers">Sustainable Village And Farmers</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/NGO_trust/ecosystem_rejuvenation">Ecosystem Rejuvenation</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/NGO_trust/green_celebrations">Green Celebrations</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/NGO_trust/green_schools">Green Schools</Dropdown.Item>
                                    <Dropdown.Item className="action2">Other Initiatives
                                        <Dropdown.Menu className="nested-dropdown-menu">
                                            <Dropdown.Item as={Link} to="/">Male</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/">Female</Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/">Kids</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        <li className="">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    Get Involved
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/NGO_trust/volunteer">Volunteer</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/">Internship</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/">Careers</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        <li className="">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    Corporates
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/NGO_trust/CSR_activities">CSR Activities
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/NGO_trust/our_partners">Our Partners</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>

                        <li>
                            <Link
                                to="/NGO_trust/green_news"
                                onClick={() => setNavActive("/NGO_trust/green_news")}
                                className={navActive === "/NGO_trust/green_news" ? "active" : ""}
                            >
                                Green News
                            </Link>
                        </li>

                        <li>
                            <Link
                                onClick={() => setNavActive("/NGO_trust/blog")}
                                className={navActive === "/NGO_trust/blog" ? "active" : ""}
                                to="/NGO_trust/blog"
                            >
                                Blog
                            </Link>
                        </li>

                        <li>
                            <Link
                                onClick={() => setNavActive("/NGO_trust/contact_us")}
                                className={navActive === "/NGO_trust/contact_us" ? "active" : ""}
                                to="/NGO_trust/contact_us"
                            >
                                Contact
                            </Link>
                        </li>

                        <li>
                            <Link to={'/NGO_trust/donate_now'}>
                                <Button className='buttonnn'>
                                    Donate now
                                </Button>
                            </Link>
                        </li>
                    </ul>
                </Box>
            </Box>
        </>
    )
}

export default NGOTrustHeader

import Pankaj from '../assest/restaurantImg/Pankaj.jpg'
import Becollage from '../assest/restaurantImg/Becollage.jpg'


const ServicePeopleData = [
    {
        id: 1,
        heading:"How Bata's Power Brand is Taking Fashion Forward with the Launch of Power Apparel",
        textInfo:'Bata India, with a rich heritage spanning over a century, has not just witnessed the evolution of the footwear industry but has actively shaped its…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Becollage,
    },
    {
        id: 2,
        heading:"How Bata's Power Brand is Taking Fashion Forward with the Launch of Power Apparel",
        textInfo:'Bata India, with a rich heritage spanning over a century, has not just witnessed the evolution of the footwear industry but has actively shaped its…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Pankaj,
    },
    {
        id: 3,
        heading:"How Bata's Power Brand is Taking Fashion Forward with the Launch of Power Apparel",
        textInfo:'Bata India, with a rich heritage spanning over a century, has not just witnessed the evolution of the footwear industry but has actively shaped its…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Becollage,
    }, {
        id: 4,
        heading:"How Bata's Power Brand is Taking Fashion Forward with the Launch of Power Apparel",
        textInfo:'Bata India, with a rich heritage spanning over a century, has not just witnessed the evolution of the footwear industry but has actively shaped its…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Pankaj,
    }, {
        id: 5,
        heading:"How Bata's Power Brand is Taking Fashion Forward with the Launch of Power Apparel",
        textInfo:'Bata India, with a rich heritage spanning over a century, has not just witnessed the evolution of the footwear industry but has actively shaped its…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Becollage,
    }, {
        id: 6,
        heading:"How Bata's Power Brand is Taking Fashion Forward with the Launch of Power Apparel",
        textInfo:'Bata India, with a rich heritage spanning over a century, has not just witnessed the evolution of the footwear industry but has actively shaped its…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Pankaj,
    }
];
export default ServicePeopleData
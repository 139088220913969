
import teaImg from '../assest/restaurantImg/tea.jpg'
import CoffeeImg from '../assest/restaurantImg/Coffee.jpg'


const GrowthData = [
    {
        id: 1,
        heading:'Building a Coffee Business: 10 Key Strategies for Success in a Competitive Market',
        textInfo:'To meet the evolving needs of customers and improve efficiency, restaurant owners should invest in new technology to stay current with industry…',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: teaImg,
    },
    {
        id: 2,
        heading:'Building a Coffee Business: 10 Key Strategies for Success in a Competitive Market',
        textInfo:'To meet the evolving needs of customers and improve efficiency, restaurant owners should invest in new technology to stay current with industry…',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: CoffeeImg,
    },
    {
        id: 3,
        heading:'Building a Coffee Business: 10 Key Strategies for Success in a Competitive Market',
        textInfo:'To meet the evolving needs of customers and improve efficiency, restaurant owners should invest in new technology to stay current with industry…',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: teaImg,
    }, {
        id: 4,
        heading:'Building a Coffee Business: 10 Key Strategies for Success in a Competitive Market',
        textInfo:'To meet the evolving needs of customers and improve efficiency, restaurant owners should invest in new technology to stay current with industry…',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: CoffeeImg,
    }, {
        id: 5,
        heading:'Building a Coffee Business: 10 Key Strategies for Success in a Competitive Market',
        textInfo:'To meet the evolving needs of customers and improve efficiency, restaurant owners should invest in new technology to stay current with industry…',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: teaImg,
    }, {
        id: 6,
        heading:'Building a Coffee Business: 10 Key Strategies for Success in a Competitive Market',
        textInfo:'To meet the evolving needs of customers and improve efficiency, restaurant owners should invest in new technology to stay current with industry…',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: CoffeeImg,
    }
];
export default GrowthData
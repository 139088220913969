
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import './ServiceFooterStyle.css'

const ServiceFooter = () => {
    return (
        <>
            <div className="ServiceFooter">
                <ul>
                    <li>
                        <Link className="" to="">Home</Link>
                    </li>

                    <li>
                        <Link className="" to="/restaurant/about_us">About Us</Link>
                    </li>

                    <li>
                        <Link className="" to="/restaurant/contact_us">Contact Us</Link>
                    </li>

                    <li>
                        <Link className="" to="/restaurant/disclaimer">Disclaimer</Link>
                    </li>

                    <li>
                        <Link className="" to="">
                            <FaFacebookSquare className=' fs-2 ms-2 text-secondary' />
                        </Link>

                        <Link className="" to="">
                            <FaInstagramSquare className=' fs-2 ms-2 text-secondary' />
                        </Link>

                        <Link className="" to="">
                            <FaLinkedin className=' fs-2 ms-2 text-secondary' />
                        </Link>

                        <Link className="" to="">
                            <FaTwitterSquare className=' fs-2 ms-2 text-secondary' />
                        </Link>
                    </li>
                </ul>

                <div className="Copyright_service">
                    <p>Copyright © 2023 -  2024 Abarish Restaurant India.</p>

                </div>
            </div>
        </>
    )
}

export default ServiceFooter
import BeFunky from '../assest/restaurantImg/BeFunky.jpg'
import tea from '../assest/restaurantImg/tea.jpg'
import FoodSafety from '../assest/restaurantImg/FoodSafety.jpg'
import FoodTrendNew from '../assest/restaurantImg/Food Trend New.jpg'

const FoodServiceData = [
    {
        id: 1,
        heading:'How Indian Single Malts are leading the Market over Global Players',
        textInfo:'In recent years, India has experienced a remarkable transformation in its whisky industry, reshaping the appreciation of elite Indian single malts.…',
        byName:'By - Sakshi Singh',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: FoodTrendNew,
    },
    {
        id: 2,
        heading:'How Indian Single Malts are leading the Market over Global Players',
        textInfo:'In recent years, India has experienced a remarkable transformation in its whisky industry, reshaping the appreciation of elite Indian single malts.…',
        byName:'By - Sakshi Singh',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: tea,
    },
    {
        id: 3,
        heading:'How Indian Single Malts are leading the Market over Global Players',
        textInfo:'In recent years, India has experienced a remarkable transformation in its whisky industry, reshaping the appreciation of elite Indian single malts.…',
        byName:'By - Sakshi Singh',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: FoodTrendNew,
    }, {
        id: 4,
        heading:'How Indian Single Malts are leading the Market over Global Players',
        textInfo:'In recent years, India has experienced a remarkable transformation in its whisky industry, reshaping the appreciation of elite Indian single malts.…',
        byName:'By - Sakshi Singh',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: FoodSafety,
    }, {
        id: 5,
        heading:'How Indian Single Malts are leading the Market over Global Players',
        textInfo:'In recent years, India has experienced a remarkable transformation in its whisky industry, reshaping the appreciation of elite Indian single malts.…',
        byName:'By - Sakshi Singh',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: BeFunky,
    }, {
        id: 6,
        heading:'How Indian Single Malts are leading the Market over Global Players',
        textInfo:'In recent years, India has experienced a remarkable transformation in its whisky industry, reshaping the appreciation of elite Indian single malts.…',
        byName:'By - Sakshi Singh',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: tea,
    }
];
export default FoodServiceData
import { Box, Button } from "@mui/material"
import '../../styles/RestaurantSubscriptionPlanStyle.css'
import ServiceHeader from "../../common/Restaurant/serviceHeader/ServiceHeader";
import ServiceFooter from "../../common/Restaurant/ServiceFooter/ServiceFooter";
import { IoMdRadioButtonOn } from "react-icons/io";
import { FaCheck } from "react-icons/fa";

const RestaurantSubscriptionPlan = () => {

    return (
        <>
            <ServiceHeader />

            <Box>
                <div className="event-packge-tabpanel">
                    <div className="event-packge-text">
                        <div className="event-div-packge">
                            <h2>Choose your subscription plan</h2>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <p className="pt-1">Indian Retailer Premium Content Subscription. Get access to well reserached premium articles</p>
                    </div>

                    <div className="event-packages-plans">
                        <div className="event-nano-package">

                            <div className="event-imgboxdiv">
                                <h5>DIGITAL SUBSCRIPTION FOR 6MONTHS</h5>
                            </div>
                            <div className="planbox">
                                <p><IoMdRadioButtonOn className="icon fs-4" /> 6 months</p>
                                <p className="pred">₹1999/- <span> ₹999 </span> (50% OFF)</p>
                            </div>

                            <div className="event-packge-detail">
                                 <h5 className=" text-center mb-4">Benefits</h5>
                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Daily Exclusive Stories</p>
                                </div>
                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Exclusive Insights</p>
                                </div>

                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Premium Research</p>
                                </div>

                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Information and Analysis You Won't Find Anywhere Else</p>
                                </div>

                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Unlimited Access to Stories</p>
                                </div>

                            </div>

                            <div className="event-btn-div text-center mt-5">
                                <Button variant='contained'>subscribe now</Button>
                            </div>
                        </div>

                          <div className="event-nano-package">

                            <div className="event-imgboxdiv">
                                <h5>DIGITAL SUBSCRIPTION FOR 1YEAR</h5>
                            </div>
                            <div className="planbox">
                                <p><IoMdRadioButtonOn className="icon fs-4" /> 1 year</p>
                                <p className="pred">₹3999/- <span> ₹1899 </span> (52% OFF)</p>
                            </div>

                            <div className="event-packge-detail">
                                 <h5 className=" text-center mb-4">Benefits</h5>
                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Daily Exclusive Stories</p>
                                </div>
                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Exclusive Insights</p>
                                </div>

                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Premium Research</p>
                                </div>

                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Information and Analysis You Won't Find Anywhere Else</p>
                                </div>

                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Unlimited Access to Stories</p>
                                </div>

                            </div>

                            <div className="event-btn-div text-center mt-5">
                                <Button variant='contained'>subscribe now</Button>
                            </div>
                        </div>

                          <div className="event-nano-package">

                            <div className="event-imgboxdiv">
                                <h5>DIGITAL SUBSCRIPTION FOR 2YEAR</h5>
                            </div>
                            <div className="planbox">
                                <p><IoMdRadioButtonOn className="icon fs-4" /> 1 year</p>
                                <p className="pred">₹7999/- <span> ₹3499 </span> (56% OFF)</p>
                            </div>

                            <div className="event-packge-detail">
                                 <h5 className=" text-center mb-4">Benefits</h5>
                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Daily Exclusive Stories</p>
                                </div>
                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Exclusive Insights</p>
                                </div>

                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Premium Research</p>
                                </div>

                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Information and Analysis You Won't Find Anywhere Else</p>
                                </div>

                                <div>
                                    <p><FaCheck className="fs-6" /> </p>
                                    <p> Unlimited Access to Stories</p>
                                </div>

                            </div>

                            <div className="event-btn-div text-center mt-5">
                                <Button variant='contained'>subscribe now</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>

            <ServiceFooter />
        </>
    )
}

export default RestaurantSubscriptionPlan




import HowD2C from '../assest/restaurantImg/HowD2C.jpg'
import Untitleddesign from '../assest/restaurantImg/Untitled design.jpg'


const ServiceRetailBusinessData = [
    {
        id: 1,
        heading:'Decathlon India Sets Sights on Circular Economy Leadership by 2026',
        textInfo:'In recent years, sustainability has become a pivotal focus for brands worldwide. Companies are increasingly recognizing the need to adopt…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: HowD2C,
    },
    {
        id: 2,
        heading:'Decathlon India Sets Sights on Circular Economy Leadership by 2026',
        textInfo:'In recent years, sustainability has become a pivotal focus for brands worldwide. Companies are increasingly recognizing the need to adopt…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Untitleddesign,
    },
    {
        id: 3,
        heading:'Decathlon India Sets Sights on Circular Economy Leadership by 2026',
        textInfo:'In recent years, sustainability has become a pivotal focus for brands worldwide. Companies are increasingly recognizing the need to adopt…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: HowD2C,
    }, {
        id: 4,
        heading:'Decathlon India Sets Sights on Circular Economy Leadership by 2026',
        textInfo:'In recent years, sustainability has become a pivotal focus for brands worldwide. Companies are increasingly recognizing the need to adopt…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Untitleddesign,
    }, {
        id: 5,
        heading:'Decathlon India Sets Sights on Circular Economy Leadership by 2026',
        textInfo:'In recent years, sustainability has become a pivotal focus for brands worldwide. Companies are increasingly recognizing the need to adopt…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: HowD2C,
    }, {
        id: 6,
        heading:'Decathlon India Sets Sights on Circular Economy Leadership by 2026',
        textInfo:'In recent years, sustainability has become a pivotal focus for brands worldwide. Companies are increasingly recognizing the need to adopt…',
        byName:'By - Vaishnavi Gupta',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Untitleddesign,
    }
];
export default ServiceRetailBusinessData
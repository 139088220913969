import { Link } from "react-router-dom"
import RestaurantFooter from "../../common/Restaurant/RestaurantFooter/RestaurantFooter"
import RestaurantHeader from "../../common/Restaurant/RestaurantHeader/RestaurantHeader"
import '../../styles/BusinessSupplierStyle.css'
import { Button } from "@mui/material"
import PeopleData from "../../data/PeopleData"

const People = () => {
    return (
        <>
            <RestaurantHeader />

            <div className="BusinessSuppliers_head">
                <h4>People</h4>
            </div>
            <div className="container">
                <div className=" row">
                    <div className=" col-md-8 col-12">

                        <div className="Suppliers_Restaurant">
                            {PeopleData?.map((item, index) => (
                                <div className="Suppliers_detail">
                                    <div key={item.id} className="SuppliersRestaurant_imgdiv">
                                        <Link to={'/restaurant/people/detail'}>
                                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                                        </Link>
                                    </div>

                                    <div className="">
                                        <Link to={'/restaurant/people/detail'}>
                                            <h4>{item.heading}</h4>
                                        </Link>

                                        <p>{item.textInfo}</p>
                                        <div className='supp_info'>
                                            <p>{item.byName}</p>
                                            <p>{item.dateTime}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className=" col-md-4 col-12">
                        <div className="EventSessions">
                            <h4>Event & Sessions</h4>
                            <p>Explore Upcoming Events And New Age Agendas</p>
                            <Link>VIEW EVENT CALENDAR</Link>
                        </div>


                        <div className="Supplier_btndiv">
                            <h2>RELATED TOPICS</h2>
                            <div>
                                <Link to={'/restaurant/startup'}><Button>New Launch </Button></Link>
                                <Link to={'/restaurant/growth'}><Button>Growth </Button></Link>
                                <Link to={'/restaurant/operations'}><Button>Operations </Button></Link>
                                <Link to={'/restaurant/business_suppliers'}><Button>Business Suppliers </Button></Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <RestaurantFooter />

        </>
    )
}

export default People
import { Link } from "react-router-dom"
import { Box, Button } from "@mui/material"
import fea_retail from '../../assest/restaurantImg/fea_retail.jpg'
import fea_technology from '../../assest/restaurantImg/fea_technology.jpg'
import fea_cpg from '../../assest/restaurantImg/fea_cpg.jpg'
import fea_food from '../../assest/restaurantImg/fea_food.jpg'
import ServiceHeader from "../../common/Restaurant/serviceHeader/ServiceHeader"
import ServiceFooter from "../../common/Restaurant/ServiceFooter/ServiceFooter"
import ServiceD2CNewCommerceData from "../../data/ServiceD2CNewCommerceData"

const ServiceD2CNewCommerce = () => {
    return (
        <>
            <ServiceHeader />

            <div className="container">
                <div className="FoodServicediv">
                    <h1>D2C & New Commerce</h1>
                </div>

                <div className=" row">
                    <div className=" col-md-8 col-12">

                        <div className="FoodService_Restaurant">
                            {ServiceD2CNewCommerceData?.map((item, index) => (
                                <div className="FoodService_detail">
                                    <div key={item.id} className="FoodServiceRestaurant_imgdiv">
                                        <img src={item.imgSrc} alt={`img-${item.id}`} />
                                        <div className='imgdate'>
                                            <p>{item.dateTime}</p>
                                        </div>

                                    </div>

                                    <div className="">
                                        <p className=" textdanger">D2C & NEW COMMERCE</p>
                                        <h4>{item.heading}</h4>
                                        <p>{item.textInfo}</p>
                                        <div className='food_info'>
                                            <p>{item.byName}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className=" col-md-4 col-12">
                        <div className="FoodService_right">
                            <div className="Limitedoffer">
                                <p className="textsuccess">Limited time offer</p>
                                <h4>Annual Plan</h4>
                                <h1>₹1,899<span>/Year</span></h1>
                                <h3>₹3,999</h3>
                                <Button className="btnn1">at ₹159/month</Button>

                                <ul className="ulstories">
                                    <li>Read all the latest stories</li>
                                    <li>Complete access to archivesm</li>
                                    <li>All our subscriber-only newsletters, weekly and fortnightly</li>
                                </ul>

                                <Link to={'/restaurant/subscription_plan'}>
                                    <Button className="btnn2" fullWidth>SUBSCRIBE NOW</Button>
                                </Link>

                            </div>

                            <h4>Exclusive Insights</h4>
                            <p>Articles, editorial, and expert opinions</p>

                            <h4>Information and Analysis You Won't Find Anywhere Else</h4>
                            <p>Detailed articles, in-depth reporting</p>

                            <h4>Premium Research</h4>
                            <p>In-depth analysis and insights on retail, retail-tech, and a lot more</p>

                            <h4>Unlimited Access to Stories</h4>
                            <p>Access to our rich archive of stories</p>
                        </div>

                    </div>

                </div>

                <div className="LoadMoreDiv">
                <Button>Load More</Button>
            </div>

            <Box className='FeaturedCollections'>
                <div className=" text-center">
                    <h1 className="ps-2 fw-bold">Featured Collections</h1>
                </div>
                <div className="row FoodService_detail ">
                    <Link className="col-lg-3 col-12 linktag_food" to={'/home_area'}>
                        <div className="FoodService_area">
                            <div>
                                <img src={fea_retail} alt="img" />
                            </div>
                        </div>
                    </Link>
                    <Link className="col-lg-3 col-12 linktag_food" to={'/'}>
                        <div className="FoodService_area">
                            <div>
                                <img src={fea_technology} alt="img" />
                            </div>
                        </div>
                    </Link>
                    <Link className="col-lg-3 col-12 linktag_food" to={'/'}>

                        <div className="FoodService_area">
                            <div>
                                <img src={fea_cpg} alt="img" />
                            </div>
                        </div>
                    </Link>
                    <Link className="col-lg-3 col-12 linktag_food" to={'/'}>

                        <div className="FoodService_area">
                            <div>
                                <img src={fea_food} alt="img" />
                            </div>
                          
                        </div>

                    </Link>


                </div>
            </Box>
            </div>

           

            <ServiceFooter />

        </>
    )
}

export default ServiceD2CNewCommerce
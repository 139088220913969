import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import handshake from '../../assest/handshake.webp'
import OurPartnerData from "../../data/OurPartnerData"
import '../../styles/OurPartnersStyle.css'

const OurPartners = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={handshake} alt="img" />
                <h1 className="h1donate text-center">Our Partners</h1>
            </Box>

            <div className="Partners_head">
                <p>We have been associated and working with 200+ fortune and other prestigious companies, 200+ Reputed colleges, universities, 1000s of schools across India and 1000s of housing societies in Mumbai and suburbs. Here are some of our present past and partners and supporters.</p>
            </div>

            <div className=" row">
                <div className="Partners_imgdiv">
                {OurPartnerData.map((item) => (
                    <div className="col-12 col-lg-2">
                            <img src={item.imgSrc} alt="img" />
                    </div>
                        ))}
                </div>
            </div>
            <NGOTrustFooter />

        </>
    )
}

export default OurPartners

import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import volunteersbg from '../../assest/volunteersbg.webp'

const NgoFaq = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={volunteersbg} alt="img" />
                <h1 className="h1donate text-center">FAQ</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv pb-0">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4"> <span> FAQ</span> </h1>
                </div>

            </div>

            
            <div className=" row justify-content-center aboutus_div">
                    <div className="faq_pedh col-lg-12 col-12">
                        <h5>1. Registration :-</h5>
                        <h5>How to register?</h5>
                        <p>You can register by clicking on the "My Account" link at the top right corner of the homepage. Please provide the information in the form that appears.You can review the terms and conditions, provide your details and submit the registration information.</p>
                        <h5>Are there any charges for registration?</h5>
                        <p>There is no registration charges on Pedhlagao.com </p>
                        <h5>Do I have to necessarily register to shop on Pedhlagao?</h5>
                        <p>You can surf and add products to the cart without registration. Registered members have to be logged in at the time of checking out the cart, they will be prompted to do so if they are not logged in.You can shop on Pedhlagao.com as a guest also by providing necessary checkout details.</p>
                    </div>

                    <div className="faq_pedh col-lg-12 col-12">
                        <h5>2. Account Related :-</h5>
                        <h5>What is My Account?</h5>
                        <p>My Account is the section where you can reach after loging in to the website.</p>
                        <p>My Account allows you to track your active orders, credit note details as well as see your order history and update your contact details.Tags, product reviews, wishlist, referred friends.</p>
                        <h5>How do I reset my password?</h5>
                        <p>On the Login page and click on forgot password.</p>
                        <p>Please enter your registered email id and click on submit then on reset password will be sent to your email address. With this, you can change your password. In case of any further issues please contact our customer support team on support@pedhlagao.com.</p>
                        <h5>What is delivery confirmation number, why i need that?</h5>
                        <p>Delivery confirmation number is a unique digit numbers tied to every customer which enables you to give us service ratings at the time of order deliver or feedback.</p>
                    </div>

                    <div className="faq_pedh col-lg-12 col-12">
                        <h5>3. Payments :-</h5>
                        <h5>What are the modes of payment?</h5>
                        <p>You can pay for your order on Pedhlagao.com using the following modes of payment:</p>
                        <p>a. Credit and debit cards (VISA / Mastercard / Rupay)</p>
                        <p>b. Net Banking</p>

                        <h5>Are there any other charges or taxes in addition to the price shown? Is GST added to the invoice?</h5>
                        <p>The GST is included in the MRP of products. The prices you see on our product pages are the prices you pay.</p>
                        
                        
                        <h5>Is it safe to use my credit/ debit card on Pedhlagao?</h5>
                        <p>Yes it is absolutely safe to use your card on Pedhlagao.com. All credit and debit card payments are processed by fast, secure and trusted payment gateways.The banks may ask you for 3D secure pin or one time password which acts as a additional layers of security for your transaction. Pedhlagao offers you the highest standards of security available on the web so as to ensure that your shopping experience is private, safe and secure.</p>

                        <h5>Where do I enter the coupon code?</h5>
                        <p>Once you are done selecting your products and click on checkout you will be directed to payment method. On checkout page there is a box where you can enter coupon code that you have. The amount will automatically be deducted from your invoice value.</p>
                    </div>

                    <div className="faq_pedh col-lg-12 col-12">
                        <h5>4. Delivery Related :-</h5>
                        <h5>When will I receive my order?</h5>
                        <p>Once you are done selecting your products and click on checkout.</p>
                        <p>Your ordered plants will be planted to particular project.</p>
                        <h5>What about other hidden costs (sales taxes etc.)?</h5>
                        <p>There are no extra taxes, hidden costs or additional shipping charges. The prices mentioned on the website are the final prices.</p>
                        <h5>How do I check the current status of my order?</h5>
                        <p>
                        The only way you can check the status of your order is by contacting our customer support team or into your account by loginig in.</p>
                    </div>

                    <div className="faq_pedh col-lg-12 col-12">
                        <h5>5. Customer Related :-</h5>
                        <h5>What are your timings to contact customer service?</h5>
                        <p>Our customer service team is available throughout the week, all seven days from 7 am to 10 pm.support@Pedhlagao.com</p>
                        <h5>How can I give feedback?</h5>
                        <p>Our customer support team constantly strives to ensure the best shopping experience for all our customers. We would love to hear about your experience with Us.Do write to us info@Pedhlagao.com in case of positive or negative feedback.</p>
                    </div>
                    

                    <div className="faq_pedh col-lg-12 col-12">
                        <h5>6. Others :-</h5>
                        <h5>Do you have offline stores?</h5>
                        <p>No we are a purely online based company and we do not have any offline stores.</p>
                        <h5>How will I get my money back in case of a cancellation or return? What are the modes of refund?</h5>
                        <p>The amount will be refunded to your account within 8 to 10 days.</p>
                        <h5>I am a corporate/ business. Can I place orders with Pedhlagao ?</h5>
                        <p>Yes, we do bulk supply of plants at special prices to schools, restaurants and corporates. Please contact as at corporate@Pedhlagao.com to know more.</p>
                        <h5>How & where I can give my feedback?</h5>
                        <p>We always welcome feedback, both positive and negative from all our customers. Please feel free to write to us at info@Pedhlagao.com</p>
                    </div>
                </div>
            <NGOTrustFooter />

        </>
    )
}

export default NgoFaq
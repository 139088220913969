
 
 import mrDevashish from '../assest/sld3.webp'
import mrDevashishfounder from '../assest/sld3.webp'
import mrAnand from '../assest/sld3.webp'
import mrSanday from '../assest/sld3.webp'
import { Box } from "@mui/material"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import marxacedmy from '../assest/marxWeddinglogo.webp'
import marxMedia from '../assest/marxWeddinglogo.webp'
import marxFashionlogo from '../assest/marxWeddinglogo.webp'
import marxFoundlogo from '../assest/marxWeddinglogo.webp'
import MarxGroupHeader from "../common/marxGroup/MarxGroupHeader";
import { Link } from "react-router-dom";
import MarxGroupFooter from '../common/marxGroup/MarxGroupFooter'
import '../styles/marxGroupStyle.css'
import marxEventLogo from '../assest/marxWeddinglogo.webp'
import realState from '../assest/marxWeddinglogo.webp'
import marxAgroFood from '../assest/marxWeddinglogo.webp'
import marxMotionPic from '../assest/marxWeddinglogo.webp'
import RunningSlide from '../common/RunningSlideAllMarx/RunningSlide'
import { useEffect } from 'react'
import MarxGroupGalleryData from '../data/MarxGroupGalleryData'




const Home = () => {
    useEffect(() => {
        document.title = "Mar’x Group Of Companies";
    }, []);


    const latestNews = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 3,
                },
            }
        ],
    };

    const openNewTab = (url) => {
        window.open(url, '_blank');
    };

    return (
        <>
            <MarxGroupHeader />
            <div className="main-div">
                <Box className="maindiv-box row">
                    <div className="alltabs col-3">
                        <p className='mange-tab' onClick={() => openNewTab('/real_estate')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxEventLogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>
                                 RealEstate Pvt.Ltd.
                            </Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/NGO_trust')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxMedia}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>NGO Trust Pvt.Ltd.</Link>
                        </p>
                        <p className='mange-tab' onClick={() => openNewTab('/restaurant')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFoundlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>Franchise Pvt.Ltd.</Link>
                        </p>
                        <p className='mange-tab' onClick={() => openNewTab('/marxouture')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'> Marriage Pvt.Ltd.</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/marxstate')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={realState}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>Martial Arts Pvt.Ltd.</Link>
                        </p>


                    </div>
                    <div className="tabpanels col-8">
                        <div>
                            <div className="about-marx-entmnt">
                                <h1>Welcome</h1>
                                <p>The Name Of Company Mar'x Has Been Inspired From The Famous German Philospher, Political Economist, Historian, Political Theorist Sociologist, Communist & Revolutionary Karl Marx As He Ruled The Entire World In 18th Century With His Modified Ideas, Hard Work, Determination & Will Power To Achive Goals In Life. Which Inspires Me To Do Something In Life As An Inspiration.</p>
                            </div>
                            <div className="about-marx-entmnt">
                                <h1>About Us</h1>
                                <p>
                                    Mar'x Group of Companies (M.g.c) has Emerged As one of the Biggest & Powerful Conglomerate in India which has been diversified into different sectors such as : Mar'x Events.Pvt.Ltd.(The fashion Consultants) Mar'x Entertainment Worldwide.Pvt.Ltd.(The Artists Manager) Mar'x Luxury Wedding's India Pvt.Ltd.(The House of Royalty) Mar'x Media Pvt.Ltd.(The Leading Pioneer's) Mar'x Fashion Couture Pvt.Ltd.(Define your own Style Statement) Mar'x Academy of Fashion & Pageants Pvt.Ltd.(MAFP) Mar'x Agro Foods India Pvt.Ltd.(FMCG) Mar'x Foundation.Inc. (A Non - Profit Organization) Mar'x International Club Pvt.Ltd.(M.I.C) Mar'x Motion & Pictures Alliance Pvt.Ltd. with Few More Up - Coming Ventures on Pan - India & Worldwide Level Respectively.
                                </p>
                            </div>

                            <div className="about-marx-entmnt">
                                <h1>Why to Choose Us</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>
                            <div style={{ color:'white', borderTop: '1px solid white' }}></div>
                            <div className="founder-chairmsg pt-4">

                                <div className="foundermsg">
                                    <img src={mrDevashishfounder} alt="img" />
                                    <p className=" fw-bold fs-4">Mr. Devashish Bhandari</p>
                                    <h1>Founder / Chairperson</h1>
                                    <p>(Message)</p>
                                    <p></p>
                                </div>
                            </div>

                            <div className="board-director" style={{ borderTop: '2px solid white' }}>
                                <h1>Board of Directors</h1>
                                <div className="each-director">
                                    <div className="">
                                        <img src={mrAnand} alt="img" />
                                        <p className=" fw-bold fs-4">Mr. Anand Chaurdhary</p>
                                        <p style={{ border: '1px solid white', padding: '10px' }}></p>
                                    </div>
                                    <div className="">
                                        <img src={mrSanday} alt="img" />
                                        <p className=" fw-bold fs-4">Mr. Sanjay Dubey</p>
                                        <p style={{ border: '1px solid white', padding: '10px' }}></p>
                                    </div>
                                    <div className="">
                                        <img src={mrDevashish} alt="img" />
                                        <p className=" fw-bold fs-4">Mr. Devashish Bhandari</p>
                                        <p style={{ border: '1px solid white', padding: '10px' }}></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Box>
            </div>

            <RunningSlide title="GALLERY" images={MarxGroupGalleryData} />

            <div className="latestnews-sldddiv">
                <h1 className=' text-white fw-bold ms-3'>UP-COMING VENTURES</h1>
                <div className="divlatest">
                    <Slider className="slidrbox" {...latestNews}>
                        <div className="div-slide">
                            <img src={marxacedmy} alt="img" />
                        </div>
                        <div className="div-slide">
                            <img src={marxFashionlogo} alt="img" />
                        </div>
                        <div className="div-slide">
                            <img src={marxMotionPic} alt="img" />
                        </div>
                        <div className="div-slide">
                            <img src={marxAgroFood} alt="img" />
                        </div>

                    </Slider>
                </div>
            </div>

            <MarxGroupFooter />
        </>
    )
}

export default Home

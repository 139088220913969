import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";
import './NGOTrustFooterStyle.css'

const contacts = [
    {
        type: 'whatsapp',
        value: '+91 996805566'
    },
    {
        type: 'whatsapp',
        value: '+91 996805566'
    }
];

const mail = [
    {
        recipient: 'info@abariswebsite.com',
        subject: '',
        body: ''
    }, {
        recipient: 'Bookings@abariswebsite.com',
        subject: '',
        body: ''
    }
];

function NGOTrustFooter() {
    const [openSubscribe, setOpenSubscribe] = useState(false)
    const openNewTab = (url) => {
        window.open(url, '_blank');
    };

    const toggleSubscribeBox = () => {
        console.log('Toggling subscribe box');
        setOpenSubscribe(!openSubscribe);
    };

    const emailClick = (recipient, subject, body) => {
        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    const handleContactClick = (phoneNumber) => {
        window.location.href = `whatsapp://send?phone=${encodeURIComponent(phoneNumber)}`;
    };

    return (
        <>

            <div className="ngo_footerdiv">
                <div className="topfooterblock">
                    <div className="container mainwapdiv">
                        <div className="aboutblock">
                            {/* <h2>About</h2> */}
                            <ul>
                                <li>
                                    <Link className="" to="/NGO_trust/faq">
                                        FAQs
                                    </Link>
                                </li>
                                <li>
                                    <Link className="" to="/NGO_trust/privacy_policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/NGO_trust/terms_and_conditions' className="">
                                        Terms & Conditions
                                    </Link>
                                </li>

                                <li>
                                    <Link className="" to="/NGO_trust/refund_policy">
                                        Refund Policy
                                    </Link>
                                </li>

                            </ul>
                        </div>

                        <div className="secondblock">
                            <h3>Contact Us</h3>
                            <div className="block">
                                <h2>Phone : </h2>
                                <div className="ptagg">
                                    {contacts.map((contact, index) => (
                                        <p key={index} onClick={() => handleContactClick(contact.value)}>
                                            {contact.value}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <div className="block">
                                <h2>Email : </h2>
                                <div className="ptagg">
                                    {mail.map((email, index) => (
                                        <p key={index}
                                            onClick={() => emailClick(email.recipient, email.subject, email.body)}
                                        >
                                            {email.recipient}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="midfooterblock">
                    <div className="block text-center">
                        <h2 className="">Follow Us on</h2>
                        <div className="innersocial">
                            <Link className="" to="https://www.facebook.com/devashish.bhandari">
                                <img
                                    className=""
                                    src="https://www.influglue.com/public/frontend/images/facebook_btn.png?v=1688734414"
                                    alt="facebook"
                                    style={{ width: 32, height: 32 }}
                                />
                            </Link>
                            <Link className="" to="https://www.facebook.com/devashish.bhandari">
                                <img
                                    className=""
                                    src="https://www.influglue.com/public/frontend/images/linkedin_btn.png?v=1688734414"
                                    alt="linkedin"
                                    style={{ width: 32, height: 32 }}
                                />
                            </Link>
                            <Link className="" to="https://www.facebook.com/devashish.bhandari">
                                <img
                                    className=""
                                    src="https://www.influglue.com/public/frontend/images/instagram_btn.png?v=1688734414"
                                    alt="instagram"
                                    style={{ width: 32, height: 32 }}
                                />
                            </Link>
                            <Link className="" to="https://www.facebook.com/devashish.bhandari">
                                <img
                                    className=""
                                    src="https://www.influglue.com/public/frontend/images/yahoo_btn.png?v=1688734414"
                                    alt="youTube"
                                    style={{ width: 32, height: 32 }}
                                />
                            </Link>

                        </div>
                    </div>
                </div>
                <div className="fotrblack">
                    <div className="copyrightblock">
                        {/* <div className="maincopyrightwapper">
              <div className="privacypolicy pt-3">
                <Link className="" to="/privacy-policy"> Privacy Policy </Link>
                <span>|</span>
                <Link to="/term-condition"> Terms & Conditions </Link>
              </div>
            </div> */}
                        <div className="copy mt-2">
                            Copyright © 2001 - 2024 Abaris Trust pvt. ltd . All Rights Reserved.
                        </div>
                        <div className="designdevelop d-flex justify-content-center mt-2">
                            <p>Website Developed & Design By Abaris SoftTech Pvt.Ltd</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default NGOTrustFooter;

import React, { useState } from 'react'
import Logo from "../../../assest/marxWeddinglogo.webp";
import "./RealEstateHeaderStyle.css";
import { Box, Button, Drawer } from '@mui/material';
import SideBar from '../sidebar/SideBar';
import { MdOutlineMenu } from "react-icons/md";
import { Link } from 'react-router-dom';


const RealEstateHeader = () => {
    const [navActive, setNavActive] = useState("#real_estate");

    const [openSide, setOpenSide] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpenSide(newOpen);
    };


    return (
        <>
            <Box className="topHeader">
                <div className='heading-div-mobile'>
                    <Button onClick={toggleDrawer(true)}><MdOutlineMenu className=' fs-1textwhite' /></Button>
                    <Drawer open={openSide} onClose={toggleDrawer(false)}>
                        <SideBar setOpenSide={setOpenSide} />
                    </Drawer>
                </div>
                <a href="/real_estate"
                    className={navActive === "/real_estate" ? "active" : ""}
                    onClick={() => setNavActive("/real_estate")}
                >
                    <img className="logo" src={Logo} alt='Logo' />
                    <span className='compnyName'>Abaris Real Estate</span>
                </a>
                
                <Box className='rightUL'>
                    <ul className="liauto">
                        <li>
                            <a
                                onClick={() => setNavActive("#real_estate")}
                                className={navActive === "#real_estate" ? "active" : ""}
                                href="#real_estate"
                            >
                                Home
                            </a>
                        </li>
                        <li>
                            <a
                                href="#about"
                                onClick={() => setNavActive("#about")}
                                className={navActive === "#about" ? "active" : ""}
                            >
                                About
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() => setNavActive("#selling")}
                                className={navActive === "#selling" ? "active" : ""}
                                href="#selling"
                            >
                                Selling
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() => setNavActive("#buying")}
                                className={navActive === "#buying" ? "active" : ""}
                                href="#buying"
                            >
                                Buying
                            </a>
                        </li>
                        <li>
                            <a
                                href="#dealing_sectors"
                                onClick={() => setNavActive("#dealing_sectors")}
                                className={navActive === "#dealing_sectors" ? "active" : ""}
                            >
                                Dealing Sectors
                            </a>
                        </li>
                        <li>
                            <a
                                href="#why_choose_us"
                                onClick={() => setNavActive("#why_choose_us")}
                                className={navActive === "#why_choose_us" ? "active" : ""}
                            >
                                Why choose us
                            </a>
                        </li>
                        <li>
                            <a
                                href="#testimonials"
                                onClick={() => setNavActive("#testimonials")}
                                className={navActive === "#testimonials" ? "active" : ""}
                            >
                                Testimonials
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() => setNavActive("#contact")}
                                className={navActive === "#contact" ? "active" : ""}
                                href="#contact"
                            >
                                Contact
                            </a>
                        </li>
                    </ul>
                </Box>
            </Box>
        </>
    )
}

export default RealEstateHeader
import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import miyawakiBanner from '../../assest/banner-miyawaki.webp'
import miyawaki5 from '../../assest/miyawaki5.webp'
import '../../styles/MiyawakiForestStyle.css'
import GalleryPhotos from "../../common/Gallery/Gallery"
import MiyawakiForestGalleryData from "../../data/MiyawakiForestGalleryData"

const MiyawakiForest = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={miyawakiBanner} alt="img" />
                <h1 className="h1donate text-center">Miyawaki Forest</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Miyawaki <span>Forest</span> </h1>
                </div>

                <div className="aboutus_div">
                    <p className=" fs-4">Indian Metro and Megacities have lost 50% of their green cover. Open spaces are reducing year by year and cities are slowly converting to concrete jungles. Mega and metropolitan cities need a solution where many dense patches of forests can be created. Miyawaki method is the way forward to bring a big quick change in a small area.</p>
                    <h4 className=" mt-5 mb-3">What is the Miyawaki Method?</h4>
                    <p className=" ">The Miyawaki Method was invented by Japanese botanist and expert in plant ecology, Dr Akira Miyawaki. This Method is a unique innovative Potential Natural Vegetation concept, proven to work worldwide, irrespective of soil and climatic conditions. More than 3,000 forests have been successfully created worldwide using this methodology.</p>
                    <p className=" ">In this method, 3-5 saplings are planted per square meter with a minimum of 20-50 different species, creating a multi-layered green forest with no maintenance required after a 2-3 year period.</p>
                    <img className="imgbigforest" src={miyawaki5} alt="img" />
                    <div>
                        <p className="text-center">MIYAWAKI DENSE FORESTS grow 10 times faster, they are 100 times more biodiverse, 30 times better noise and dust reduction and have 30 times more green surface area and is 100 organic, Up to 30 times better carbon dioxide absorption as compared to a monoculture plantation.</p>
                        <h6 className="text-center fw-bold">So we get a 100 YEAR OLD FOREST IN JUST 10 YEARS</h6>
                        <p className="text-center">We have created many miyawaki forests across India</p>
                    </div>
                </div>
            </div>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Miyawaki Plantation <span>Gallery</span> </h1>
                </div>

                <div>
                    <GalleryPhotos GalleryData={MiyawakiForestGalleryData}  />
                </div>

            </div>
            <NGOTrustFooter />

        </>
    )
}

export default MiyawakiForest
import Gallery from "react-photo-gallery";
import FadeIn from "react-fade-in";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useCallback, useState } from "react";
import './galleryStyle.css'


const GalleryPhotos = ({ GalleryData, title }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    // Custom render for each photo to include profileName
    const renderGalleryPhoto = ({ photo, index }) => {
        return (
            <div className="gallery-photo-container" key={photo.id} onClick={(event) => openLightbox(event, { photo, index })}>
                <img src={photo.src} alt={photo.profileName} className="gallery-photo" />
                {/* <div className="gallery-photo-caption">{photo.profileName}</div> */}
            </div>
        );
    };

    return (
        <>

            <FadeIn>
                <div className="albumContainer">
                    {/* <h1 className="gallerytitle">{title}</h1> */}
                    <Gallery className='photosimg' photos={GalleryData} renderImage={renderGalleryPhoto} onClick={openLightbox} />
                    <ModalGateway className='dddddd'>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox} className='sdsfghjfdasfgh'>
                                <Carousel className='scdfghggg'
                                    currentIndex={currentImage}
                                    views={GalleryData.map((photo) => ({
                                        ...photo,
                                        srcset: photo.srcSet,
                                        // caption: photo.profileName,
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>
            </FadeIn>
        </>
    )
}

export default GalleryPhotos

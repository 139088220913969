import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import plantingTrees from '../../assest/planting-trees.webp'
import air from '../../assest/air.webp'
import soil from '../../assest/soil.webp'
import wrldbg from '../../assest/wrldbg.webp'
import '../../styles/EcosystemRejuvenationStyle.css'
import { FaChevronCircleRight } from "react-icons/fa";
import flowChart3 from '../../assest/flow-chart3.webp'


const EcosystemRejuvenation = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={plantingTrees} alt="img" />
                <h1 className="h1donate text-center">Ecosystem Rejuvenation</h1>
            </Box>
            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Ecosystem <span>Rejuvenation</span> </h1>
                </div>
                <div className="activitiesDiv">
                    <p className=" fs-4">Our landscapes, atmosphere and water bodies are degrading around us at an exponential rate - there is large scale extinction of flora and fauna species, groundwater depletion, and major security threat to water, food and resources. Conservation and restoration of natural resources, natural systems, biodiversity, hydrological and nutrient cycles is the NEED OF THE HOUR, before everything is lost forever.</p>
                    <p className=" fs-4">We focus to identify, plan and develop strategic proposals to restore our degraded natural environment - air, water and land; and sustainably manage them. We work closely with local authorities and communities to revive these systems to mitigate climate change and create a sustainable future.</p>
                </div>

            </div>

            <div className="pedhlagao_div">
                <div className="">
                    <h5>AIR</h5>
                    <div className=" row">
                        <div className="col-lg-12 col-12 more_activities">
                            <div>
                                <img src={air} alt="img" />

                            </div>
                            <div>
                                <p>According to IQAir AirVisual, India is home to 22 out of 30 most polluted cities in the world, with more than 700 million Indians exposed to unhealthy air. At Green Yatra, our Pedh Lagao project is working tirelessly to clean air and absorb harmful gaseous pollutants and airborne particles. Through our various tree plantation drives, including Miyawaki Forests, Urban Plantation and Forestation, and Rural Livelihood Plantations, we continue to improve air quality PAN India.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="">
                    <h5>SOIL</h5>
                    <div className=" row">
                        <div className="col-lg-12 col-12 more_activities">
                            <div>
                                <img src={soil} alt="img" />

                            </div>
                            <div>
                                <p>As per the UN, currently 29% of India's land is considered degraded and requires urgent rejuvenation.India has almost 30% degraded land which is equivalent to the total area of Maharashtra, MP, and Rajasthan combined. Land rejuvenation is all about restoring damaged soil with healthier replacements, allowing trees and forests to grow efficiently Our tree plantations involve conducting extensive surveys of soil, biodiversity, climate and weather, and local flora and fauna. If the soil is unhealthy, land is rejuvenated through the addition of natural ingredients with more nourishing nutrients. By planting trees in enriched soil mixes, and nurturing them over time, land is rejuvenated to live healthier and longer. Further we promote organic farming and conduct mass tree plantation drives.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="">
                    <h5>WATER</h5>
                    <div className=" row">
                        <div className="col-lg-12 col-12 more_activities">
                            <div>
                                <img src={wrldbg} alt="img" />

                            </div>
                            <div>
                                <p>Day Zero, the day where all local water sources will run out, has been predicted for 21 cities in India by 2021. By 2030 India's water demand is projected to be twice the size of the available supply. Around 2,00,000 people die every year due to inadequate access to clean water and it is only going to get worse.</p>
                                <p>To prevent water scarcity and increase the water table, Green Yatra is working extensively in many aspects related to water conservation and management under Project Jal.</p>
                                <p>We install rainwater harvesting systems, recharge groundwater, rejuvenate and create new water bodies PAN India Our objective is to conserve and maintain the hydrological cycle of India and rejuvenate depleting water tables.</p>
                                <p>Water Conservation And Management: We use different methods such as creating small check dams, LBS, CCT, kunds/well, ponds, soak pits, trenches and artificial water bodies to tap rainwater and stream water.</p>
                                <p>Water Bodies Rejuvenation And Creation: We rejuvenate existing water bodies (lakes, ponds, rivers) and create new water bodies as well.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row Ecosystem_Benefits">
                <div class="col-lg-12">
                    <h3><strong>Benefits</strong></h3>
                </div>

                <div class="col-lg-6">
                    <div class="Ecosystem_bdr bdr-txt3">
                        <ul class="list-icon theme-colored">
                            <li><FaChevronCircleRight className="icons" />In turn empower rural livelihood</li>

                            <li><FaChevronCircleRight className="icons" />Economic benefits</li>

                            <li><FaChevronCircleRight className="icons" />Improve agricultural productivity</li>

                            <li><FaChevronCircleRight className="icons" />Improve flow of water, nutrients, fresh air and carbon</li>

                            <li><FaChevronCircleRight className="icons" />Avail ecosystem services = 55 trillion dollars annually</li>

                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="Ecosystem_bdr bdr-txt">
                        <ul class="list-icon theme-colored">
                            <li><FaChevronCircleRight className="icons" />Dust and pollution reduction</li>

                            <li><FaChevronCircleRight className="icons" />Flood and disaster control</li>

                            <li><FaChevronCircleRight className="icons" />Preservation and conservation of natural resources and biodiversity</li>

                            <li><FaChevronCircleRight className="icons" />Brand image and credibility</li>

                            <li><FaChevronCircleRight className="icons" />Engage and retain stakeholders and staff</li>

                        </ul>

                    </div>
                </div>

            </div>

            <div className="flowChart3">
                <img src={flowChart3} alt="img" />
            </div>
            <NGOTrustFooter />

        </>
    )
}

export default EcosystemRejuvenation
import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import digitalArt from '../../assest/digital-art.webp'
import flowChart2 from '../../assest/flow-chart2.webp'
import img3794722 from '../../assest/3794722.webp'
import '../../styles/SustainableSolutionStyle.css'
import { FaChevronCircleRight } from "react-icons/fa";
import watertap from '../../assest/watertap.webp'
import thunder from '../../assest/thunder.webp'
import img5264070 from '../../assest/5264070.webp'


const SustainableSolutions = () => {
    return (
        <>

            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={digitalArt} alt="img" />
                <h1 className="h1donate text-center">Sustainable Solutions</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Sustainable <span>Solutions</span> </h1>
                </div>

                <div className="aboutus_div">
                    <p className=" fs-5">Sustainability- is the ability to meet the needs of the present without compromising the needs of future generations.</p>
                    <p>It is the integration of social development, economic growth and environmental protection dimensions to solve a problem. No matter how big or small - every step towards sustainability makes an impact to save our planet and its resources. Responsible companies and individuals are taking steps towards a greener future. It’s time for you to be part of the change, the revolution...</p>

                    <h5 className=" mt-4 mb-3">Implementing sustainable solutions-</h5>
                    <ol>
                        <li>We design, plan and provide solutions on how you can make a difference in the wider community through implementing sustainable solutions.</li>
                        <li>We guide you about making your existing office premises and buildings green, by analyzing your water, energy and waste consumption.</li>
                    </ol>
                    <p className=" ">It may seem overwhelming and inconvenient, but our sustainable solutions for your needs through innovation lead to a positive impact for people, as well as benefits our planet.</p>
                    <img className="imgbigforest object-fit-cover" src={flowChart2} alt="img" />
                </div>
                <div className="pedhlagao_div">
                    <h3>Why? For Us and Our <span>Children</span></h3>
                    <p>As per the United Nations, we only have 12 years to limit climate change catastrophe (2018).</p>
                    <p>Theearth is designed to sustain symbiotically - every species, water droplet, air molecule and even humans are all inter-dependent on each other. As pollution and contamination increases, we too will cease to exist.</p>
                    <p>This requires US to act NOW. Strategic investment supporting the change to low-carbon, clean and sustainable growth will lead to economic gains and healthy lifestyles.</p>
                </div>
            </div>

            <div class="row Benefits">
                <div class="col-lg-12">
                    <h3><strong>Benefits</strong></h3>
                </div>

                <div class="col-lg-4">
                    <div class="bdr bdr-txt3">
                        <h4><strong>Economic</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li><FaChevronCircleRight className="icons" />High savings by reducing consumption, efficient resource productivity and optimize material  utilization</li>

                            <li><FaChevronCircleRight className="icons" />Savings of net income</li>

                            <li><FaChevronCircleRight className="icons" />Improved operational and management efficiency</li>

                            <li><FaChevronCircleRight className="icons" />Strategic advancement to comply with regulations</li>

                            <li><FaChevronCircleRight className="icons" />Increases property value</li>

                            <li><FaChevronCircleRight className="icons" />Fiscal stability with potential rise in water and energy  costs</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="bdr bdr-txt">
                        <h4><strong>Social</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li><FaChevronCircleRight className="icons" />Fosters healthy urban lifestyle</li>

                            <li><FaChevronCircleRight className="icons" />Extends customer relations and staff support</li>

                            <li><FaChevronCircleRight className="icons" />Boosts brand image and competitive advantage
                            </li>

                            <li><FaChevronCircleRight className="icons" />Lowers healthcare expenditure</li>

                            <li><FaChevronCircleRight className="icons" />Boosts productivity and learning capacity</li>

                            <li><FaChevronCircleRight className="icons" /> Connection to nature</li>
                        </ul>

                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="bdr bdr-txt1">
                        <h4><strong>Environmental</strong></h4>
                        <ul class="list-icon theme-colored">

                            <li><FaChevronCircleRight className="icons" />Long term management of resources at optimum
                                level - water, energy, food and waste</li>

                            <li><FaChevronCircleRight className="icons" />Reduction in pollution, waste, toxic emissions</li>

                            <li><FaChevronCircleRight className="icons" />Extends customer relations and staff support.</li>

                            <li><FaChevronCircleRight className="icons" />Preservation of natural services dispensed by forests, rivers, mountains - ecosystems </li>

                            <li><FaChevronCircleRight className="icons" />Lower air pollutant and  CO <sub>2</sub> &nbsp; emissions</li>

                            <li><FaChevronCircleRight className="icons" />Protect threatened species, wetlands, cultural areas, and pristine natural areas  </li>
                        </ul>
                    </div>
                </div>


            </div>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Waste <span>Footprint</span> </h1>
                </div>

                <div class="row Benefits">

                    <div class="col-lg-6">
                        <div className=" WasteDiv">
                            <div>
                                <img src={img3794722} alt="img" />

                            </div>
                            <div>
                                <p>India generates 2 lakh tonnes of waste every day, but how much are we really recycling? Did you know that only 30% of all waste is treated in environmentally friendly methods. The other 70% is causing public health concerns, landfill shortage and environmental constraints.</p>
                            </div>
                        </div>
                        <p className="w_divp">A waste audit is the qualitative and quantitative study of waste generated. It involves identifying waste streams, handling waste and disposal methods. We develop feasible solutions to segregate, minimize and optimize waste generation streams - aiming for a zero-landfill approach.</p>
                    </div>

                    <div class="col-lg-6">
                        <div class="bdr bdr-txt1">
                            <h4><strong>Benefits</strong></h4>
                            <ul class="list-icon theme-colored">

                                <li><FaChevronCircleRight className="icons" />Cost savings</li>

                                <li><FaChevronCircleRight className="icons" />New revenue sources</li>

                                <li><FaChevronCircleRight className="icons" />Improved resource efficiency</li>

                                <li><FaChevronCircleRight className="icons" />Reduction in environmental footprint </li>

                                <li><FaChevronCircleRight className="icons" />Safeguard urban and public health</li>

                                <li><FaChevronCircleRight className="icons" />Branding and publicity</li>
                            </ul>

                        </div>
                    </div>


                </div>

            </div>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Water <span>Footprint</span> </h1>
                </div>

                <div class="row Benefits">

                    <div class="col-lg-6">
                        <div className=" WasteDiv">
                            <div>
                                <img src={watertap} alt="img" />

                            </div>
                            <div>
                                <p>India21 megacities in India wont have their own ground water by 2020. Water is the most basic need for our survival. It plays a vital role in business, farming and our community.</p>
                            </div>
                        </div>
                        <p className="w_divp">A water audit is an instrument to analyze water consumption, water loss, contamination and the associated costs.</p>
                        <p className="w_divp">We study the data and determine sustainable solutions to conserve water, minimize wastage and generate savings through efficient management and monitoring.</p>
                    </div>

                    <div class="col-lg-6">
                        <div class="bdr bdr-txt1">
                            <h4><strong>Benefits</strong></h4>
                            <ul class="list-icon theme-colored">

                                <li><FaChevronCircleRight className="icons" />Reduce water bill - cost savings</li>

                                <li><FaChevronCircleRight className="icons" />Conserve fresh water resources</li>

                                <li><FaChevronCircleRight className="icons" />Reduce load on municipal treatment plants</li>

                                <li><FaChevronCircleRight className="icons" />Reduction in environmental footprint </li>

                                <li><FaChevronCircleRight className="icons" />Improve knowledge of water distribution, consumption and loss.</li>

                                <li><FaChevronCircleRight className="icons" />Secure fresh water for future generations</li>
                            </ul>

                        </div>
                    </div>
                </div>

            </div>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Energy <span>Footprint</span> </h1>
                </div>

                <div class="row Benefits">

                    <div class="col-lg-6">
                        <div className=" WasteDiv">
                            <div>
                                <img src={thunder} alt="img" />

                            </div>
                            <div>
                                <p>72% of India’s electricity is currently being produced by coal fired plants. Dependence on non-renewable resources is the primary cause of climate change. Technological advancement depends on energy use, but at the cost of our health and the planets health.</p>
                            </div>
                        </div>
                        <p className="w_divp">An energy audit is the systematic management of energy flow of an office or operations to conserve energy input without negatively arraying output.</p>
                        <p className="w_divp">We inspect your premise to devise cost-effective strategies which increase its performance, prolongs life and minimizes your overall expenses.</p>
                    </div>

                    <div class="col-lg-6">
                        <div class="bdr bdr-txt1">
                            <h4><strong>Benefits</strong></h4>
                            <ul class="list-icon theme-colored">

                                <li><FaChevronCircleRight className="icons" />Reduce energy bill and operating costs</li>

                                <li><FaChevronCircleRight className="icons" />ConservReduction in production cost - improved competitivenesseresources</li>

                                <li><FaChevronCircleRight className="icons" />Boost property value</li>

                                <li><FaChevronCircleRight className="icons" />Increased energy security </li>

                                <li><FaChevronCircleRight className="icons" />Reduce emissions and pollution - lessen environmental impact.</li>

                                <li><FaChevronCircleRight className="icons" />Discover unaccounted usage and loss in system</li>
                            </ul>

                        </div>
                    </div>
                </div>

            </div>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Carbon <span>Footprint</span> </h1>
                </div>

                <div class="row Benefits">

                    <div class="col-lg-6">
                        <div className=" WasteDiv">
                            <div>
                                <img src={img5264070} alt="img" />

                            </div>
                            <div>
                                <p>India is the 3rd largest emitter of Green House Gas (GHG) emissions. GHG emissions is the most accountable element in disrupting our sustainable endeavor, which is directly linked to our water, energy and waste management.</p>
                            </div>
                        </div>
                        <p className="w_divp">The carbon footprint is the measurement of GHG emissions produced directly or indirectly by any activity or organization.</p>
                        <p className="w_divp">We measure and manage the bio-capacity required to offset untreated carbon and negate carbon buildup in the atmosphere, and reduce your overall carbon footprint.</p>
                    </div>

                    <div class="col-lg-6">
                        <div class="bdr bdr-txt1">
                            <h4><strong>Benefits</strong></h4>
                            <ul class="list-icon theme-colored">

                                <li><FaChevronCircleRight className="icons" />Financial savings</li>

                                <li><FaChevronCircleRight className="icons" />Reduction in environmental footprint</li>

                                <li><FaChevronCircleRight className="icons" />Comply with national and international norms and regulations</li>

                                <li><FaChevronCircleRight className="icons" />Improve brand credibility and attract new and retain stakeholders </li>

                                <li><FaChevronCircleRight className="icons" />Improve long-term success and profitability</li>

                                <li><FaChevronCircleRight className="icons" />Conserve the environment</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <NGOTrustFooter />

        </>
    )
}

export default SustainableSolutions
import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import wrldbg from '../../assest/wrldbg.webp'
import co2 from '../../assest/co2.webp'
import '../../styles/CarbonFootprintStyle.css'

const CarbonFootprint = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={wrldbg} alt="img" />
                <h1 className="h1donate mx-5">Carbon Footprint: Understanding the Impact of Our Actions</h1>
            </Box>

            <Box className="NGOTrust_blog row">
                <div className="ngo_imggdiv col-lg-6 col-12">
                    <img src={co2} alt="img" />
                </div>

                <div className="NGO_textdiv col-lg-6 col-12">
                    <h6>Carbon Footprint: Understanding the Impact of Our Actions</h6>
                    <p>Carbon footprint is the total amount of greenhouse gases that are generated by our actions. It is a measure of the impact our lifestyle has on the environment. Understanding and working on reducing our carbon footprint can help mitigate the effects of global warming and climate change, leading to a better future. The major global contributors to carbon emissions are electricity and heat, transportation, manufacturing and construction, agriculture, and industrial processes. There are two types of carbon footprint: individual footprint and corporate carbon footprint.</p>
                    <p>Individual carbon footprint is our contribution to carbon emissions, which is directly linked to our day-to-day activities such as consumption of food, taking a bath, commuting to work, watching OTT content, and even something as simple as checking our email. Corporate carbon footprint is the total amount of GHG emissions that are directly or indirectly caused by a company’s activities. It includes emissions across the entire value chain, from sourcing, logistics, use of sold products, to end-of-life disposal.</p>
                    <p>When calculating the sources of emissions for corporates, there are three scopes: scope 1, 2, and 3. Scope 1 covers emissions from sources that an organization owns or controls directly. Scope 2 covers emissions that a company causes indirectly when the energy it purchases and uses is produced. Scope 3 encompasses emissions that are not produced by the company itself but are the result of activities from assets owned or controlled by them, up and down its value chain.</p>
                    <p>Direct emissions are using energy (fuel, electricity) for heating/cooling space/water, powering various gadgets and appliances, and burning fuel in personal vehicles. Indirect emissions are embedded into the activities we do, such as watching TV, where the emissions that occur during the manufacturing process, transportation, and installation of that TV in our home are indirect emissions.</p>
                    <p>Food production is responsible for one-quarter of the world’s greenhouse gas emissions. Our food has a big impact on greenhouse gases, and our food carbon footprint is bigger than we may know. Everything we eat (veggies, meat, dairy, etc.) had to be cultivated or raised and shipped to us, contributing to greenhouse emissions. Diets with higher meat intake produce higher greenhouse gas emissions and a greater environmental impact, especially in cases where the meat is from ruminant animals. The total carbon emissions of people following a vegetarian diet are on average 22% lower than those who eat meat frequently, and in the case of vegans, their greenhouse gas emissions are on average 37% lower than those who eat meat frequently.</p>
                    <p>In India, the per capita carbon footprint is about 2.4 metric tonnes of CO2, according to the UNEP Emissions Gap Report 2022. This is very low compared to the world average of 6.3mt and other countries such as the USA (14mt), Russian Federation (13mt), China (9.7mt), Brazil and Indonesia (about 7.5mt), and the European Union (7.2mt). India’s dietary guideline recommendations footprint is also small at 0.86 kg CO2 per day compared to the USA's 3.83kg CO2 per day, which is a 4.5 times increase.</p>
                    <p>In addition to the food industry, the construction industry is also a significant contributor to greenhouse gas emissions. The production of cement and other building materials produces large amounts of carbon dioxide, while the energy used in the construction and operation of buildings is also a significant source of emissions.</p>
                    <p>The tourism industry is another sector that contributes significantly to greenhouse gas emissions. The transportation of tourists to and from destinations, as well as the energy used in the operation of hotels, restaurants, and other tourist facilities, all contribute to the industry's carbon footprint.</p>
                    <p>So, what can we do to reduce our carbon footprint? One way is to make changes to our diet. Eating less meat and dairy products and opting for more plant-based options can significantly reduce our individual carbon footprint. Choosing to buy locally produced foods can also reduce the carbon footprint associated with transportation. In the construction industry, there are many ways to reduce emissions, such as using recycled materials, designing buildings for energy efficiency, and using renewable energy sources for operation.In the tourism industry, individuals can reduce their carbon footprint by choosing destinations that are closer to home, using public transportation instead of renting cars, and choosing eco-friendly accommodations.</p>
                    <p>In conclusion, understanding the impact of our actions on the environment is essential in reducing our carbon footprint. By making changes to our diet, choosing eco-friendly products and services, and reducing energy use, we can all do our part to reduce greenhouse gas emissions and protect our planet for future generations. Reducing our carbon footprint requires a collective effort. Governments and companies should implement measures to reduce carbon emissions, such as investing in renewable energy, improving public transportation, and using sustainable manufacturing processes. Individuals can also take steps to reduce their carbon footprint by adopting environmentally-friendly habits, such as reducing meat consumption, conserving energy, and using public transportation or walking/biking instead of driving alone. By reducing our carbon footprint, we can help mitigate the effects of global warming and climate change and build a sustainable future for generations to come.</p>
                </div>

            </Box>
            <NGOTrustFooter />

        </>
    )
}

export default CarbonFootprint


import people1 from '../assest/restaurantImg/people1.jpg'
import people2 from '../assest/restaurantImg/people2.jpg'
import people3 from '../assest/restaurantImg/people3.jpg'
import people4 from '../assest/restaurantImg/people4.jpg'


const PeopleData = [
    {
        id: 1,
        heading:'‘A Chef is Responsible for What He Puts on the Plate,’ says Chef Michael Swamy',
        textInfo:"In an interview with Restaurant India, Michael Swamy, Chef and Author talks about why it's important to source the ingredients locally and…",
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'‘A Chef is Responsible for What He Puts on the Plate,’ says Chef Michael Swamy',
        textInfo:"In an interview with Restaurant India, Michael Swamy, Chef and Author talks about why it's important to source the ingredients locally and…",
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'‘A Chef is Responsible for What He Puts on the Plate,’ says Chef Michael Swamy',
        textInfo:"In an interview with Restaurant India, Michael Swamy, Chef and Author talks about why it's important to source the ingredients locally and…",
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: people3,
    }, {
        id: 4,
        heading:'‘A Chef is Responsible for What He Puts on the Plate,’ says Chef Michael Swamy',
        textInfo:"In an interview with Restaurant India, Michael Swamy, Chef and Author talks about why it's important to source the ingredients locally and…",
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: people4,
    }, {
        id: 5,
        heading:'‘A Chef is Responsible for What He Puts on the Plate,’ says Chef Michael Swamy',
        textInfo:"In an interview with Restaurant India, Michael Swamy, Chef and Author talks about why it's important to source the ingredients locally and…",
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: people1,
    }, {
        id: 6,
        heading:'‘A Chef is Responsible for What He Puts on the Plate,’ says Chef Michael Swamy',
        textInfo:"In an interview with Restaurant India, Michael Swamy, Chef and Author talks about why it's important to source the ingredients locally and…",
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: people2,
    }
];
export default PeopleData
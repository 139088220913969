import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";
import Home from "./page/Home";
import RealEstateHome from "./page/RealEstate/RealEstateHome";
import HomeArea from "./page/RealEstate/HomeArea";
import NGOTrustHome from "./page/NGOTrust/NGOTrustHome";
import DonateNow from "./page/NGOTrust/DonateNow";
import ContactUs from "./page/NGOTrust/ContactUs";
import NgoAboutUs from "./page/NGOTrust/NgoAboutUs";
import OurWorkGallery from "./page/NGOTrust/OurWorkGallery";
import NgoBlog from "./page/NGOTrust/NgoBlog";
import CarbonFootprint from "./page/NGOTrust/CarbonFootprint";
import NgoGreenNews from "./page/NGOTrust/NgoGreenNews";
import CSRActivities from "./page/NGOTrust/CSRActivities";
import OurPartners from "./page/NGOTrust/OurPartners";
import PedhLagao from "./page/NGOTrust/PedhLagao";
import MiyawakiForest from "./page/NGOTrust/MiyawakiForest";
import SustainableSolutions from "./page/NGOTrust/SustainableSolutions";
import SustainableVillageAndFarmers from "./page/NGOTrust/SustainableVillageAndFarmers";
import EcosystemRejuvenation from "./page/NGOTrust/EcosystemRejuvenation";
import GreenCelebrations from "./page/NGOTrust/GreenCelebrations";
import GreenSchools from "./page/NGOTrust/GreenSchools";
import Miyawaki from "./page/NGOTrust/Miyawaki";
import WaterBodyRestoration from "./page/NGOTrust/WaterBodyRestoration";
import NatureIntractionCentre from "./page/NGOTrust/NatureIntractionCentre";
import CampusGreenProject from "./page/NGOTrust/CampusGreenProject";
import Volunteer from "./page/NGOTrust/Volunteer";
import NgoFaq from "./page/NGOTrust/NgoFaq";
import NgoPrivacyPolicy from "./page/NGOTrust/NgoPrivacyPolicy";
import NgoTermsConditions from "./page/NGOTrust/NgoTermsConditions";
import NgoRefundPolicy from "./page/NGOTrust/NgoRefundPolicy";
import RestaurantHome from "./page/Restaurant/RestaurantHome";
import BusinessSuppliers from "./page/Restaurant/BusinessSuppliers";
import Operations from "./page/Restaurant/Operations";
import Growth from "./page/Restaurant/Growth";
import Startup from "./page/Restaurant/Startup";
import People from "./page/Restaurant/People";
import DetailPage from "./components/DetailPage";
import FoodService from "./page/Restaurant/FoodService";
import ServicePeople from "./page/Restaurant/ServicePeople";
import ServiceCPG from "./page/Restaurant/ServiceCPG";
import ServiceD2CNewCommerce from "./page/Restaurant/ServiceD2CNewCommerce";
import ServiceFashionAndBeauty from "./page/Restaurant/ServiceFashionAndBeauty";
import ServiceTechnologyECommerce from "./page/Restaurant/ServiceTechnologyECommerce";
import ServiceRetailBusiness from "./page/Restaurant/ServiceRetailBusiness";
import RestaurantAboutUs from "./page/Restaurant/RestaurantAboutUs";
import RestaurantContactUs from "./page/Restaurant/RestaurantContactUs";
import RestaurantDisclaimer from "./page/Restaurant/RestaurantDisclaimer";
import RestaurantTerms from "./page/Restaurant/RestaurantTerms";
import RestaurantFeedback from "./page/Restaurant/RestaurantFeedback";
import RestaurantSubscriptionPlan from "./page/Restaurant/RestaurantSubscriptionPlan";

function App() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <BrowserRouter>
        <div className="top-to-btm">
          {showTopBtn && (
            <FaAngleUp
              className="icon-position icon-style "
              style={{ zIndex: 199 }}
              onClick={goToTop}
            />
          )}
        </div>
        <Routes>

          {/* Real Estate  */}

          <Route path='/' element={<Home />} />
          <Route path='/real_estate' element={<RealEstateHome />} />
          <Route path='/home_area' element={<HomeArea />} />

          {/* NGO Trust  */}

          <Route path='/NGO_trust' element={<NGOTrustHome />} />
          <Route path='/NGO_trust/donate_now' element={<DonateNow />} />
          <Route path='/NGO_trust/contact_us' element={<ContactUs />} />
          <Route path='/NGO_trust/about_us' element={<NgoAboutUs />} />
          <Route path='/NGO_trust/our_work_gallery' element={<OurWorkGallery />} />
          <Route path='/NGO_trust/blog' element={<NgoBlog />} />
          <Route path='/NGO_trust/blog/carbon_footprint' element={<CarbonFootprint />} />
          <Route path='/NGO_trust/green_news' element={<NgoGreenNews />} />
          <Route path='/NGO_trust/CSR_activities' element={<CSRActivities />} />
          <Route path='/NGO_trust/our_partners' element={<OurPartners />} />
          <Route path='/NGO_trust/pedh_lagao' element={<PedhLagao />} />
          <Route path='/NGO_trust/miyawaki_forest' element={<MiyawakiForest />} />
          <Route path='/NGO_trust/sustainable_solutions' element={<SustainableSolutions />} />
          <Route path='/NGO_trust/sustainable_village_and_farmers' element={<SustainableVillageAndFarmers />} />
          <Route path='/NGO_trust/ecosystem_rejuvenation' element={<EcosystemRejuvenation />} />
          <Route path='/NGO_trust/green_celebrations' element={<GreenCelebrations />} />
          <Route path='/NGO_trust/green_schools' element={<GreenSchools />} />
          <Route path='/NGO_trust/Miyawaki' element={<Miyawaki />} />
          <Route path='/NGO_trust/water_body_restoration' element={<WaterBodyRestoration />} />
          <Route path='/NGO_trust/nature_intraction_centre' element={<NatureIntractionCentre />} />
          <Route path='/NGO_trust/campus_green_project' element={<CampusGreenProject />} />
          <Route path='/NGO_trust/volunteer' element={<Volunteer />} />
          <Route path='/NGO_trust/faq' element={<NgoFaq />} />
          <Route path='/NGO_trust/privacy_policy' element={<NgoPrivacyPolicy />} />
          <Route path='/NGO_trust/terms_and_conditions' element={<NgoTermsConditions />} />
          <Route path='/NGO_trust/refund_policy' element={<NgoRefundPolicy />} />

          {/* restaurant  */}

          <Route path='/restaurant' element={<RestaurantHome />} />
          <Route path='/restaurant/business_suppliers' element={<BusinessSuppliers />} />
          <Route path='/restaurant/operations' element={<Operations />} />
          <Route path='/restaurant/growth' element={<Growth />} />
          <Route path='/restaurant/startup' element={<Startup />} />
          <Route path='/restaurant/people' element={<People />} />
          <Route path='/restaurant/people/detail' element={<DetailPage />} />
          <Route path='/restaurant/service/food_service' element={<FoodService />} />
          <Route path='/restaurant/service/people' element={<ServicePeople />} />
          <Route path='/restaurant/service/CPG' element={<ServiceCPG />} />
          <Route path='/restaurant/service/D2C_new_commerce' element={<ServiceD2CNewCommerce />} />
          <Route path='/restaurant/service/fashion_beauty' element={<ServiceFashionAndBeauty />} />
          <Route path='/restaurant/service/technology_e_commerce' element={<ServiceTechnologyECommerce />} />
          <Route path='/restaurant/service/retail_business' element={<ServiceRetailBusiness />} />

          <Route path='/restaurant/about_us' element={<RestaurantAboutUs />} />
          <Route path='/restaurant/contact_us' element={<RestaurantContactUs />} />
          <Route path='/restaurant/disclaimer' element={<RestaurantDisclaimer />} />
          <Route path='/restaurant/terms' element={<RestaurantTerms/>} />
          <Route path='/restaurant/feedback' element={<RestaurantFeedback/>} />
          <Route path='/restaurant/subscription_plan' element={<RestaurantSubscriptionPlan />} />





        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;

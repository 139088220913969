import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import wrldbg from '../../assest/wrldbg.webp'
import { FaChevronCircleRight } from "react-icons/fa";
import bannermiyawaki from '../../assest/banner-miyawaki.webp'



const Miyawaki = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={wrldbg} alt="img" />
                <h1 className="h1donate text-center">Miyawaki Forest</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv pb-0">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Miyawaki Dense <span>Forest</span> </h1>
                    <h6 className="m-4" style={{ color: '#3aa348' }}>ESTABLISHING A MIYAWAKI FOREST FOR URBAN BIODIVERSITY CONSERVATION AND GREENERY ENHANCEMENT</h6>
                </div>
                <div className="pedhlagao_div">
                    <h3>NEED OF THE <span>PROJECT:</span></h3>
                    <p className=" fs-5">The rapid urbanization and expansion of cities have led to the loss of natural habitats, a decline in biodiversity, and increased pollution levels. There is a pressing need to address these environmental challenges by creating green spaces within urban areas. The Miyawaki forest offers a sustainable and effective solution to restore biodiversity, improve air quality, and enhance the overall well-being of urban communities.</p>
                </div>
            </div>

            <div class="row gap-5 Benefits">
                <div class="col-lg-12">
                    <div class="bdr bdr-txt3">
                        <h4><strong>OBJECTIVES:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Establish a Miyawaki forest in a designated urban area, utilizing the Miyawaki method of planting local native tree species.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Enhance urban greenery by restoring and preserving native plant species and creating a self-sustaining forest ecosystem.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Promote biodiversity conservation by providing a habitat for local wildlife, including birds, insects, and small mammals.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Improve air quality and mitigate the effects of pollution by sequestering carbon dioxide and other pollutants through the dense vegetation of the Miyawaki forest.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Raise awareness and educate the local community about the importance of urban forestry and the benefits of the Miyawaki method.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Develop a replicable model for future implementation of Miyawaki forests in other urban areas.</li>
                        </ul>

                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="bdr bdr-txt">
                        <h4><strong>TARGET BENEFICIARIES:</strong></h4>
                        <p>The project will benefit various stakeholders, including:</p>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Urban residents:The Miyawaki forest will provide a natural and serene space for residents to connect with nature, relax, and engage in recreational activities.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Local Biodiversity:The forest will serve as a habitat for various Birds, Insects, Butterflies, Reptiles and other living creature and contributing to ecological balance.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Local government and municipalities: The project will assist local authorities in achieving their sustainability and environmental goals, enhancing their reputation as green cities.</li>
                        </ul>
                        
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="bdr bdr-txt1">
                        <h4><strong>EXPECTED IMPACT:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Increased urban biodiversity: The Miyawaki forest will foster the growth of a diverse range of plant species, attracting various forms of wildlife and contributing to the conservation of urban biodiversity.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Improved air quality: The dense vegetation will act as a natural air filter, absorbing pollutants and releasing oxygen, leading to cleaner and healthier urban environments.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Enhanced community well-being: Access to green spaces and proximity to nature have been linked to improved mental health, reduced stress levels, and increased community cohesion.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Educational and awareness-building opportunities: The project will raise awareness among the local community about the importance of urban forests and the role they play in mitigating environmental challenges.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Replicability and scalability: The successful establishment of a Miyawaki forest will serve as a model for other urban areas, inspiring similar initiatives and contributing to a broader movement of urban greening.</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="bdr bdr-txt1">
                        <h4><strong>BENEFITS TO FUNDER:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Positive reputation and enhanced brand image.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Employee engagement activity via volunteering.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Meaningful social and environmental impact and improved community well-being.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Opportunities for collaboration and stakeholder engagement.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Access to valuable knowledge and insights.</li>
                            
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Increased publicity and visibility.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Alignment with corporate social responsibility (CSR) objectives.</li>

                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Measurable results and evidence of impact.</li>

                        </ul>
                    </div>
                </div>


            </div>

            <div className="flowChart3">
                <img src={bannermiyawaki} alt="img" />
                <h5 className=" my-5 text-center">Green Yatra has planted 60+ Miyawaki forests in India</h5>
            </div>
            <NGOTrustFooter />

        </>
    )
}

export default Miyawaki
import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import MatataSchool from '../../assest/Matata_school.webp'
import greenschool from '../../assest/green-school.webp'
import whatwe1 from '../../assest/1-what-we.webp'
import whatwe2 from '../../assest/2-what-we.webp'
import whatwe3 from '../../assest/3-what-we.webp'
import whatwe4 from '../../assest/4-what-we.webp'
import whatwe5 from '../../assest/5-what-we.webp'

import { FaChevronCircleRight } from "react-icons/fa";
import '../../styles/GreenSchoolStyle.css'


const GreenSchools = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={MatataSchool} alt="img" />
                <h1 className="h1donate text-center">Green Schools</h1>
            </Box>
            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Green <span>Schools</span> </h1>
                </div>
                <div className="activitiesDiv">
                    <p className=" fs-4"> Our children are the future, and it is vital that they are educated about the environment and the ongoing concerns related to it. Through creating green practices and raising awareness about environmental issues, children will learn about where our food is coming from, how our air is becoming polluted and what needs to be done to save our planet. </p>
                </div>

            </div>


            <div className="DonateNow">
                <div className="mx-4 mt-5">
                    <h4 className="ps-2 fw-bold pb-2">Green School Project </h4>
                </div>

                <div class="row Benefits">

                    <div class="col-lg-6">
                        <div className=" WasteDiv">
                            <div>
                                <img src={greenschool} alt="img" />

                            </div>
                            <div>
                                <p>The Green Schools Programme’s objective is to inspire students to move beyond theories and textbooks and concentrate solely on 'doing' It is an environmental extra curriculum programme directed to subtly sensitizing students toward fellow inhabitants of our planet and to the environment through hands-on and thought provoking activities.</p>
                            </div>
                        </div>
                        <p className="w_divp">The Sustainable Green School Model is about a school with green backyard orchards, agroforestry, vegetable and kitchen gardens, a herbal garden, a school that’s clean and green, a school which promotes environmental conversations, protection and green extra curriculum projects and activities. A green school may also create it’s own alternate green energy (i.e. solar energy).</p>
                    </div>

                    <div class="col-lg-6">
                        <div class="bdr bdr-txt1">
                            <h4><strong>Initiatives</strong></h4>
                            <ul class="list-icon theme-colored">

                                <li><FaChevronCircleRight className="icons" />Creating a Green Committee</li>

                                <li><FaChevronCircleRight className="icons" />Installing rainwater harvesting systems</li>

                                <li><FaChevronCircleRight className="icons" />Best out of waste workshop</li>

                                <li><FaChevronCircleRight className="icons" />Birds house & Birdfeeder making workshop </li>

                                <li><FaChevronCircleRight className="icons" />Kitchen garden installation</li>

                                <li><FaChevronCircleRight className="icons" />Clean up drive</li>

                                <li><FaChevronCircleRight className="icons" />Organize discussion, debates and competitions</li>

                                <li><FaChevronCircleRight className="icons" />Many other initiatives</li>
                            </ul>
                        </div>
                    </div>


                </div>

            </div>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Go Green <span>Kids</span> </h1>
                </div>
                <div className="activitiesDiv">
                    <h3 className="ms-3 fw-semibold">An innovative effort to save Mother Nature</h3>
                    <p className=" fs-6">Go GREEN Kids is a unique, innovative initiative to sensitize and educate school children towards environmental protection and improvement. We believe that the younger generation is our future and has the potential to become change makers. It is this belief that fuels our campaign, and we have opted a unique retrograde approach to save mother earth, where children will teach and inspire their parents, elders, teachers and friends by adopting a green 3R (Reduce, Reuse, Recycle) lifestyle. By learning how to protect, save and improve the environment, they will inspire those around them to reduce pollution and contribute towards a greener life and society. </p>
                </div>

                <div className="activitiesDiv">
                    <h3 className="ms-3 fw-semibold">What we do</h3>
                    <p className=" fs-6">We organize different kinds of interactive events and activities in schools to preserve Mother Nature and humanity, such as tree plantations, clean up drives, spot fixes, site visits, environmental documentary shows, old stuff collection drives ( including notebooks, clothes, shoes etc.), audio visual presentations and workshops (e.g. change you can bring, Green birthday celebration, how to go green in daily life, best out of waste, clay Ganesha workshops). </p>
                </div>

                <div className="whatimg">
                    <ul>
                        <li><img src={whatwe1} alt="img" /></li>
                        <li><img src={whatwe2} alt="img" /></li>
                        <li><img src={whatwe3} alt="img" /></li>
                        <li><img src={whatwe4} alt="img" /></li>
                        <li><img src={whatwe5} alt="img" /></li>
                    </ul>
                </div>


                <div className="activitiesDiv">
                    <h3 className="ms-3 fw-semibold">Impact</h3>
                    <p className=" fs-6">We have organized thousands of presentations, workshops and activities reaching out to more than 50 lakh students across India through associations and partnerships with thousands of schools. </p>
                </div>

                <div className="activitiesDiv">
                    <h3 className="ms-3 fw-semibold">Partner With Us</h3>
                    <p className=" fs-6">Schools from across India can be part of Go GREEN Kids project. As a partner, your school will gain many valuable benefits. If you want to organize Go GREEN Kids activities mentioned above in your school, please write or call us for further details. </p>
                </div>

            </div>
            <NGOTrustFooter />

        </>
    )
}

export default GreenSchools
import './RestaurantFooterStyle.css'
import Logo from "../../../assest/marxWeddinglogo.webp";
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

const RestaurantFooter = () => {
    const defalutValue = {
        EmailAddress: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        EmailAddress: yup.string().required('Email is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }
    return (
        <>
            <Box className='Get_daily_news'>
                <div className="maindiv">
                    <div className="newshead ">
                        <h1 className="ps-2">Stay on top – Get the daily news from Indian Retailer in your inbox</h1>
                    </div>

                    <div className=" justify-content-end">
                        <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid">
                            {({ errors, touched, resetForm }) => {
                                return <Form className="">

                                    <div className=" input-group">
                                        <div className="inputdivfiled">
                                            <Field type='text' name='EmailAddress' placeholder='Email Address'
                                                className={
                                                    `form-control rounded-end-0 w-100
                                                    ${errors.EmailAddress && touched.EmailAddress ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'white', fontSize: '12px', marginBottom: 0 }}> <ErrorMessage name="EmailAddress" /></p>
                                        </div>

                                        <div className="">
                                            <button type="submit" className="btn btn-success bg-opacity-75 rounded-start-0">
                                                Subscribe now
                                            </button>
                                        </div>
                                    </div>

                                </Form>
                            }}
                        </Formik>
                    </div>

                </div>
            </Box>
            <footer className="newfooter">
                <div className="container">
                    <div className="footer_links">
                        <div className="row">
                            <div className="col-md-3">
                                <img src={Logo} alt="RestaurantIndia" className="footerlogo" />
                                <div className="footer-sub-head">Moving Indian Tech Forward</div>
                                <a href="/" target="_blank" className="redlnk">Become A Member</a>
                                <div className="footerlnk-head">Follow Us</div>
                                <ul className="soc_ul_footer">
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/fb.png" alt="Facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/in.png" alt="Instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/li.png" alt="LinkedIn" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/tw.png" alt="Twitter" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <div className="footerlnk-head">Discover</div>
                                <ul className="menu">
                                    <li><Link to="/restaurant/startup">New Launch</Link></li>
                                    <li><Link to="/restaurant/growth">Growth</Link></li>
                                    <li><Link to="/restaurant/operations">Operations</Link></li>
                                    <li><Link to="/restaurant/business_suppliers">Business Suppliers</Link></li>
                                    <li><Link to="/restaurant/service/food_service">Food</Link></li>
                                    <li><Link to="/restaurant/people">Restaurant People</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-2">
                                <div className="footerlnk-head">More</div>
                                <ul className="menu">
                                    <li><a href="/restaurant/about_us">About Us</a></li>
                                    <li><a href="/restaurant/contact_us">Contact</a></li>
                                    <li><a href="/restaurant/disclaimer">Disclaimer</a></li>
                                    <li><a href="/restaurant/terms">Terms</a></li>
                                    {/* <li><a href="/restaurant/subscribe">Subscribe</a></li> */}
                                    <li><a href="/restaurant/feedback">Feedback</a></li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <div className="mag">
                                    <div className="mag-head">Get The Magazine</div>
                                    <ul className="mag-inner">
                                        <li>
                                            <img src="https://master.franchiseindia.com/magazine-subscribe/images/retailer-470x635.jpg" alt="Magazine" className="img-fluid" />
                                        </li>
                                        <li>
                                            <div className="mag1">Subscribe to World's No. 1 Consumer Insight magazine</div>
                                            <div className="listlink">
                                                <a href="/" className="subscribe" target="_blank">Subscribe Now</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="affliates">
                        <div className="footerlnk-head">AFFILIATED SITES</div>
                        <ul className="affliates_lnk">
                            <li>
                                <a href="/" target="_blank">
                                    <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/entrepreneur.png" alt="Entrepreneur" />
                                </a>
                            </li>
                            <li>
                                <a href="/" target="_blank">
                                    <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/franchiseindia.png" alt="Franchiseindia" />
                                </a>
                            </li>
                            <li>
                                <a href="/" target="_blank">
                                    <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/oi.png" alt="Opportunity India" />
                                </a>
                            </li>
                            <li>
                                <a href="/" target="_blank">
                                    <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/licenseindia.png" alt="LicenseIndia" />
                                </a>
                            </li>
                            <li>
                                <a href="/" target="_blank">
                                    <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/businessex.png" alt="Businessex" />
                                </a>
                            </li>
                            <li>
                                <a href="/" target="_blank">
                                    <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/franglobal.png" alt="Franglobal" />
                                </a>
                            </li>
                            <li>
                                <a href="/" target="_blank">
                                    <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/dealerindia.png" alt="Dealerindia" />
                                </a>
                            </li>
                            <li>
                                <a href="/" target="_blank">
                                    <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/franchiseindiabangladesh.png" alt="FranchiseIndiaBangladesh" />
                                </a>
                            </li>
                            <li>
                                <a href="/" target="_blank">
                                    <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/finet.png" alt="franchiseindia.net" />
                                </a>
                            </li>
                            <li>
                                <a href="/" target="_blank">
                                    <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/francorp.png" alt="Francorp" />
                                </a>
                            </li>
                            <li>
                                <a href="/package-lock.json" target="_blank">
                                    <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/gauravmarya.png" alt="Gaurav Marya" />
                                </a>
                            </li>
                            <li>
                                <a href="/" target="_blank">
                                    <img width={109} height={61} src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/msme.png" alt="MSME" />
                                </a>
                            </li>
                            <li>
                                <a href="/" target="_blank">
                                    <img width={109} height={61} src="https://www.indianretailer.com/restaurant/themes/menshealth/images/affliates/w3.png" alt="W3summit" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
            <div className="copyright">
                <div className="copy">
                    <p>Copyright © 2023 -  2024 Abarish Restaurant India.</p>
                </div>
            </div>


        </>
    )
}

export default RestaurantFooter
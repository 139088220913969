import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SecondSliderStyle.css';


const SecondSlider = ({ title, detailsData }) => {
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '1px',
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '1px',
        },
      },
      {
        breakpoint: 9999, // A large value to ensure the last breakpoint always applies
        settings: {
          slidesToShow: 2,
        },
      }
    ],
  };

  return (
    <>
      <div className="SecondSlider_Restaurant">
        <h1 className='fw-bold ms-3'>{title}</h1>
        <Slider {...settings}>
          {detailsData?.map((item, index) => (
            <div key={item.id} className="Res_Second_Slider">
              <img src={item.imgSrc} alt={`img-${item.id}`} />
              <h4>{item.heading}</h4>
              <p>{item.textInfo}</p>
              <div className='info'>
                <p>{item.byName}</p>
                <p>{item.dateTime}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  )
}

export default SecondSlider

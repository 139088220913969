import { Link } from "react-router-dom"
import RestaurantHeader from "../../common/Restaurant/RestaurantHeader/RestaurantHeader"
import SecondSlider from "../../common/Restaurant/SecSlider/SecondSlider"
import TopSlider from "../../common/Restaurant/TopSlider/TopSlider"
import RestaurantNewsData from "../../data/RestaurantNewsData"
import '../../styles/RestaurantHomeStyle.css'
import img6874997295881585616 from '../../assest/restaurantImg/6874997295881585616.gif'
import img5853073383977373140 from '../../assest/restaurantImg/5853073383977373140.gif'
import { Box, Button } from "@mui/material"
import { FaArrowRightLong } from "react-icons/fa6";
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RestaurantFooter from "../../common/Restaurant/RestaurantFooter/RestaurantFooter"
import RestaurantPeopleData from "../../data/RestaurantPeopleData"
import theWorkzon1 from '../../assest/restaurantImg/theWorkzon1.jpg'
import theWorkzon2 from '../../assest/restaurantImg/theWorkzon2.jpg'
import theWorkzon3 from '../../assest/restaurantImg/theWorkzon3.jpg'
import theWorkzon4 from '../../assest/restaurantImg/theWorkzon4.jpg'


const RestaurantHome = () => {
    const defalutValue = {
        EmailAddress: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        EmailAddress: yup.string().required('Email is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }


    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 2,
                },
            }
        ],
    };
    return (
        <>
            <RestaurantHeader />

            <div className=" container">

                <div className=" row">
                    <div className=" col-md-8 col-12">
                        <TopSlider detailsData={RestaurantNewsData} />

                        <SecondSlider detailsData={RestaurantPeopleData} title={'People'} />
                    </div>

                    <div className=" col-md-4 col-12">
                        <div className="EventSessions">
                            <h4>Event & Sessions</h4>
                            <p>Explore Upcoming Events And New Age Agendas</p>
                            <Link>VIEW EVENT CALENDAR</Link>
                        </div>
                        <div className="imgdivv">
                            <img src={img6874997295881585616} alt="img" />
                        </div>

                        <div className="KnowService">
                            <h6>Know about Food Service</h6>
                            <div>
                                <p><Link>Food and Beverages</Link></p>
                                <h5><Link>Food Safety, Food Security at High Risk: Why its Time Food Companies/Restaurants should look at it</Link></h5>
                            </div>

                            <div>
                                <p><Link>Food and Beverages</Link></p>
                                <h5><Link>Navigating India’s Evolving Alcohol Landscape</Link></h5>
                            </div>
                            <div>
                                <p><Link>Growth</Link></p>
                                <h5><Link>4 Key Reasons to Drive Growth in Restaurant Biz</Link></h5>
                            </div>
                            <div>
                                <p><Link>People</Link></p>
                                <h5><Link>‘A Chef is Responsible for What He Puts on the Plate,’ says Chef Michael Swamy</Link></h5>
                            </div>

                            <div>
                                <p><Link>Operations</Link></p>
                                <h5><Link>How Restaurants Can Aim for Cost-Effective Sustainable Food Packaging</Link></h5>
                            </div>

                        </div>

                        <div className=" butnsdiv">
                            <Link to={'/restaurant/startup'}><Button>New Launch </Button></Link>
                            <Link to={'/restaurant/growth'}><Button>Growth </Button></Link>
                            <Link to={'/restaurant/operations'}><Button>Operations </Button></Link>
                            <Link to={'/restaurant/business_suppliers'}><Button>Business Suppliers </Button></Link>
                        </div>
                    </div>

                </div>

                <div className="advertise_div">
                    <img src={img5853073383977373140} alt="img" />
                </div>

                <div className=" row">
                    <div className="newItemsAdd col-lg-6 col-12">
                        <h5><Link>New Launch <FaArrowRightLong className=" text-danger fs-3 ms-1" /> </Link></h5>
                        <div className="dflex_Div">
                            <div>
                                <img src={theWorkzon1} alt="img" />

                            </div>
                            <div className="content_div">
                                <h6><Link> CYK Hospitalities Brings Authentic Asian Flavors to Agra with Launch of Wok Bar</Link></h6>
                                <h6><Link> J's Oriental launches an upscale oriental restaurant for Asian Food Lovers</Link></h6>
                                <h6><Link> BCS Globals launches WOX, a new player in the Indian Energy Drink market</Link></h6>
                            </div>

                        </div>

                    </div>

                    <div className="newItemsAdd col-lg-6 col-12">
                        <h5><Link>Growth <FaArrowRightLong className=" text-danger fs-3 ms-1" /> </Link></h5>
                        <div className="dflex_Div">
                            <div>
                                <img src={theWorkzon2} alt="img" />

                            </div>
                            <div className="content_div">
                                <h6><Link> 4 Key Reasons to Drive Growth in Restaurant Biz</Link></h6>
                                <h6><Link> Building a Coffee Business: 10 Key Strategies for Success in a Competitive Market</Link></h6>
                                <h6><Link> Rethinking Profitability in Hotel F&B Operations</Link></h6>
                            </div>




                        </div>

                    </div>

                    <div className="newItemsAdd col-lg-6 col-12">
                        <h5><Link>Operations  <FaArrowRightLong className=" text-danger fs-3 ms-1" /> </Link></h5>
                        <div className="dflex_Div">
                            <div>
                                <img src={theWorkzon3} alt="img" />

                            </div>
                            <div className="content_div">
                                <h6><Link> How Restaurants Can Aim for Cost-Effective Sustainable Food Packaging</Link></h6>
                                <h6><Link> The Complex Landscape of Online Reservation Systems in India</Link></h6>
                                <h6><Link> How Top Breweries in Bangalore is Combating the Water Crisis</Link></h6>
                            </div>
                        </div>

                    </div>

                    <div className="newItemsAdd col-lg-6 col-12">
                        <h5> <Link>Business Suppliers <FaArrowRightLong className=" text-danger fs-3 ms-1" /></Link> </h5>
                        <div className="dflex_Div">
                            <div>
                                <img src={theWorkzon4} alt="img" />

                            </div>
                            <div className="content_div">
                                <h6><Link> Nestle Partners with Restaurants to introduce plant based Range</Link></h6>
                                <h6><Link> Epigamia Unveils Ready to Eat Puddings & Milkshakes</Link></h6>
                                <h6><Link> FSIPL Organizing F&B Roadshows to Boost HORECA Biz during Cricket World Cup 2023</Link></h6>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Box className='Get_daily_news'>
                <div className="maindiv">
                    <div className="newshead ">
                        <h1 className="ps-2">Stay on top – Get the daily news from Indian Retailer in your inbox</h1>
                    </div>

                    <div className=" justify-content-end">
                        <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid">
                            {({ errors, touched, resetForm }) => {
                                return <Form className="">

                                    <div className=" input-group">
                                        <div className="inputdivfiled">
                                            <Field type='text' name='EmailAddress' placeholder='Email Address'
                                                className={
                                                    `form-control rounded-end-0 w-100
                                                    ${errors.EmailAddress && touched.EmailAddress ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'white', fontSize: '12px', marginBottom: 0 }}> <ErrorMessage name="EmailAddress" /></p>
                                        </div>

                                        <div className="">
                                            <button type="submit" className="btn btn-success bg-opacity-75 rounded-start-0">
                                                Subscribe now
                                            </button>
                                        </div>
                                    </div>

                                </Form>
                            }}
                        </Formik>
                    </div>

                </div>
            </Box>

            <div className="LatestVideodiv">
                <div className="andviewmore">
                    <h5>Latest Video</h5>
                    <Button>View More</Button>

                </div>
                <div className="SecondSlider_Restaurant">
                    <Slider {...settings}>
                        <div className="Res_Second_Slider">
                            <iframe
                                width="100%"
                                height="345"
                                src="https://www.youtube.com/embed/7QCph_cOBes?si=1PBtIX-NGhJSFbog"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen>
                            </iframe>

                        </div>

                        <div className="Res_Second_Slider">
                            <iframe
                                width="100%"
                                height="345"
                                src="https://www.youtube.com/embed/7QCph_cOBes?si=1PBtIX-NGhJSFbog"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen>
                            </iframe>

                        </div>

                        <div className="Res_Second_Slider">
                            <iframe
                                width="100%"
                                height="345"
                                src="https://www.youtube.com/embed/7QCph_cOBes?si=1PBtIX-NGhJSFbog"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen>
                            </iframe>

                        </div>

                        <div className="Res_Second_Slider">
                            <iframe
                                width="100%"
                                height="345"
                                src="https://www.youtube.com/embed/7QCph_cOBes?si=1PBtIX-NGhJSFbog"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen>
                            </iframe>

                        </div>

                        <div className="Res_Second_Slider">
                            <iframe
                                width="100%"
                                height="345"
                                src="https://www.youtube.com/embed/7QCph_cOBes?si=1PBtIX-NGhJSFbog"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen>
                            </iframe>

                        </div>

                        <div className="Res_Second_Slider">
                            <iframe
                                width="100%"
                                height="345"
                                src="https://www.youtube.com/embed/7QCph_cOBes?si=1PBtIX-NGhJSFbog"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen>
                            </iframe>

                        </div>

                        <div className="Res_Second_Slider">
                            <iframe
                                width="100%"
                                height="345"
                                src="https://www.youtube.com/embed/7QCph_cOBes?si=1PBtIX-NGhJSFbog"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen>
                            </iframe>

                        </div>

                        <div className="Res_Second_Slider">
                            <iframe
                                width="100%"
                                height="345"
                                src="https://www.youtube.com/embed/7QCph_cOBes?si=1PBtIX-NGhJSFbog"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen>
                            </iframe>

                        </div>
                    </Slider>
                </div>
            </div>

            <div className="advertise_div2">
                <img src={img5853073383977373140} alt="img" />
            </div>

            <RestaurantFooter />
        </>
    )
}

export default RestaurantHome
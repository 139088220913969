import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";


import 'react-pro-sidebar/dist/css/styles.css';
import "./sideBar.css"

import { MdEventNote } from "react-icons/md";
import { Link } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { GoGift } from "react-icons/go";
import { TfiUser } from "react-icons/tfi";
import { BiMessageSquareDetail } from "react-icons/bi";
import { SiBento } from "react-icons/si";
import { GiSkills } from "react-icons/gi";
import { CgWebsite } from "react-icons/cg";

const SideBar = () => {
  

  return (
    <>
      <div id="main-div" data-aos="fade-right"  data-aos-duration="500" data-aos-easing="ease-in-sine">
        <ProSidebar
          style={{ height: "100%", position: "absolute" }}
        >
          <div className="sidebar-body-div">
            <SidebarContent className="main-div">
              <Menu className="sidebar-content-div"
                iconShape="circle"
              >
                
                {/* <p className="Menus-p">MENUS</p> */}

                <MenuItem icon={<GoHome />}> Home <Link to={'/'} /></MenuItem>

                <MenuItem icon={<TfiUser />}>About<Link to={'about'} /></MenuItem>

                <MenuItem icon={<GiSkills />}>Selling<Link to={'/selling'} /></MenuItem>

                <MenuItem icon={<SiBento />}>Buying <Link to={'/buying'} /></MenuItem>

                {/* <SubMenu title="Check Out" icon={<GoGift />}>
                  <MenuItem icon={<MdEventNote />}>Github<Link to="/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>LinkedIn <Link to="/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>TwitterX <Link to="/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Youtube <Link to="/" /></MenuItem>
                </SubMenu> */}

                <MenuItem icon={<CgWebsite />}>Dealing Sectors<Link to={'/dealing_sectors'} /></MenuItem>
                <MenuItem icon={<CgWebsite />}>Why choose us<Link to={'/why_choose_us'} /></MenuItem>
                <MenuItem icon={<CgWebsite />}>Testimonials<Link to={'/testimonials'} /></MenuItem>
                <MenuItem icon={<BiMessageSquareDetail />}>Contact<Link to={'/contact'} /></MenuItem>

                {/* <MenuItem icon={<SlLock />}>Logout <Link to={'/'} /></MenuItem> */}

              </Menu>
            </SidebarContent>
          </div>
        </ProSidebar>
      </div>
    </>
  );
};

export default SideBar;



import RestaurantFooter from "../common/Restaurant/RestaurantFooter/RestaurantFooter"
import RestaurantHeader from "../common/Restaurant/RestaurantHeader/RestaurantHeader"
import Covidrestaurant from '../assest/restaurantImg/Covidrestaurant.jpg'
import '../styles/DetailPageStyle.css'
import { Link } from "react-router-dom"
import { Button } from "@mui/material"
import BeFunky from '../assest/restaurantImg/BeFunky1.jpg'
import TheKindRoastery from '../assest/restaurantImg/The Kind Roastery.jpg'
import FoodTrendNew from '../assest/restaurantImg/Food Trend New.jpg'
import Zomato_0 from '../assest/restaurantImg/Zomato_0.jpg'


const DetailPage = () => {
    return (
        <>
            <RestaurantHeader />

            <div className="BusinessSuppliers_head">
                <h4>‘A Chef is Responsible for What He Puts on the Plate,’ says Chef Michael Swamy</h4>
            </div>

            <div className=" container">

                <div className="People">
                    <p>People</p>
                    <h1>‘A Chef is Responsible for What He Puts on the Plate,’ says Chef Michael Swamy</h1>
                </div>

                <div className="profiletag">
                    <div>
                        <img src={Covidrestaurant} alt="img" />
                    </div>
                    <div>
                        <p><span>BY -</span> <Link>Vijetha Iyer</Link></p>
                        <p>Content Editor</p>
                        <p>Jun 24, 2024 / 4 MIN READ</p>
                    </div>
                </div>

                <div className="DetailPage_div">
                    <img src={Covidrestaurant} alt="img" />
                    <p>A renowned chef, writer, and wildlife enthusiast, Michael Swamy, known for his thorough exploration of various culinary traditions, has been called "the historian chef" for his expertise. His passion for the natural world and its creature is also evident in his approach to cooking that emphasizes the use of locally available ingredients that can be prepared over an open fire in the heart of a forest.</p>
                    <p>After completing his education at the renowned Le Cordon Bleu in London, he has earned the title of a Gourmand Award-winning writer. Chef Michael is dedicated to elevating food and culinary traditions beyond the traditional dining setting. His culinary adventures have taken him to the peaks and depths of the Indian wilderness, where he has explored various cooking styles, from bush cooking to local cuisines, each with its unique presentation. Chef Michael Swamy shares his insights on major food trends, upcoming food workshops and his new restaurant. Excerpts from the interview:</p>
                    <h6>What are the major food trends seen in the Indian market?</h6>
                    <p>People are more into South American food and local food these days. For me, I am taking the adivasi cuisine for the foreigner in the jungles. I am serving the local adivasi food to them in the five-star dimension.</p>
                    <h6>Do you source the ingredients locally? </h6>
                    <p>Most of my ingredients are sourced locally as I believe in sustainability. As it’s high time we should think about sustainability for the environment, for ourselves and for the future. And, a chef is responsible for what he puts on the plate.</p>
                    <h6>What is your signature dish and speciality cuisine?</h6>
                    <p>Pasta based dishes and Empanada are my signature dishes. French and South American is my specialty, but I am also exploring more on local Indian food for the past decade now.</p>
                    <h6>How much do you believe in seasonality and local sourcing?</h6>
                    <p>I believe more in seasonality and local sourcing as one can play with the local ingredients and give them an international touch. I source from the local markets mainly.</p>
                    <h6>Can we see you opening a restaurant very soon?</h6>
                    <p>I have been associated with five restaurants already wherein I was the partner and now I am not working with them. But, very soon, I will be a partner again in a restaurant at Houston with a client. It is still in the planning stage. But, we are planning to launch it by end of this year.</p>

                    <div className="Supplier_btndiv my-4 mx-0">
                        <div>
                            <Button className=" fw-bold">Chef</Button>
                            <Button className=" fw-bold">Local Food</Button>
                            <Button className=" fw-bold">Hyper Local Sourcing</Button>
                        </div>
                    </div>
                </div>


                <div className="">
                    <h1 className=" fw-bold">Also Worth Reading</h1>
                    <div className=" row">
                        <div className="newItemsAdd col-lg-6 col-12">
                            <div className="dflex_Div">
                                <div>
                                    <img src={BeFunky} alt="img" />

                                </div>
                                <div className="content_div">
                                    <h5 className="mt-0"><Link>Karigari by Chef Harpal Singh Sokhi Opens in Indore</Link></h5>
                                    <p className="mb-1"> New outlet</p>
                                    <p> Featuring a menu that combines innovative international cuisines with authentic Indian tastes, the restaurant caters to the diverse palates of Indore…</p>
                                </div>
                            </div>

                        </div>

                        <div className="newItemsAdd col-lg-6 col-12">
                            <div className="dflex_Div">
                                <div>
                                    <img src={TheKindRoastery} alt="img" />

                                </div>
                                <div className="content_div">
                                    <h5 className="mt-0"><Link>6 Dining Trends to Watch out This Season</Link></h5>
                                    <p className="mb-1"> Food Trend</p>
                                    <p>Every Indian state takes great pride in showcasing its own culinary legacy, which is distinguished by a particular combination of spices, cooking…</p>
                                </div>
                            </div>

                        </div>

                        <div className="newItemsAdd col-lg-6 col-12">
                            <div className="dflex_Div">
                                <div>
                                    <img src={FoodTrendNew} alt="img" />

                                </div>
                                <div className="content_div">
                                    <h5 className="mt-0"><Link> The Kind Roastery & Brewroom Opens 2nd Outlet in Bengaluru</Link></h5>
                                    <p className="mb-1"> Invest in cafes</p>
                                    <p> The Kind's future is brimming with exciting possibilities. They are launching a new retail line featuring fun merchandise and home brewing tools…</p>
                                </div>
                            </div>
                        </div>

                        <div className="newItemsAdd col-lg-6 col-12">
                            <div className="dflex_Div">
                                <div>
                                    <img src={Zomato_0} alt="img" />

                                </div>
                                <div className="content_div">
                                    <h5 className="mt-0"><Link> Zomato Receives Approval from Shareholders for a new $458 million ESOP Scheme</Link></h5>
                                    <p className="mb-1"> Zomato</p>
                                    <p> Zomato was sent a GST demand notice for Rs 9.5 crore earlier this week. The Gurugram-based company has now received two GST notices in as many months…</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>

            <RestaurantFooter />

        </>
    )
}

export default DetailPage
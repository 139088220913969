import people1 from '../assest/restaurantImg/people1.jpg'
import people2 from '../assest/restaurantImg/people2.jpg'
import people3 from '../assest/restaurantImg/people3.jpg'
import people4 from '../assest/restaurantImg/people4.jpg'


const RestaurantPeopleData = [
    {
        id: 1,
        heading:'Mumbai Gets a New Culinary Destination ‘Novaara’',
        textInfo:'Mumbai has got an all-new innovative dining destination offering an unparalleled trifecta of experiences. Nestled in the vibrant area of Malad, Novaara with the motto of Bites, Brews & beyond is set to redefine the way we dine, drink, and dance, all under one roof.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Mumbai Gets a New Culinary Destination ‘Novaara’',
        textInfo:'Mumbai has got an all-new innovative dining destination offering an unparalleled trifecta of experiences. Nestled in the vibrant area of Malad, Novaara with the motto of Bites, Brews & beyond is set to redefine the way we dine, drink, and dance, all under one roof.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Mumbai Gets a New Culinary Destination ‘Novaara’',
        textInfo:'Mumbai has got an all-new innovative dining destination offering an unparalleled trifecta of experiences. Nestled in the vibrant area of Malad, Novaara with the motto of Bites, Brews & beyond is set to redefine the way we dine, drink, and dance, all under one roof.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: people3,
    }, {
        id: 4,
        heading:'Mumbai Gets a New Culinary Destination ‘Novaara’',
        textInfo:'Mumbai has got an all-new innovative dining destination offering an unparalleled trifecta of experiences. Nestled in the vibrant area of Malad, Novaara with the motto of Bites, Brews & beyond is set to redefine the way we dine, drink, and dance, all under one roof.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: people4,
    }, {
        id: 5,
        heading:'Mumbai Gets a New Culinary Destination ‘Novaara’',
        textInfo:'Mumbai has got an all-new innovative dining destination offering an unparalleled trifecta of experiences. Nestled in the vibrant area of Malad, Novaara with the motto of Bites, Brews & beyond is set to redefine the way we dine, drink, and dance, all under one roof.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: people1,
    }, {
        id: 6,
        heading:'Mumbai Gets a New Culinary Destination ‘Novaara’',
        textInfo:'Mumbai has got an all-new innovative dining destination offering an unparalleled trifecta of experiences. Nestled in the vibrant area of Malad, Novaara with the motto of Bites, Brews & beyond is set to redefine the way we dine, drink, and dance, all under one roof.',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: people2,
    }
];
export default RestaurantPeopleData
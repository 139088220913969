import people1 from '../assest/restaurantImg/people1.jpg'
import people2 from '../assest/restaurantImg/people4.jpg'
import people3 from '../assest/restaurantImg/people3.jpg'
import people4 from '../assest/restaurantImg/people4.jpg'


const RestaurantAboutUsData = [
    {
        id: 1,
        heading:'Knowledge',
        textInfo:"Backed by global experts and professionals",
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Opportunity',
        textInfo:"Creates common platform for opportunity through focussed media",
        imgSrc: people2,
    },
    
    {
        id: 3,
        heading:'Network',
        textInfo:"Assists local investors with its 60 offices worldwide",
        imgSrc: people3,
    }, {
        id: 4,
        heading:'Success',
        textInfo:"Successful execution of projects",
        imgSrc: people4,
    }
];
export default RestaurantAboutUsData
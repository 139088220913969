
import beer from '../assest/restaurantImg/beer.jpg'
import Covidrestaurant from '../assest/restaurantImg/Covidrestaurant.jpg'


const OperationsData = [
    {
        id: 1,
        heading:'How Top Breweries in Bangalore is Combating the Water Crisis',
        textInfo:'Emphasizing on the fact that water is a valuable resource in any industry, and implementing water conservation measures can have a positive…',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: beer,
    },
    {
        id: 2,
        heading:'How Top Breweries in Bangalore is Combating the Water Crisis',
        textInfo:'Emphasizing on the fact that water is a valuable resource in any industry, and implementing water conservation measures can have a positive…',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Covidrestaurant,
    },
    {
        id: 3,
        heading:'How Top Breweries in Bangalore is Combating the Water Crisis',
        textInfo:'Emphasizing on the fact that water is a valuable resource in any industry, and implementing water conservation measures can have a positive…',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: beer,
    }, {
        id: 4,
        heading:'How Top Breweries in Bangalore is Combating the Water Crisis',
        textInfo:'Emphasizing on the fact that water is a valuable resource in any industry, and implementing water conservation measures can have a positive…',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Covidrestaurant,
    }, {
        id: 5,
        heading:'How Top Breweries in Bangalore is Combating the Water Crisis',
        textInfo:'Emphasizing on the fact that water is a valuable resource in any industry, and implementing water conservation measures can have a positive…',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: beer,
    }, {
        id: 6,
        heading:'How Top Breweries in Bangalore is Combating the Water Crisis',
        textInfo:'Emphasizing on the fact that water is a valuable resource in any industry, and implementing water conservation measures can have a positive…',
        byName:'BY - Nusra',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Covidrestaurant,
    }
];
export default OperationsData

import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import wrldbg from '../../assest/wrldbg.webp'
import { FaChevronCircleRight } from "react-icons/fa";
import afforestation from '../../assest/afforestation.webp'
import bannermiyawaki from '../../assest/banner-miyawaki.webp'



const WaterBodyRestoration = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={wrldbg} alt="img" />
                <h1 className="h1donate text-center">Water Body Restoration</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv pb-0">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Water Body <span>Restoration</span> </h1>
                    <h6 className="m-4" style={{ color: '#3aa348' }}>REVIVING AND RESTORING WATER BODIES: PRESERVING PONDS AND LAKES FOR SUSTAINABLE ECOSYSTEMS</h6>
                </div>
                <div className="pedhlagao_div">
                    <h3>NEED OF THE <span>PROJECT:</span></h3>
                    <p className=" fs-5">Water bodies such as ponds and lakes play a crucial role in maintaining the ecological balance and providing essential ecosystem services. However, many of these water bodies are facing degradation due to pollution, encroachment, and neglect. This project aims to address the urgent need for restoring and conserving these water bodies to safeguard biodiversity, ensure water security, and enhance the overall environmental quality.</p>
                </div>
            </div>
            <div className="flowChart3">
                <img src={afforestation} alt="img" />
            </div>

            <div class="row gap-5 Benefits">
                <div class="col-lg-12">
                    <div class="bdr bdr-txt3">
                        <h4><strong>OBJECTIVES:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Restore and rejuvenate degraded ponds and lakes through various interventions, including water quality improvement, sediment management, and ecological rehabilitation.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Enhance the biodiversity of water bodies by reintroducing native aquatic plants, creating suitable habitats for fish and other aquatic species, and promoting ecological connectivity.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Raise awareness among the local community about the importance of water body conservation, sustainable water management practices, and the benefits derived from restored water bodies.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Strengthen the capacity of local stakeholders, including communities, government agencies, and non-governmental organizations, to sustainably manage and maintain restored water bodies.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Foster community participation and engagement in the restoration process, encouraging local ownership and long-term stewardship of the revitalized water bodies.</li>
                        </ul>

                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="bdr bdr-txt">
                        <h4><strong>TARGET BENEFICIARIES:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" /> Local communities residing near the water bodies: They will benefit from improved access to clean water, enhanced recreational opportunities, and a healthier natural environment.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Biodiversity and aquatic species: The restoration efforts will create suitable habitats for various aquatic plants, fish, birds, and other wildlife, contributing to their conservation.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Farmers and agricultural activities: Restored water bodies can support irrigation needs, promoting sustainable agriculture practices and water availability for farming.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Local government and authorities: The project will assist them in meeting water conservation and environmental sustainability goals, contributing to their governance and development objectives.</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="bdr bdr-txt1">
                        <h4><strong>EXPECTED IMPACT:</strong></h4>
                         <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Improved water quality: The restoration activities will enhance the water quality of the ponds and lakes, ensuring a reliable and sustainable source of clean water for various purposes, including irrigation, and ecosystem health.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Water Holding Capacity: Increase the water holding capacity of the water body, ensuring enhanced resilience to droughts and better water availability for local communities and ecosystems.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Biodiversity conservation: Restoring the ecological balance of water bodies will support the revival of native flora and fauna, preserving aquatic habitats and promoting the overall biodiversity of the region.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Community well-being: Access to restored water bodies will provide recreational opportunities, promote ecotourism, and contribute to the overall well-being of local communities.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Climate resilience: Healthy and restored water bodies can serve as natural reservoirs, contributing to the regulation of local microclimates and reducing the impacts of climate change.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Sustainable development: The project aligns with the Sustainable Development Goals, particularly those related to water security, biodiversity conservation, and sustainable communities.</li>

                        </ul>

                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="bdr bdr-txt1">
                        <h4><strong>BENEFITS TO FUNDER:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Positive reputation and brand recognition as a supporter of environmental conservation and sustainable development.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Employee engagement via volunteering activities.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Alignment with corporate social responsibility (CSR) goals, demonstrating commitment to environmental stewardship and community well-being.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Opportunities for collaboration and partnership with local communities, government agencies, and environmental organizations.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Measurable impact and success stories to showcase the funder's contributions to water body restoration and conservation.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Potential for knowledge sharing and learning from the project's implementation, monitoring, and evaluation activities.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Legacy creation as a funder contributing to the long-term preservation of valuable natural resources and ecosystems.</li>

                        </ul>
                        
                    </div>
                </div>


            </div>

            <div className="flowChart3">
                <img src={bannermiyawaki} alt="img" />
            </div>
            <NGOTrustFooter />

        </>
    )
}

export default WaterBodyRestoration
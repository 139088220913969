import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import wrldbg from '../../assest/wrldbg.webp'
import { FaChevronCircleRight } from "react-icons/fa";
import bannermiyawaki from '../../assest/banner-miyawaki.webp'
import natur1 from '../../assest/natur1.webp'
import natur2 from '../../assest/natur2.webp'
import natur3 from '../../assest/natur3.webp'
import natur4 from '../../assest/natur4.webp'
import natur5 from '../../assest/natur5.webp'



const NatureIntractionCentre = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={wrldbg} alt="img" />
                <h1 className="h1donate text-center">Nature Interaction Centre</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv pb-0">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Nature Interaction <span>Centre</span> </h1>
                    <h6 className="m-4" style={{ color: '#3aa348' }}>ESTABLISHING A NATURE INTERACTION CENTRE</h6>
                </div>
                <div className="pedhlagao_div">
                    <h3>NEED OF THE <span>PROJECT:</span></h3>
                    <p className=" fs-5">There is a growing need to create spaces where urban residents can reconnect with nature, learn about biodiversity, and engage in activities that promote environmental conservation. The Nature Interaction Centre, incorporating various vegetative features and public amenities, aims to fulfill this need by providing an immersive and educational experience that fosters a deeper connection between people and the natural world.</p>
                </div>
            </div>

            <div class="row gap-5 Benefits">
                <div class="col-lg-12">
                    <div class="bdr bdr-txt3">
                        <h4><strong>OBJECTIVES:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Establish a Nature Interaction Centre that showcases a diverse range of vegetative features, including a Miyawaki Forest, Sacred Groves, Native Flowering and Fragrance Cluster, Butterfly Garden, State Tree Gallery, Bamboo Arboretum, Colourful Native Tree Cluster, Sanskrit, Vedic, and Cultural Van, Rare and Endangered Tree Species Bank, and Unique Medicinal Tree Cluster.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Create public amenities and infrastructure within the centre, such as a Nature Selfie Point, Yoga and Interpretation Centre, Meditation with Trees area, Different Pathways for exploration, Open Gym, Bird Watching Park, Children's Play Area, Project Information Boards for information, awareness, and branding, and provision of dustbins and cleaning equipment.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Facilitate environmental education and awareness by providing interpretive signage, guided tours, workshops, and interactive exhibits that highlight the importance of biodiversity conservation, ecosystem services, and sustainable living practices.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Promote community engagement and participation through outreach programs, citizen science initiatives, and volunteering opportunities that encourage active involvement in nature conservation efforts.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Foster a sense of well-being, tranquility, and stress reduction by providing a serene and green environment where visitors can relax, engage in recreational activities, and experience the therapeutic benefits of nature.</li>
                        </ul>

                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="bdr bdr-txt">
                        <h4><strong>TARGET BENEFICIARIES:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Urban residents: The Nature Interaction Centre will cater to individuals and families seeking a respite from urban life, providing them with a space to connect with nature, learn about biodiversity, and engage in recreational activities.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Students and educational institutions: The centre will serve as a valuable educational resource, offering field trips, workshops, and experiential learning opportunities for students to deepen their understanding of environmental conservation and sustainable practices.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Environmental enthusiasts and researchers: The centre will provide a platform for researchers and enthusiasts to study and document biodiversity, contributing to scientific knowledge and conservation efforts.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Local communities and nature lovers: The establishment of the Nature Interaction Centre will create a sense of pride, ownership, and cultural connection with the natural heritage of the region, benefiting local communities and fostering a sense of environmental stewardship.</li>
                        </ul>

                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="bdr bdr-txt1">
                        <h4><strong>EXPECTED IMPACT:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Increased awareness and understanding of biodiversity conservation, ecosystem services, and sustainable living practices among visitors, contributing to a more environmentally conscious society.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Improved well-being and mental health of visitors through nature-based experiences, relaxation, and engagement with green spaces.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Conservation and preservation of native flora and fauna, including rare and endangered species, within the Nature Interaction Centre.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Promotion of eco-tourism and the generation of economic opportunities for the local community.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Enhanced sense of community engagement and ownership in environmental conservation efforts.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Creation of a replicable model for the establishment of similar Nature Interaction Centres in other urban areas, expanding the impact of the project.</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="bdr bdr-txt1">
                        <h4><strong>BENEFITS TO FUNDER:</strong></h4>
                        <ul class="list-icon theme-colored">
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Positive brand association and reputation as a supporter of environmental conservation, biodiversity, and community well-being.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Employee engagement via volunteering activities.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Opportunities for collaboration and partnerships with local government authorities, educational institutions, and environmental organizations.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Increased visibility and recognition through project branding, public engagement activities, and media coverage.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Potential for knowledge exchange and learning from the project's implementation and monitoring processes.</li>
                            <li className=" fs-6"><FaChevronCircleRight className="icons" />Measurable impact and success stories to showcase.</li>
                        </ul>
                    </div>
                </div>


            </div>

            <div className="row justify-content-center gap-3 my-5">
                <div className=" col-lg-3 col-12">
                    <img className=" w-100 rounded-1" src={natur1} alt="img" />
                </div>
                <div className=" col-lg-3 col-12">
                    <img className=" w-100 rounded-1" src={natur2} alt="img" />
                </div>
                <div className=" col-lg-3 col-12">
                    <img className=" w-100 rounded-1" src={natur3} alt="img" />
                </div>
                <div className=" col-lg-3 col-12">
                    <img className=" w-100 rounded-1" src={natur4} alt="img" />
                </div>
                <div className=" col-lg-3 col-12">
                    <img className=" w-100 rounded-1" src={natur5} alt="img" />
                </div>
                <div className=" col-lg-3 col-12">
                    <img className=" w-100 rounded-1" src={natur1} alt="img" />
                </div>
            </div>
            <NGOTrustFooter />

        </>
    )
}

export default NatureIntractionCentre
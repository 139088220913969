import RestaurantFooter from "../../common/Restaurant/RestaurantFooter/RestaurantFooter"
import RestaurantHeader from "../../common/Restaurant/RestaurantHeader/RestaurantHeader"
import '../../styles/RestaurantDisclaimerStyle.css'


const RestaurantTerms = () => {
    return (
        <>
            <RestaurantHeader />

            <div className="BusinessSuppliers_head">
                <h4>Terms</h4>
            </div>
            <div className="container">

                <div className="Disclaimer">
                    <h6>General</h6>
                    <p>The domain name www.franchiseindia.com (the "Site") is owned and presented by Franchise India Holdings Limited ("Company"/"we"/"us"/"our"which shall mean to include its affiliated, subsidiaries and associated companies and successors and assigns). These terms and conditions of the Site("Terms") govern use of the Site and are a binding contract between Franchise India, the Site owner and you, the user. By accessing, browsing and/or using the Site, you acknowledge that you have read, understood and agree to be bound by these Terms and Conditions. The services that are being provided by Franchise India vide the Site are subject to the conditions as mentioned herein.</p>
                </div>

                <div className="Disclaimer">
                    <h6>Legal Disclaimer:</h6>
                    <ul>
                        <li> In no event we shall be liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages whatsoever including without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use of information provided on the Site or our performance with the delay or inability to use the Site or our services or related services, the provisions of or failure to provide services, or for any information, services and related services, availed through the Site or our services or otherwise arising through the use of the Site or our services, whether based on contract, tort, negligence strict liability, or otherwise, even if any of you have been advised of the possibility of damages. We do not nor does the Site endorse in any way any advertisers or contents of advertisers on theweb pages. Verification of such lies with the users on reliability and usage of such. We shall not be responsible or liable for any consequential damages arising on account of users relying on the contents of the advertisers.</li>
                        <li>Before sharing (if applicable)any content on the Site, please be sure you have the right to do so. We reserve the right to use, reproduce and modify all content submitted by you to the site, including but not limited to comments published by you on the site. We reserve to use our absolute discretion in exercising this right.</li>
                        <li>All contents, links and listings provided within the Site and related sites are provided for information purposes only. We make no warranties and are not liable for any actions or representations of any listed company within the Site and related sites.</li>
                        <li>You should always seek independent advice before acting on any information on the Site.</li>
                        <li>Although we take great care in making sure that all information we provide is accurate but we are not responsible for any loss suffered by you as a consequence of any of your action you take or omit to take on the basis of any of the information we provide. It is the sole responsibility of the prospective investorthe Site user to obtain all documents and verify information on its own.</li>
                        <li>We are not liable for any of the content published on the Site. If you would like to alert us to content that you believe to be inappropriate, please immediately write to us at info@franchiseindia.com. We will deal with your request as soon as reasonably possible.</li>
                        <li>All contents and code are copyright © 1999-2013, of Franchise India Holdings Limited. All Rights Reserved. We reserve the right at our sole discretion to refuse any advertising of submitted information.</li>
                        <li>We are not responsible for the transaction that takes place between the investors and franchisors listed on the Site. It is being reiterated that all the franchisor(s) and investor(s) listed on the Site are purely for information purposes and represent neither endorsement nor recommendation of such companies by Franchise India Group.</li>
                    </ul>
                </div>

                <div className="Disclaimer">
                    <h6>Obligations:</h6>
                    <ul>
                        <li>You must not use the services and functionality provided on the Site in any manner that causes damage to the Site or its pages or is unlawful, harmful or fraudulent in any manner or for any competitive intelligence, reverse engineering or for copying of any of its content or functionality.</li>
                        <li>You agree to accord respect to other users of the Site and not to interfere with their legitimate use of the Site and our services.</li>
                        <li>You agree to indemnify us against all costs, claims, liabilities, demands or expenses incurred as a result of any breach of these Terms by you.</li>
                        <li>We reserve the right to block your access to the Site or delete your user account at any time at our absolute discretion.</li>
                        <li>You shall not publish or cause to be published any unlawful, defamatory, obscene, threatening, offensive, harmful or otherwise objectionable content. You confirm that you are the author of any content submitted to the Site and agree to waive all your moral rights to be identified as the author and your copyright to such content.</li>
                        <li>12.36% service tax is applicable on all the transactions that take place with regards to dotcom service. The paid activation of the account will happen on the realization of the cheque and the paid client will have access to the details of the investors. In case of any changes to be made in your account, the information shall to be mailed to us by you for the changes to be brought.</li>
                        <li>By submitting a request for information regarding any of our sponsors, you represent and warrant that all information submitted is true and non - misleading and does not violate any law or regulation. However the Company reserves the right to deny service to any user at any time in its sole discretion.</li>
                    </ul>
                </div>

                <div className="Disclaimer">
                    <h6>Email/Newsletter Subscription and Membership obligations:</h6>
                    <ul>
                        <li>In case you join our community or submit for a newsletter/ membership subscription or opt to receive emails from the Site, the details you enter on the registration form will be used by us to provide you with franchise industry newsletters and/or special promotions and other information. If at any time you wish opt-out of receiving these e-mails, simply follow the unsubscribing instructions given at the bottom of the e-mail. By submitting details to the Site and asking us to disclose your information to advertisers, you agree to receive telephone calls and/or emails about franchise and business opportunities even if you are registered with us or anywhere in the Do Not Call List.</li>
                        <li>You agree to provide your real name, last name and accurate and truthful personal information in your registration.</li>
                        <li>You agree to keep your username and password safe from use by third parties and not to share your registration details with anyone else.</li>
                        <li>You agree to indemnify the Company against any unauthorised use of your username and password, howsoever caused.We reserve the right to modify the membership information you provide, as well as to delete, disable and/or restrict your account in our absolute discretion.</li>
                    </ul>
                </div>

                <div className="Disclaimer">
                    <h6>Franchise India Verified:</h6>
                    <ul>
                        <li>The "Franchise India Verified" is limited assurance offered by the Company that the name and contact information of the advertiser and the category in which the advertiser is listed on the Site , have been verified as existing and correct at the time of the advertiser's registration at the Site.</li>
                        <li>The Company makes no representations or guarantees, whether express or implied, including but not limited to guarantees of the continued existence and/or operations of the advertiser, or the reliability, quality, or support, of services as a franchisor for taking a franchise offered by the advertiser. Buying franchise from advertisers shall be at your own risk.</li>
                    </ul>
                </div>
            
                <div className="Disclaimer">
                    <h6>Governing Law:</h6>
                    <ul>
                        <li>Laws of India govern the Terms of the Site. Users hereby give irrevocable consent to the exclusive jurisdiction and venue of the Delhi, India, in all disputes arising out of or relating to the use of the Site or its related services. Users agree to indemnify and hold the Site and the Company and its subsidiaries, affiliates, officers, and employees harmless from any claim, demand, or damage, including reasonable attorneys fees, asserted by any third party due to or arising out of users own usage of or conduct on the Site. The Site/Company reserves the right to disclose any personal information about the users or use of the Site, including its contents, without the users prior permission, if the Company has good faith belief that such action is necessary to: (1) to conform to legal recommendations or comply with legal process; (2) Protect and defend the rights or property of the Company or its affiliated companies; (3) Enforce the terms or use; or (4) Act to protect the interests of its members or others. If any part of this contract is determined to be invalid or unenforceable pursuant to any applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, unenforceable provision that most closely matches the intent of the original provision and the remainder of the contract (as and when amended) shall continue in effect. Unless otherwise specified herein, this contract constitutes the entire agreement between the user and the Company with respect to the Site and its related services and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and Company with respect to the use of the Site and its services.</li>
                    </ul>
                </div>
                <div className="Disclaimer">
                    <h6>Variation:</h6>
                    <ul>
                        <li>We reserve the right to amend or modify these Terms and Conditions at any time without notice.</li>
                    </ul>
                </div>
                
                <div className="Disclaimer">
                    <h6>Contact Information:</h6>
                    <ul>
                        <li>Your privacy is very important to us. If you have further questions or feedback, please email us at info@franchiseindia.com</li>
                    </ul>
                    <p>Thank you for visiting RestaurantIndia.</p>

                </div>
            </div>

            <RestaurantFooter />

        </>
    )
}

export default RestaurantTerms
import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import wrldbg from '../../assest/wrldbg.webp'
import media2 from '../../assest/media2.webp'
import award1 from '../../assest/award1.webp'
import award2 from '../../assest/award2.webp'
import '../../styles/NgoBlogStyle.css'
import { Link } from "react-router-dom"
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'

const NgoBlog = () => {
    const defalutValue = {
        FirstName:'',
        LastName:'',
        EmailAddress:'',

    }

    const validationSceema = yup.object().shape({
        FirstName: yup.string().required('First Name is Requird!'),
        LastName: yup.string().required('Last Name is Requird!'),
        EmailAddress: yup.string().required('Email Address is Requird!'),


    })
    const handleSubmit = (value) => {
        console.log(value);

    }
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={wrldbg} alt="img" />
                <h1 className="h1donate">Blog</h1>
            </Box>

            <Box className='ngo_ourwork'>
               
                <div className="row ngo_ourwork_detail ps-3">
                        <div className="col-lg-3 col-12 ngo_blog card">
                           
                            <div>
                               <Link to={'/NGO_trust/blog/carbon_footprint'}> <h5>Carbon Footprint</h5></Link>
                            </div>
                            <div>
                                <img src={award1} alt="img" />
                            </div>

                        </div>
                        <div className="col-lg-3 col-12 ngo_blog card">
                           
                            <div>
                                 <Link to={'/NGO_trust/blog/carbon_footprint'}><h5>Sustainable Saturdays</h5></Link>
                            </div>
                            <div>
                                <img src={media2} alt="img" />
                            </div>

                        </div>


                        <div className="col-lg-3 col-12 ngo_blog card">
                           
                            <div>
                                 <Link to={'/NGO_trust/blog/carbon_footprint'}><h5>Carbon Footprin</h5></Link>
                            </div>
                            <div>
                                <img src={award1} alt="img" />
                            </div>
                        </div>

                        <div className="col-lg-3 col-12 ngo_blog card">
                           
                            <div>
                                 <Link to={'/NGO_trust/blog/carbon_footprint'}><h5>Sustainable Saturdays</h5></Link>
                            </div>

                            <div>
                                <img src={award2} alt="img" />
                            </div>

                        </div>



                        <div className="col-lg-3 col-12 ngo_blog card">
                           
                            <div>
                                 <Link to={'/NGO_trust/blog/carbon_footprint'}><h5>Carbon Footprin</h5></Link>
                            </div>
                            <div>
                                <img src={award1} alt="img" />
                            </div>

                        </div>


                        <div className="col-lg-3 col-12 ngo_blog card">
                           
                            <div>
                                 <Link to={'/NGO_trust/blog/carbon_footprint'}><h5>Sustainable Saturdays</h5></Link>
                            </div>
                            <div>
                                <img src={award2} alt="img" />
                            </div>

                        </div>
                </div>
            </Box>

            <Box className='subscribebox'>
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="mb-4">
                            <div className="subscribediv">
                                <h1 className="ps-2">Subscribe to Our <span>Green Newsletter</span></h1>
                                <h6>Get regular updates on email</h6>
                            </div>

                            <div className="row">
                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">

                                            <div className="col-12 col-lg-2">

                                                <Field type='text' name='FirstName' placeholder='First Name'
                                                    className={
                                                        `form-control
                                                    ${errors.FirstName && touched.FirstName ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FirstName" /></p>
                                            </div>

                                            <div className="col-12 col-lg-2">

                                                <Field type='text' name='LastName' placeholder='Last Name'
                                                    className={
                                                        `form-control
                                                      ${errors.LastName && touched.LastName ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="LastName" /></p>
                                            </div>

                                            <div className="col-12 col-lg-2">

                                                <Field type='text' name='EmailAddress' placeholder='Email Address'
                                                    className={
                                                        `form-control
                                                    ${errors.EmailAddress && touched.EmailAddress ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="EmailAddress" /></p>
                                            </div>

                                            <div className="col-12 col-lg-2">
                                                <button type="submit" className="btn btn-success">
                                                    Sign up for green news
                                                </button>
                                            </div>

                                        </Form>
                                    }}
                                </Formik>
                            </div>

                        </div>


                    </div>

                </div>
            </Box>
            <NGOTrustFooter />

        </>
    )
}

export default NgoBlog
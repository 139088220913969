import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './runningSlideStyle.css'


const RunningSlide = ({ title, images }) => {
    // Define the number of slides to show for laptop and mobile
    // const slidesToShowLaptop = 3;
    // const slidesToShowMobile = 1;

    // // Set the initial slidesToShow based on the screen width
    // const initialSlidesToShow = window.innerWidth >= 768 ? slidesToShowLaptop : slidesToShowMobile;

    const settingGallery = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        responsive: [
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              centerPadding: '1px',
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              centerMode: true,
              centerPadding: '1px',
            },
          },
          {
            breakpoint: 9999, // A large value to ensure the last breakpoint always applies
            settings: {
              slidesToShow: 3,
            },
          }
        ],
      };

    return (
        <>
            <div className="imgg-runningslide-div">
                <h1 className='fw-bold ms-3'>{title}</h1>
                <div className="divv-gallery">
                    <Slider className="sliderr-running-box" {...settingGallery}>
                        {images?.map((item, index) => (
                            <div className="running-img-box" key={index}>
                                <img src={item.imgSrc} alt='img' />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    )
}

export default RunningSlide

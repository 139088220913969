import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import wrldbg from '../../assest/wrldbg.webp'
import { Link } from "react-router-dom"
import media2 from '../../assest/media2.webp'
import award1 from '../../assest/award1.webp'
import award2 from '../../assest/award2.webp'
import responsible from '../../assest/responsible.webp'
import { FaRegCalendarDays, FaRegRectangleList } from "react-icons/fa6";
import '../../styles/NgoGreenNewsStyle.css'
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'

const NgoGreenNews = () => {
    const defalutValue = {
        FirstName: '',
        LastName: '',
        EmailAddress: '',

    }

    const validationSceema = yup.object().shape({
        FirstName: yup.string().required('First Name is Requird!'),
        LastName: yup.string().required('Last Name is Requird!'),
        EmailAddress: yup.string().required('Email Address is Requird!'),


    })
    const handleSubmit = (value) => {
        console.log(value);

    }
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={wrldbg} alt="img" />
                <h1 className="h1donate">Green News</h1>
            </Box>

            <div className=" row justify-content-center">
                <div className="col-lg-4 col-12 ngo_greentop">
                    <div>
                        <img src={responsible} alt="img" />
                    </div>
                    <div>
                        <Link to={'/NGO_trust/'}> <h5>The Need for Businesses to adopt Sustainability</h5></Link>
                    </div>
                    <div className="schedule_timediv">
                        <ul>
                            <li><FaRegCalendarDays className=" me-2" /> 2021-06-16</li>
                            <li><FaRegRectangleList className=" me-2" />Others</li>
                        </ul>
                    </div>

                </div>
                <div className="col-lg-4 col-12 ngo_greentop">
                    <div>
                        <img src={responsible} alt="img" />
                    </div>
                    <div>
                        <Link to={'/NGO_trust/'}> <h5>Green Bonds: Investing in Sustainable Energy</h5></Link>
                    </div>
                    <div className="schedule_timediv">
                        <ul>
                            <li><FaRegCalendarDays className=" me-2" /> 2021-06-16</li>
                            <li><FaRegRectangleList className=" me-2" />Energy</li>
                        </ul>
                    </div>

                </div>
                <div className="col-lg-4 col-12 ngo_greentop">
                    <div>
                        <img src={responsible} alt="img" />
                    </div>
                    <div>
                        <Link to={'/NGO_trust/'}> <h5>Building Sustainable Production and Consumption Patterns</h5></Link>
                    </div>
                    <div className="schedule_timediv">
                        <ul>
                            <li><FaRegCalendarDays className=" me-2" /> 2021-06-16</li>
                            <li><FaRegRectangleList className=" me-2" /> Others</li>
                        </ul>
                    </div>

                </div>
                <div className="col-lg-4 col-12 ngo_greentop">
                    <div>
                        <img src={responsible} alt="img" />
                    </div>
                    <div>
                        <Link to={'/NGO_trust/'}> <h5>Single-Use Plastics: Transitioning towards a Circular Plastics Economy</h5></Link>
                    </div>
                    <div className="schedule_timediv">
                        <ul>
                            <li><FaRegCalendarDays className=" me-2" /> 2021-06-16</li>
                            <li><FaRegRectangleList className=" me-2" />Waste</li>
                        </ul>
                    </div>

                </div>

            </div>


            <div className=" row mt-5">
                <div className=" col-lg-9 col-12">
                    <Box className='ngo_ourwork'>

                        <div className="row ngo_ourwork_detail justify-content-center">
                            <div className="col-lg-3 col-12 ngo_blog card">

                                <div>
                                    <Link to={'/NGO_trust/'}> <h5>Carbon Sinks and Oceans: What’s the Connection?</h5></Link>
                                </div>
                                <div className="schedule_timediv">
                                    <ul>
                                        <li><FaRegCalendarDays className=" me-2" /> 2021-06-16</li>
                                        <li><FaRegRectangleList className=" me-2" /> Climate Change | Energy | Waste</li>
                                    </ul>
                                </div>
                                <div>
                                    <img src={award1} alt="img" />
                                </div>

                            </div>
                            <div className="col-lg-3 col-12 ngo_blog card">

                                <div>
                                    <Link to={'/NGO_trust/'}><h5>Carbon Sinks and Oceans: What’s the Connection?</h5></Link>
                                </div>
                                <div className="schedule_timediv">
                                    <ul>
                                        <li><FaRegCalendarDays className=" me-2" /> 2021-06-16</li>
                                        <li><FaRegRectangleList className=" me-2" /> Climate Change | Energy | Waste</li>
                                    </ul>
                                </div>
                                <div>
                                    <img src={media2} alt="img" />
                                </div>

                            </div>


                            <div className="col-lg-3 col-12 ngo_blog card">

                                <div>
                                    <Link to={'/NGO_trust/'}><h5>Carbon Sinks and Oceans: What’s the Connection?</h5></Link>
                                </div>
                                <div className="schedule_timediv">
                                    <ul>
                                        <li><FaRegCalendarDays className=" me-2" /> 2021-06-16</li>
                                        <li><FaRegRectangleList className=" me-2" /> Climate Change | Energy | Waste</li>
                                    </ul>
                                </div>
                                <div>
                                    <img src={award1} alt="img" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-12 ngo_blog card">

                                <div>
                                    <Link to={'/NGO_trust/'}><h5>Carbon Sinks and Oceans: What’s the Connection?</h5></Link>
                                </div>
                                <div className="schedule_timediv">
                                    <ul>
                                        <li><FaRegCalendarDays className=" me-2" /> 2021-06-16</li>
                                        <li><FaRegRectangleList className=" me-2" /> Climate Change | Energy | Waste</li>
                                    </ul>
                                </div>
                                <div>
                                    <img src={award2} alt="img" />
                                </div>

                            </div>



                            <div className="col-lg-3 col-12 ngo_blog card">

                                <div>
                                    <Link to={'/NGO_trust/'}><h5>Carbon Sinks and Oceans: What’s the Connection?</h5></Link>
                                </div>
                                <div className="schedule_timediv">
                                    <ul>
                                        <li><FaRegCalendarDays className=" me-2" /> 2021-06-16</li>
                                        <li><FaRegRectangleList className=" me-2" /> Climate Change | Energy | Waste</li>
                                    </ul>
                                </div>
                                <div>
                                    <img src={award1} alt="img" />
                                </div>

                            </div>


                            <div className="col-lg-3 col-12 ngo_blog card">

                                <div>
                                    <Link to={'/NGO_trust/'}><h5>Carbon Sinks and Oceans: What’s the Connection?</h5></Link>
                                </div>
                                <div className="schedule_timediv">
                                    <ul>
                                        <li><FaRegCalendarDays className=" me-2" /> 2021-06-16</li>
                                        <li><FaRegRectangleList className=" me-2" /> Climate Change | Energy | Waste</li>
                                    </ul>
                                </div>
                                <div>
                                    <img src={award2} alt="img" />
                                </div>

                            </div>
                        </div>
                    </Box>
                </div>

                <div className=" col-lg-3 col-12">
                    <div className="FilterbyCategory">
                        <p>Filter by Category</p>
                        <ul>
                            <li><Link> Waste</Link></li>
                            <li><Link> Energy</Link></li>
                            <li><Link> Pollution</Link></li>
                            <li><Link> Climate Change</Link></li>
                            <li><Link> Green Building</Link></li>
                            <li><Link> Green School</Link></li>
                            <li><Link> Tree Plantation</Link></li>
                            <li><Link> Miyawaki</Link></li>
                            <li><Link> Green Heroes</Link></li>
                            <li><Link> Others</Link></li>
                        </ul>
                    </div>
                </div>

            </div>

            <Box className='subscribebox'>
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="mb-4">
                            <div className="subscribediv">
                                <h1 className="ps-2">Subscribe to Our <span>Green Newsletter</span></h1>
                                <h6>Get regular updates on email</h6>
                            </div>

                            <div className="row">
                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">

                                            <div className="col-12 col-lg-2">

                                                <Field type='text' name='FirstName' placeholder='First Name'
                                                    className={
                                                        `form-control
                                                    ${errors.FirstName && touched.FirstName ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FirstName" /></p>
                                            </div>

                                            <div className="col-12 col-lg-2">

                                                <Field type='text' name='LastName' placeholder='Last Name'
                                                    className={
                                                        `form-control
                                                      ${errors.LastName && touched.LastName ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="LastName" /></p>
                                            </div>

                                            <div className="col-12 col-lg-2">

                                                <Field type='text' name='EmailAddress' placeholder='Email Address'
                                                    className={
                                                        `form-control
                                                    ${errors.EmailAddress && touched.EmailAddress ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="EmailAddress" /></p>
                                            </div>

                                            <div className="col-12 col-lg-2">
                                                <button type="submit" className="btn btn-success">
                                                    Sign up for green news
                                                </button>
                                            </div>

                                        </Form>
                                    }}
                                </Formik>
                            </div>

                        </div>


                    </div>

                </div>
            </Box>
            <NGOTrustFooter />

        </>
    )
}

export default NgoGreenNews
import imgmin11 from '../assest/restaurantImg/1_1.jpg'
import Kirana_2_0 from '../assest/restaurantImg/Kirana_2_0.jpg'


const ServiceCPGData = [
    {
        id: 1,
        heading:'How Hypermarkets in India are Enabling Growth Acceleration for Small Kirana Stores in India',
        textInfo:'India is a big market with over 12 mn Kirana shops, which constitutes about 1/3rd of the world driving over $400 billion worth of food and grocery…',
        byName:'By - Akshat Saxena',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Kirana_2_0,
    },
    {
        id: 2,
        heading:'How Hypermarkets in India are Enabling Growth Acceleration for Small Kirana Stores in India',
        textInfo:'India is a big market with over 12 mn Kirana shops, which constitutes about 1/3rd of the world driving over $400 billion worth of food and grocery…',
        byName:'By - Akshat Saxena',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: imgmin11,
    },
    {
        id: 3,
        heading:'How Hypermarkets in India are Enabling Growth Acceleration for Small Kirana Stores in India',
        textInfo:'India is a big market with over 12 mn Kirana shops, which constitutes about 1/3rd of the world driving over $400 billion worth of food and grocery…',
        byName:'By - Akshat Saxena',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Kirana_2_0,
    }, {
        id: 4,
        heading:'How Hypermarkets in India are Enabling Growth Acceleration for Small Kirana Stores in India',
        textInfo:'India is a big market with over 12 mn Kirana shops, which constitutes about 1/3rd of the world driving over $400 billion worth of food and grocery…',
        byName:'By - Akshat Saxena',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: imgmin11,
    }, {
        id: 5,
        heading:'How Hypermarkets in India are Enabling Growth Acceleration for Small Kirana Stores in India',
        textInfo:'India is a big market with over 12 mn Kirana shops, which constitutes about 1/3rd of the world driving over $400 billion worth of food and grocery…',
        byName:'By - Akshat Saxena',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: Kirana_2_0,
    }, {
        id: 6,
        heading:'How Hypermarkets in India are Enabling Growth Acceleration for Small Kirana Stores in India',
        textInfo:'India is a big market with over 12 mn Kirana shops, which constitutes about 1/3rd of the world driving over $400 billion worth of food and grocery…',
        byName:'By - Akshat Saxena',
        dateTime:'Jun 28, 2024 / 3 MIN READ',        
        imgSrc: imgmin11,
    }
];
export default ServiceCPGData
import greenfield1 from '../assest/greenfield1.webp'
import greenfield2 from '../assest/greenfield2.webp'
import greenfield3 from '../assest/greenfield3.webp'
import greenfield4 from '../assest/greenfield4.webp'
import greenfield5 from '../assest/greenfield5.webp'
import greenfield6 from '../assest/greenfield6.webp'


const NgoGreenTreeImageData = [
    {
        id: 1,
        imgSrc: greenfield1,
    },
    {
        id: 2,
        imgSrc: greenfield2,
    },
    {
        id: 3,
        imgSrc: greenfield3,
    }, {
        id: 4,
        imgSrc: greenfield4,
    }, {
        id: 5,
        imgSrc: greenfield5,
    }, {
        id: 6,
        imgSrc: greenfield6,
    }
];
export default NgoGreenTreeImageData
import amazon from '../assest/amazon.webp'
import barclayslogo from '../assest/barclayslogo.webp'
import creditsuisselogo from '../assest/creditsuisselogo.webp'
import colgate from '../assest/colgate.webp'
import dcb_bank from '../assest/dcb_bank.webp'
import sony_music from '../assest/sony_music.webp'
import tata_capital from '../assest/tata_capital.webp'


const OurPartnerData = [
    {
        id: 1,
        imgSrc: amazon,
    },
    {
        id: 2,
        imgSrc: barclayslogo,
    },
    {
        id: 3,
        imgSrc: creditsuisselogo,
    }, {
        id: 4,
        imgSrc: colgate,
    }, {
        id: 5,
        imgSrc: dcb_bank,
    }, {
        id: 6,
        imgSrc: sony_music,
    }, {
        id: 7,
        imgSrc: tata_capital,
    }
];
export default OurPartnerData
import { Box, Button } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import ourGallery from '../../assest/our-gallery.webp'
import img044 from '../../assest/044.webp'
import planting from '../../assest/planting.webp'
import teamwork from '../../assest/teamwork.webp'
import control from '../../assest/control.webp'
import volunteer from '../../assest/volunteer_1.webp'
import '../../styles/OurWorkGalleryStyle.css'
import { FaAngleDoubleRight } from "react-icons/fa";
import img092 from '../../assest/092.webp'
import img1731 from '../../assest/1731.webp'
import img014 from '../../assest/014.webp'
import img660 from '../../assest/660.webp'


const OurWorkGallery = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={ourGallery} alt="img" />
                <h1 className="h1donate">Our Work Gallery</h1>
            </Box>

            <Box className='ngo_ourwork'>
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 pb-4 mx-4"><span>Miyawaki</span> </h1>
                </div>
                <div className="row justify-content-center ngo_ourwork_detail">


                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img044} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Miyawaki Forest near Bhalswa Landfill, DelhiNCR</h5>
                            <p className="my-1 text-secondary">Our 2nd #MiyawakiForest in #DelhiNCR near #Bhalswa Landfill</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>


                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img044} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Miyawaki Forest near Bhalswa Landfill, DelhiNCR</h5>
                            <p className="my-1 text-secondary">Our 2nd #MiyawakiForest in #DelhiNCR near #Bhalswa Landfill</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>





                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img044} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Miyawaki Forest near Bhalswa Landfill, DelhiNCR</h5>
                            <p className="my-1 text-secondary">Our 2nd #MiyawakiForest in #DelhiNCR near #Bhalswa Landfill</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>





                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img044} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Miyawaki Forest near Bhalswa Landfill, DelhiNCR</h5>
                            <p className="my-1 text-secondary">Our 2nd #MiyawakiForest in #DelhiNCR near #Bhalswa Landfill</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>




                </div>

                <div className="GreenYatra_btndiv">
                    <Button>See All <FaAngleDoubleRight className=" fs-4 ms-2"/></Button>
                </div>
            </Box>

            <Box className='ngo_ourwork'>
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 pb-4 mx-4"><span>Plantations</span> </h1>
                </div>
                <div className="row ngo_ourwork_detail justify-content-center">


                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img092} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Cosmo Films Livelihood Plantation at Aurangabad</h5>
                            <p className="my-1 text-secondary">We plant livelihood Fruits Tree in the outskirt of the metro</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>


                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img092} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Cosmo Films Livelihood Plantation at Aurangabad</h5>
                            <p className="my-1 text-secondary">We plant livelihood Fruits Tree in the outskirt of the metro</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>





                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img092} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Cosmo Films Livelihood Plantation at Aurangabad</h5>
                            <p className="my-1 text-secondary">We plant livelihood Fruits Tree in the outskirt of the metro</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>





                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img092} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Cosmo Films Livelihood Plantation at Aurangabad</h5>
                            <p className="my-1 text-secondary">We plant livelihood Fruits Tree in the outskirt of the metro</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>




                </div>

                <div className="GreenYatra_btndiv">
                    <Button>See All <FaAngleDoubleRight className=" fs-4 ms-2"/></Button>
                </div>
            </Box>

            <Box className='ngo_ourwork'>
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 pb-4 mx-4"><span>Green Events</span> </h1>
                </div>
                <div className="row ngo_ourwork_detail justify-content-center">


                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img1731} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">E-Waste Awareness Session at Mithibai College</h5>
                            <p className="my-1 text-secondary">Thank you Mithibai College and SVKM for inviting us again for</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>


                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img1731} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">E-Waste Awareness Session at Mithibai College</h5>
                            <p className="my-1 text-secondary">Thank you Mithibai College and SVKM for inviting us again for</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>





                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img1731} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">E-Waste Awareness Session at Mithibai College</h5>
                            <p className="my-1 text-secondary">Thank you Mithibai College and SVKM for inviting us again for</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>





                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img1731} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">E-Waste Awareness Session at Mithibai College</h5>
                            <p className="my-1 text-secondary">Thank you Mithibai College and SVKM for inviting us again for</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>




                </div>

                <div className="GreenYatra_btndiv">
                    <Button>See All <FaAngleDoubleRight className=" fs-4 ms-2"/></Button>
                </div>
            </Box>

            <Box className='ngo_ourwork'>
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 pb-4 mx-4"><span>Zaroorat</span> </h1>
                </div>
                <div className="row ngo_ourwork_detail justify-content-center">


                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img014} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Zaroorat Collection Drive at Anushakti Nagar</h5>
                            <p className="my-1 text-secondary">Did you know project Zaroorat- A Need is our one of the most</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>


                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img014} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Zaroorat Collection Drive at Anushakti Nagar</h5>
                            <p className="my-1 text-secondary">Did you know project Zaroorat- A Need is our one of the most</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>





                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img014} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Zaroorat Collection Drive at Anushakti Nagar</h5>
                            <p className="my-1 text-secondary">Did you know project Zaroorat- A Need is our one of the most</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>





                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img014} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Zaroorat Collection Drive at Anushakti Nagar</h5>
                            <p className="my-1 text-secondary">Did you know project Zaroorat- A Need is our one of the most</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>




                </div>

                <div className="GreenYatra_btndiv">
                    <Button>See All <FaAngleDoubleRight className=" fs-4 ms-2"/></Button>
                </div>
            </Box>

            <Box className='ngo_ourwork'>
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 pb-4 mx-4"><span>Others</span> </h1>
                </div>
                <div className="row ngo_ourwork_detail justify-content-center">


                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img660} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Clean Up Drive and Awareness at Milind Nagar</h5>
                            <p className="my-1 text-secondary">Clean-Up Drive and Awareness at Milind Nagar</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>


                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img660} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Clean Up Drive and Awareness at Milind Nagar</h5>
                            <p className="my-1 text-secondary">Clean-Up Drive and Awareness at Milind Nagar</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>





                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img660} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Clean Up Drive and Awareness at Milind Nagar</h5>
                            <p className="my-1 text-secondary">Clean-Up Drive and Awareness at Milind Nagar</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>





                    <div className="ngo_Awards col-lg-3 col-12">
                        <div>
                            <img src={img660} alt="img" />
                        </div>
                        <div className=" text-start">
                            <h5 className="my-1">Clean Up Drive and Awareness at Milind Nagar</h5>
                            <p className="my-1 text-secondary">Clean-Up Drive and Awareness at Milind Nagar</p>
                            <p className="my-1 text-secondary">Read More</p>

                        </div>
                        <div className="FocusArea">
                            <p>Focus Area</p>
                            <div>
                                <img src={planting} alt="img" />
                                <img src={teamwork} alt="img" />
                                <img src={control} alt="img" />
                                <img src={volunteer} alt="img" />
                            </div>
                        </div>

                    </div>




                </div>

                <div className="GreenYatra_btndiv">
                    <Button>See All <FaAngleDoubleRight className=" fs-4 ms-2"/></Button>
                </div>
            </Box>

            <NGOTrustFooter />

        </>
    )
}

export default OurWorkGallery
import React, { useState } from 'react'
import Logo from "../../../assest/marxWeddinglogo.webp";
import { Box, Button, Drawer } from '@mui/material';
import { MdOutlineMenu } from "react-icons/md";
import { Link } from 'react-router-dom';
import SideBar from '../../realEstate/sidebar/SideBar';
import { IoSearchSharp } from "react-icons/io5";
import './ServiceHeaderStyle.css'


const ServiceHeader = () => {
    const [navActive, setNavActive] = useState("#");

    const [openSide, setOpenSide] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpenSide(newOpen);
    };


    return (
        <>
            <Box className="Restaurant_topHeader">
                <div className='heading-div-mobile'>
                    <Button onClick={toggleDrawer(true)}><MdOutlineMenu className=' fs-1textwhite' /></Button>
                    <Drawer open={openSide} onClose={toggleDrawer(false)}>
                        <SideBar setOpenSide={setOpenSide} />
                    </Drawer>
                </div>
                <a href="/NGO_trust"
                    className={navActive === "/NGO_trust" ? "active" : ""}
                    onClick={() => setNavActive("/NGO_trust")}
                >
                    <img className="logo" src={Logo} alt='Logo' />
                    <span className='ngo_compnyName'>Abaris Restaurant</span>
                </a>

                <Box className='rightUL'>
                    <ul className="Restaurant_liauto">

                        <li>
                            <div className="input-group">
                                <input type="text" className="form-control" aria-label="" placeholder=' Search Here...' />
                                <span className="input-group-text"><IoSearchSharp /> </span>
                            </div>

                        </li>

                        <li>
                            <Link className="" to="/restaurant/subscription_plan">
                                <Button className="Buttonsubs" fullWidth>SUBSCRIBE</Button>
                            </Link>
                        </li>

                    </ul>
                </Box>
            </Box>

            <Box className="Restaurant_topHeader">
                <Box className='rightUL'>
                    <ul className="Restaurant_header_li">
                        <li>
                            <Link
                                onClick={() => setNavActive("/restaurant/service/retail_business")}
                                className={navActive === "/restaurant/service/retail_business" ? "active" : ""}
                                to="/restaurant/service/retail_business"
                            >
                                Retail & Business
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/restaurant/service/technology_e_commerce"
                                onClick={() => setNavActive("/restaurant/service/technology_e_commerce")}
                                className={navActive === "/restaurant/service/technology_e_commerce" ? "active" : ""}
                            >
                                Technology & E-Commerce
                            </Link>
                        </li>

                        <li>
                            <Link
                                onClick={() => setNavActive("/restaurant/service/fashion_beauty")}
                                className={navActive === "/restaurant/service/fashion_beauty" ? "active" : ""}
                                to="/restaurant/service/fashion_beauty"
                            >
                                Fashion & Beauty
                            </Link>
                        </li>

                        <li>
                            <Link
                                onClick={() => setNavActive("/restaurant/service/D2C_new_commerce")}
                                className={navActive === "/restaurant/service/D2C_new_commerce" ? "active" : ""}
                                to="/restaurant/service/D2C_new_commerce"
                            >
                                D2C & New Commerce
                            </Link>
                        </li>

                        <li>
                            <Link
                                onClick={() => setNavActive("/restaurant/service/CPG")}
                                className={navActive === "/restaurant/service/CPG" ? "active" : ""}
                                to="/restaurant/service/CPG"
                            >
                                CPG
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={() => setNavActive("/restaurant/service/food_service")}
                                className={navActive === "/restaurant/service/food_service" ? "active" : ""}
                                to="/restaurant/service/food_service"
                            >
                                Food Service
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={() => setNavActive("/restaurant/service/people")}
                                className={navActive === "/restaurant/service/people" ? "active" : ""}
                                to="/restaurant/service/people"
                            >
                                People
                            </Link>
                        </li>


                    </ul>
                </Box>
            </Box>


        </>
    )
}

export default ServiceHeader